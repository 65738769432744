<template>
	<el-form
		ref="personal_info"
		@keydown="personal_info.errors.clear($event.target.name)"
		v-loading="loading"
		:model="personal_info"
		label-width="120px"
		label-position="top"
	>
		<div class="row">
			<div class="col-sm-12 col-md-12 col-lg-8">
				<div class="wizard-des">
					<p>
						Please provide your personal details matching what is in your
						ID proof. For example, please give your first name as “Ravi”
						and last name as “Sharma” if that is what is in your ID.
					</p>
				</div>
				<div class="personalinfo-tab tab-card">
					<h2 class="will-create-title">Personal information</h2>
					<div class="form-row">
						<div class="form-group col-3 col-sm-2 col-md-2 col-lg-2">
							<el-form-item
								label="Title"
								prop="personal_info.title"
								:rules="getTitleRule('Title')"
							>
								<el-select v-model="personal_info.personal_info.title">
									<el-option label="Mr" value="Mr"></el-option>
									<el-option label="Mrs" value="Mrs"></el-option>
									<el-option label="Ms" value="Ms"></el-option>
									<el-option label="Miss" value="Miss"></el-option>
									<el-option label="Master" value="Master"></el-option>
									<!--<el-option label="Dr" value="Dr"></el-option>-->
									<!--<el-option label="Other" value="Other"></el-option>-->
								</el-select>
								<div
									v-if="form.errors.has('personal_info.title')"
									class="el-form-item__error"
									v-text="form.errors.first('personal_info.title')"
								></div>
							</el-form-item>
						</div>
						<div class="form-group col-9 col-sm-4 col-md-4 col-lg-4">
							<el-form-item
								label="First name"
								prop="personal_info.first_name"
								:rules="getFieldRule('First name')"
							>
								<el-input
									@input="
										(value) =>
											(personal_info.personal_info.first_name = value)
									"
									maxlength="15"
									:value="
										personal_info.personal_info.first_name
											| capitalize
									"
									type="text"
									placeholder="First Name"
								></el-input>
								<div
									v-if="form.errors.has('personal_info.first_name')"
									class="el-form-item__error"
									v-text="
										form.errors.first('personal_info.first_name')
									"
								></div>
							</el-form-item>
						</div>
						<div class="form-group col-sm-6 col-md-6 col-lg-6">
							<el-form-item
								label="Last name"
								prop="personal_info.last_name"
								:rules="getFieldRule('Last name')"
							>
								<el-input
									@input="
										(value) =>
											(personal_info.personal_info.last_name = value)
									"
									maxlength="15"
									:value="
										personal_info.personal_info.last_name | capitalize
									"
									type="text"
									placeholder="Last name"
								></el-input>
								<div
									v-if="form.errors.has('personal_info.last_name')"
									class="el-form-item__error"
									v-text="form.errors.first('personal_info.last_name')"
								></div>
							</el-form-item>
						</div>

						<!--<div class="form-group col-3 col-sm-2 col-md-2 col-lg-2"  v-if="personal_info.personal_info.title == 'Other'">

                        <div class="form-group col-3 col-sm-2 col-md-2 col-lg-2" v-if="personal_info.personal_info.title == 'Other'">

                            <el-form-item label="Other title" prop="personal_info.other_title" :rules="getFieldRule('Title')">
                                <el-input v-model="personal_info.personal_info.other_title" type="text"
                                          placeholder="other title"></el-input>
                            </el-form-item>
                        </div>
                        <div class="form-group col-sm-6 col-md-6 col-lg-6" v-if="personal_info.personal_info.title == 'Other'">
                        </div>
                        <div class="form-group col-9 col-sm-4 col-md-4 col-lg-4"  v-if="personal_info.personal_info.title == 'Other'">
                        </div>-->
						<!--                        <div class="form-group col-9 col-sm-4 col-md-4 col-lg-4" v-if="personal_info.personal_info.title == 'Other'">-->
						<!--                        </div>-->
						<div class="form-group col-sm-6 col-md-6 col-lg-6">
							<el-form-item label="Middle name">
								<el-input
									maxlength="15"
									@input="
										(value) =>
											(personal_info.personal_info.middle_name = value)
									"
									:value="
										personal_info.personal_info.middle_name
											| capitalize
									"
									type="text"
									placeholder="Middle name"
								></el-input>
								<div
									v-if="form.errors.has('personal_info.middle_name')"
									class="el-form-item__error"
									v-text="
										form.errors.first('personal_info.middle_name')
									"
								></div>
							</el-form-item>
						</div>
						<div class="form-group col-sm-6 col-md-6 col-lg-6">
							<el-form-item
								label="Father’s name"
								prop="personal_info.father_name"
								:rules="getFieldRule('Father’s name')"
							>
								<el-input
									maxlength="50"
									@input="
										(value) =>
											(personal_info.personal_info.father_name = value)
									"
									:value="
										personal_info.personal_info.father_name
											| capitalize
									"
									type="text"
									placeholder="Father’s name"
								></el-input>
								<div
									v-if="form.errors.has('personal_info.father_name')"
									class="el-form-item__error"
									v-text="
										form.errors.first('personal_info.father_name')
									"
								></div>
							</el-form-item>
						</div>
						<div class="form-group col-sm-6 col-md-6 col-lg-6">
							<el-form-item
								label="Gender"
								prop="personal_info.gender"
								:rules="getFieldRule('Gender')"
							>
								<el-radio
									v-model="personal_info.personal_info.gender"
									@change="changeSpouceGender()"
									label="Male"
									>Male</el-radio
								>
								<el-radio
									v-model="personal_info.personal_info.gender"
									@change="changeSpouceGender()"
									label="Female"
									>Female</el-radio
								>
							</el-form-item>
						</div>
						<div class="form-group col-sm-6 col-md-6 col-lg-6">
							<el-form-item
								label="Date of Birth"
								prop="personal_info.date_of_birth"
								:rules="getFieldRule('Date of Birth')"
							>
								<el-date-picker
									v-model="personal_info.personal_info.date_of_birth"
									type="date"
									format="dd/MM/yyyy"
									:clearable="false"
									value-format="yyyy-MM-dd"
									:picker-options="datePickerOptions"
									@change="enableOption()"
								>
								</el-date-picker>
								<div
									v-if="form.errors.has('personal_info.date_of_birth')"
									class="el-form-item__error"
									v-text="
										form.errors.first('personal_info.date_of_birth')
									"
								></div>
							</el-form-item>
						</div>

						<!--                        <div class="form-group col-sm-6 col-md-6 col-lg-6 MaritalStatus-tooltip ">-->
						<div
							class="form-group col-sm-6 col-md-6 col-lg-6 type-tooltip MaritalStatus"
						>
							<div class="tooltips">
								<span class="fa fa-question fa-tooltip question_tooltip"></span>
								<div class="tooltip-box">
									<ul>
										<li>
											<b>Married</b> – Legally married and spouse is
											alive.
										</li>
										<li>
											<b>Single</b> – An individual who is not
											married.
										</li>
										<li>
											<b>Divorced</b> – An individual who has legally
											dissolved their marriage.
										</li>
										<li>
											<b>Widowed</b> – A woman who has lost her
											spouse by death and has not remarried.
										</li>
										<li>
											<b>Widower</b> - A man who has lost his spouse
											by death and has not remarried.
										</li>
										<li>
											<b>Separated</b> - You are living apart from
											your spouse, but you're still legally married
											until you get a judgment of divorce from a
											court
										</li>
									</ul>
								</div>
							</div>
							<!--                            <div class="MaritalStatus-tooltip-content">-->
							<!--                                <span class="fa fa-question MaritalStatus-fa"></span>-->
							<!--                                <div class="MaritalStatus-tooltip-tolbox">-->

							<!--                                </div>-->
							<!--                            </div>-->
							<el-form-item
								label="Marital Status"
								prop="personal_info.martial_status"
								:rules="getFieldRule('Marital Status')"
							>
								<el-select
									v-model="personal_info.personal_info.martial_status"
									@change="
										DisplaySpouceDetail(
											personal_info.personal_info.martial_status
										)
									"
								>
									<el-option
										label="Select Marital Status"
										value=""
									></el-option>
									<el-option label="Single" value="Single"></el-option>
									<el-option
										label="Seperated"
										value="Seperated"
									></el-option>
									<el-option
										label="Divorced"
										value="Divorced"
									></el-option>
									<el-option
										v-if="
											personal_info.personal_info.gender == 'Male'
										"
										label="Widower"
										value="Widower"
									></el-option>
									<el-option
										v-else
										label="Widowed"
										value="Widowed"
									></el-option>
									<el-option
										label="Married"
										value="Married"
									></el-option>
								</el-select>
								<div
									v-if="
										form.errors.has('personal_info.martial_status')
									"
									class="el-form-item__error"
									v-text="
										form.errors.first('personal_info.martial_status')
									"
								></div>
							</el-form-item>
						</div>
					</div>
					<!--                    <h2 class="will-create-subtitle">Address</h2>-->
					<h2 class="will-create-subtitle">
						Address<!--<el-button type="text" class="add-copy" @click="copy()">(Copy as above)</el-button>-->
					</h2>
					<div class="form-row">
						<div class="form-group col-sm-6 col-md-6 col-lg-6">
							<el-form-item
								label="Address 1"
								prop="personal_info.address_1"
								:rules="getFieldRule('Address 1')"
							>
								<el-input
									maxlength="50"
									@input="
										(value) =>
											(personal_info.personal_info.address_1 = value)
									"
									:value="
										personal_info.personal_info.address_1 | capitalize
									"
									placeholder="Address 1"
								></el-input>
								<div
									v-if="form.errors.has('personal_info.address_1')"
									class="el-form-item__error"
									v-text="form.errors.first('personal_info.address_1')"
								></div>
							</el-form-item>
						</div>
						<div class="form-group col-sm-6 col-md-6 col-lg-6">
							<el-form-item
								label="Address 2"
								prop="personal_info.address_2"
							>
								<el-input
									maxlength="50"
									@input="
										(value) =>
											(personal_info.personal_info.address_2 = value)
									"
									:value="
										personal_info.personal_info.address_2 | capitalize
									"
									placeholder="Address 2"
								></el-input>
							</el-form-item>
						</div>
						<div class="form-group col-sm-3 col-md-3 col-lg-3">
							<el-form-item
								label="City"
								prop="personal_info.city"
								:rules="getFieldRule('City')"
							>
								<el-input
									maxlength="20"
									@input="
										(value) =>
											(personal_info.personal_info.city = value)
									"
									:value="
										personal_info.personal_info.city | capitalize
									"
									placeholder="City"
								></el-input>
								<div
									v-if="form.errors.has('personal_info.city')"
									class="el-form-item__error"
									v-text="form.errors.first('personal_info.city')"
								></div>
							</el-form-item>
						</div>

						<div class="form-group col-sm-3 col-md-3 col-lg-3">
							<el-form-item
								label="State"
								prop="personal_info.state"
								:rules="getFieldRule('State')"
							>
								<!--                                <el-input v-model="personal_info.personal_info.state" placeholder="State"></el-input>-->
								<!--                                <el-select filterable allow-create v-model="personal_info.personal_info.state" placeholder="State">-->
								<!--                                    <el-option-->
								<!--                                            v-for="(record,index) in states"-->
								<!--                                            v-bind:key="index"-->
								<!--                                            :label="record.state"-->
								<!--                                            v-bind:value="record.state">-->
								<!--                                    </el-option>-->
								<!--                                </el-select>-->
								<el-input
									maxlength="20"
									@input="
										(value) =>
											(personal_info.personal_info.state = value)
									"
									:value="
										personal_info.personal_info.state | capitalize
									"
									placeholder="State"
								></el-input>

								<div
									v-if="form.errors.has('personal_info.state')"
									class="el-form-item__error"
									v-text="form.errors.first('personal_info.state')"
								></div>
							</el-form-item>
						</div>

						<div class="form-group col-sm-3 col-md- col-lg-3">
							<el-form-item
								label="Pin Code"
								prop="personal_info.pin_code"
							>
								<el-input
									maxlength="10"
									v-model="personal_info.personal_info.pin_code"
									placeholder="Pin code"
								></el-input>
								<!--   <div v-if="form.errors.has('personal_info.pin_code')" class="el-form-item__error"
                                     v-text="form.errors.first('personal_info.pin_code')"></div>-->
							</el-form-item>
						</div>
						<div class="form-group col-sm-3 col-md-3 col-lg-3">
							<el-form-item
								label="Country"
								prop="personal_info.country"
								:rules="getFieldRule('Country')"
							>
								<!--                                <el-select allow-create v-model="personal_info.personal_info.country">-->
								<!--                                    <el-option label="India" value="India"></el-option>-->
								<!--                                </el-select>-->
								<el-input
									maxlength="20"
									@input="
										(value) =>
											(personal_info.personal_info.country = value)
									"
									:value="
										personal_info.personal_info.country | capitalize
									"
									placeholder="Country"
								></el-input>

								<div
									v-if="form.errors.has('personal_info.country')"
									class="el-form-item__error"
									v-text="form.errors.first('personal_info.country')"
								></div>
							</el-form-item>
						</div>
						<div class="form-group col-sm-12 col-md-12 col-lg-12">
							<el-form-item
								label="Religion"
								prop="personal_info.religion"
								:rules="getFieldRule('Religion')"
							>
								<el-select
									@change="
										addDesc(personal_info.personal_info.religion)
									"
									v-model="personal_info.personal_info.religion"
								>
									<el-option label="Muslim" value="Muslim"></el-option>
									<el-option label="Hindu" value="Hindu"></el-option>
									<el-option
										label="Christian"
										value="Christian"
									></el-option>
									<el-option label="Sikh" value="Sikh"></el-option>
									<el-option
										label="Buddhists"
										value="Buddhists"
									></el-option>
									<el-option label="Jain" value="Jain"></el-option>
									<el-option label="Parsi" value="Parsi"></el-option>
									<el-option label="Other" value="Other"></el-option>
								</el-select>
								<div
									v-if="form.errors.has('personal_info.religion')"
									class="el-form-item__error"
									v-text="form.errors.first('personal_info.religion')"
								></div>
							</el-form-item>
						</div>
						<div class="form-group col-sm-12 col-md-12 col-lg-12">
							<el-form-item
								v-if="
									otherDesc != false &&
										personal_info.personal_info.religion == 'Other'
								"
							>
								<el-input
									maxlength="20"
									v-model="personal_info.personal_info.religion_desc"
									type="text"
									placeholder="If 'Other' is selected please provide details"
								></el-input>
							</el-form-item>
							<p class="religion-des">
								{{ description }}
							</p>
						</div>
					</div>
				</div>

				<div v-if="spouceDetail == true" class="personalinfo-tab tab-card">
					<h2 class="will-create-title">Spouse Details</h2>
					<div class="form-row">
						<div class="form-group col-3 col-sm-2 col-md-2 col-lg-2">
							<el-form-item
								label="Title"
								prop="spouse_details.title"
								:rules="getTitleRule('Title')"
							>
								<!--<el-form-item data-toggle="tooltip" data-placement="bottom" data-html="true" title="Married – Legally married and spouse is alive.
   "
                                              label="Martial Status" prop="personal_info.martial_status" :rules="getFieldRule('Marital Status')">-->

								<el-select v-model="personal_info.spouse_details.title">
									<el-option label="Mr" value="Mr"></el-option>
									<el-option label="Mrs" value="Mrs"></el-option>
									<el-option label="Ms" value="Ms"></el-option>
									<el-option label="Miss" value="Miss"></el-option>
									<el-option label="Master" value="Master"></el-option>
									<!--<el-option label="Dr" value="Dr"></el-option>-->
									<!--<el-option label="Other" value="Other"></el-option>-->
								</el-select>
								<div
									v-if="form.errors.has('spouse_details.title')"
									class="el-form-item__error"
									v-text="form.errors.first('spouse_details.title')"
								></div>
							</el-form-item>
						</div>
						<div class="form-group col-9 col-sm-4 col-md-4 col-lg-4">
							<el-form-item
								label="First name"
								prop="spouse_details.first_name"
								:rules="getFieldRule('First Name')"
							>
								<el-input
									maxlength="20"
									@input="
										(value) =>
											(personal_info.spouse_details.first_name = value)
									"
									:value="
										personal_info.spouse_details.first_name
											| capitalize
									"
									placeholder="First Name"
								></el-input>
								<div
									v-if="form.errors.has('spouse_details.first_name')"
									class="el-form-item__error"
									v-text="
										form.errors.first('spouse_details.first_name')
									"
								></div>
								<!--                                <input v-model="personal_info.spouse_details.first_name" type="text" class="form-control" placeholder="First Name">-->
							</el-form-item>
						</div>
						<div class="form-group col-sm-6 col-md-6 col-lg-6">
							<el-form-item
								label="Last name"
								prop="spouse_details.last_name"
								:rules="getFieldRule('Last Name')"
							>
								<el-input
									maxlength="20"
									@input="
										(value) =>
											(personal_info.spouse_details.last_name = value)
									"
									:value="
										personal_info.spouse_details.last_name
											| capitalize
									"
									placeholder="Last name"
								></el-input>
								<div
									v-if="form.errors.has('spouse_details.last_name')"
									class="el-form-item__error"
									v-text="
										form.errors.first('spouse_details.last_name')
									"
								></div>
							</el-form-item>
						</div>
						<!--<div class="form-group col-3 col-sm-2 col-md-2 col-lg-2"  v-if="personal_info.spouse_details.title == 'Other'">
                            <el-form-item label="Other title" prop="spouse_details.other_title" :rules="getFieldRule('Title')">
                                <el-input v-model="personal_info.spouse_details.other_title" type="text"
                                          placeholder="other title"></el-input>
                            </el-form-item>
                        </div>
                        <div class="form-group col-sm-6 col-md-6 col-lg-6" v-if="personal_info.personal_info.title == 'Other'">
                        </div>

                        <div class="form-group col-9 col-sm-4 col-md-4 col-lg-4"  v-if="personal_info.personal_info.title == 'Other'">
                        </div>-->
						<!--                        <div class="form-group col-9 col-sm-4 col-md-4 col-lg-4" v-if="personal_info.personal_info.title == 'Other'">-->
						<!--                        </div>-->

						<div class="form-group col-sm-6 col-md-6 col-lg-6">
							<el-form-item label="Middle name">
								<el-input
									maxlength="20"
									@input="
										(value) =>
											(personal_info.spouse_details.middle_name = value)
									"
									:value="
										personal_info.spouse_details.middle_name
											| capitalize
									"
									placeholder="Middle name"
								></el-input>
								<div
									v-if="form.errors.has('spouse_details.middle_name')"
									class="el-form-item__error"
									v-text="
										form.errors.first('spouse_details.middle_name')
									"
								></div>
							</el-form-item>
						</div>
						<div class="form-group col-sm-6 col-md-6 col-lg-6">
							<el-form-item
								label="Gender"
								prop="spouse_details.gender"
								:rules="getFieldRule('Gender')"
							>
								<el-radio
									v-model="personal_info.spouse_details.gender"
									@change="changeGender()"
									label="Male"
									>Male</el-radio
								>
								<el-radio
									v-model="personal_info.spouse_details.gender"
									@change="changeGender()"
									label="Female"
									>Female</el-radio
								>
							</el-form-item>
						</div>
						<div class="form-group col-sm-6 col-md-6 col-lg-6">
							<el-form-item
								label="Date of Birth"
								prop="spouse_details.date_of_birth"
								:rules="getFieldRule('Date of Birth')"
							>
								<el-date-picker
									v-model="personal_info.spouse_details.date_of_birth"
									:clearable="false"
									:picker-options="datePickerOptions"
									type="date"
									format="dd/MM/yyyy"
									value-format="yyyy-MM-dd"
								>
								</el-date-picker>
								<div
									v-if="
										form.errors.has('spouse_details.date_of_birth')
									"
									class="el-form-item__error"
									v-text="
										form.errors.first('spouse_details.date_of_birth')
									"
								></div>
							</el-form-item>
						</div>
					</div>
					<h2 class="will-create-subtitle">
						Address<el-button type="text" class="add-copy"
							>(To copy your address please click <span @click="copy()" class="click_here">here</span>)</el-button
						>
					</h2>
					<div class="form-row">
						<div class="form-group col-sm-6 col-md-6 col-lg-6">
							<el-form-item
								label="Address 1"
								prop="spouse_details.address_1"
								:rules="getFieldRule('Address 1')"
							>
								<el-input
									maxlength="50"
									@input="
										(value) =>
											(personal_info.spouse_details.address_1 = value)
									"
									:value="
										personal_info.spouse_details.address_1
											| capitalize
									"
									placeholder="Address 1"
								></el-input>
								<div
									v-if="form.errors.has('spouse_details.address_1')"
									class="el-form-item__error"
									v-text="
										form.errors.first('spouse_details.address_1')
									"
								></div>
							</el-form-item>
						</div>
						<div class="form-group col-sm-6 col-md-6 col-lg-6">
							<el-form-item
								label="Address 2"
								prop="spouse_details.address_2"
							>
								<el-input
									maxlength="50"
									@input="
										(value) =>
											(personal_info.spouse_details.address_2 = value)
									"
									:value="
										personal_info.spouse_details.address_2
											| capitalize
									"
									placeholder="Address 2"
								></el-input>
							</el-form-item>
						</div>
						<div class="form-group col-sm-3 col-md-3 col-lg-3">
							<el-form-item
								label="City"
								prop="spouse_details.city"
								:rules="getFieldRule('city')"
							>
								<el-input
									maxlength="20"
									@input="
										(value) =>
											(personal_info.spouse_details.city = value)
									"
									:value="
										personal_info.spouse_details.city | capitalize
									"
									placeholder="City"
								></el-input>
								<div
									v-if="form.errors.has('spouse_details.city')"
									class="el-form-item__error"
									v-text="form.errors.first('spouse_details.city')"
								></div>
							</el-form-item>
						</div>
						<div class="form-group col-sm-3 col-md-3 col-lg-3">
							<el-form-item
								label="State"
								prop="spouse_details.state"
								:rules="getFieldRule('state')"
							>
								<!--                                <el-select filterable allow-create v-model="personal_info.spouse_details.state" placeholder="State">-->
								<!--                                    <el-option-->
								<!--                                            v-for="(record,index) in states"-->
								<!--                                            v-bind:key="index"-->
								<!--                                            :label="record.state"-->
								<!--                                            v-bind:value="record.state">-->
								<!--                                    </el-option>-->
								<!--                                </el-select>-->
								<el-input
									maxlength="20"
									@input="
										(value) =>
											(personal_info.spouse_details.state = value)
									"
									:value="
										personal_info.spouse_details.state | capitalize
									"
									placeholder="State"
								></el-input>
								<div
									v-if="form.errors.has('spouse_details.state')"
									class="el-form-item__error"
									v-text="form.errors.first('personal_info.state')"
								></div>
							</el-form-item>
						</div>

						<div class="form-group col-sm-3 col-md- col-lg-3">
							<el-form-item
								label="Pin Code"
								prop="spouse_details.pin_code"
							>
								<el-input
									maxlength="10"
									v-model="personal_info.spouse_details.pin_code"
									placeholder="Pin code"
								></el-input>
								<!--<div v-if="form.errors.has('personal_info.pin_code')" class="el-form-item__error"
                                     v-text="form.errors.first('spouse_details.pin_code')"></div>-->
							</el-form-item>
						</div>
						<div class="form-group col-sm-3 col-md-3 col-lg-3">
							<el-form-item
								label="Country"
								prop="spouse_details.country"
								:rules="getFieldRule('Country')"
							>
								<!--                                <el-select allow-create v-model="personal_info.spouse_details.country">-->
								<!--                                    <el-option label="India" value="India"></el-option>-->
								<!--                                </el-select>-->
								<el-input
									maxlength="20"
									@input="
										(value) =>
											(personal_info.spouse_details.country = value)
									"
									:value="
										personal_info.spouse_details.country | capitalize
									"
									placeholder="Country"
								></el-input>

								<div
									v-if="form.errors.has('spouse_details.country')"
									class="el-form-item__error"
									v-text="form.errors.first('spouse_details.country')"
								></div>
							</el-form-item>
						</div>
					</div>
				</div>
			</div>
			<div class="col-sm-12 col-md-12 col-lg-4">
				<div class="will-form-image">
					<img src="/themes/flatly/images/will1.svg" class="" />
				</div>
				<div class="personalinfo-tab tab-card">
					<h2 class="will-create-title">ID Proof</h2>
					<p>Please select identity Proof and enter the identity Number</p>
					<div class="form-row">
						<div class="col-4 col-sm-4">
							<el-radio
								v-model="personal_info.id_proof.identity_proof"
								checked="1"
								label="Aadhaar"
								>Aadhaar</el-radio
							>
						</div>
						<div class="col-4 col-sm-4">
							<el-radio
								v-model="personal_info.id_proof.identity_proof"
								label="Passport"
								>Passport</el-radio
							>
						</div>
						<div class="col-4 col-sm-4">
							<el-radio
								v-model="personal_info.id_proof.identity_proof"
								label="Pan"
								>Pan</el-radio
							>
						</div>
					</div>
					<div class="form-group col-12 ">
						<el-form-item
							label="Selected ID no"
							prop="id_proof.id_no"
							:rules="getFieldRule('ID no')"
						>
							<!--                            <el-input maxlength="12" v-model="personal_info.id_proof.id_no" placeholder="Enter The Selected ID Number" v-uppercase></el-input>-->
							<!--                            <el-input maxlength="12" @input="value => personal_info.id_proof.id_no = value"  :value="personal_info.id_proof.id_no.toUpperCase()" placeholder="Enter The Selected ID Number" v-uppercase></el-input>-->
							<el-input
								maxlength="12"
								@input="
									(value) => (personal_info.id_proof.id_no = value)
								"
								:value="personal_info.id_proof.id_no | caps"
								placeholder="Enter The Selected ID Number"
							></el-input>
							<div
								v-if="form.errors.has('id_proof.id_no')"
								class="el-form-item__error"
								v-text="form.errors.first('id_proof.id_no')"
							></div>
						</el-form-item>
					</div>
				</div>
				<div class="personalinfo-tab tab-card">
					<h2 class="will-create-title">Employment</h2>

					<div class="form-row">
						<div class="form-group col-12 col-md-4 col-lg-12 ">
							<el-form-item
								label="Employer Name"
								prop="employment.employer_name"
								:rules="getFieldRule('Employer Name')"
							>
								<el-input
									maxlength="150"
									@input="
										(value) =>
											(personal_info.employment.employer_name = value)
									"
									:value="
										personal_info.employment.employer_name
											| capitalize
									"
									placeholder="Employer Name"
								></el-input>
								<div
									v-if="form.errors.has('employment.employer_name')"
									class="el-form-item__error"
									v-text="
										form.errors.first('employment.employer_name')
									"
								></div>
							</el-form-item>
						</div>
						<div class="form-group col-12 col-md-4 col-lg-12 ">
							<el-form-item
								label="Email ID"
								prop="employment.email_id"
								:rules="getemailFieldRule()"
							>
								<el-input
									maxlength="200"
									v-model="personal_info.employment.email_id"
									placeholder="Email Address"
								></el-input>
								<div
									v-if="form.errors.has('employment.email_id')"
									class="el-form-item__error"
									v-text="form.errors.first('employment.email_id')"
								></div>
							</el-form-item>
						</div>
						<div class="form-group col-12 col-md-4 col-lg-12 ">
							<div class="row">
								<div class="col-12 col-md-4">
									<el-form-item
										label="Code"
										prop="employment.mobile_code"
										:rules="getFieldRule('Marital Status')"
									>
										<el-select
											v-model="personal_info.employment.mobile_code"
										>
											<el-option
												label="Select Country Code"
												value=""
											></el-option>
											<el-option :label="data.dialCode + ' ' + data.name" :key="index" :value="data.dialCode" v-for="(data, index) in country_codes"></el-option>
										</el-select>
										<div
											v-if="
												form.errors.has('employment.mobile_code')
											"
											class="el-form-item__error"
											v-text="
												form.errors.first('employment.mobile_code')
											"
										></div>
									</el-form-item>
								</div>
								<div class="col-12 col-md-8">
									<el-form-item
										label="Contact"
										prop="employment.contact"
										:rules="getFieldRule('Contact No')"
									>
										<el-input
											maxlength="10"
											min="10"
											v-model="personal_info.employment.contact"
											placeholder="Contact No"
										></el-input>
										<div
											v-if="form.errors.has('employment.contact')"
											class="el-form-item__error"
											v-text="form.errors.first('employment.contact')"
										></div>
									</el-form-item>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div v-if="message != ''" class="personalinfo-tab tab-card">
					<h2 class="will-create-title error-message">{{ message }}</h2>
				</div>
			</div>
		</div>

		<div class="row">
			<div class="col-sm-12 col-md-12 col-lg-12">
				<div class="will-rext-prev-btn">
					<el-form-item>
						<el-button disable class="btn btn-prev btn-nex-prev"
							><i class="fa fa-angle-left"></i
						></el-button>
						<el-button
							class="btn btn-next btn-nex-prev"
							:disabled="message != '' ? true : false"
							@click="submit('children_info')"
							><i class="fa fa-angle-right"></i
						></el-button>
					</el-form-item>
				</div>
			</div>
		</div>
	</el-form>
</template>

<script>
	import axios from 'axios';
	import Form from 'form-backend-validation';
	import FormErrors from '../../../../../Core/Assets/js/components/FormErrors.vue';
	import json from './countrycodes.json';

	export default {
		components: { FormErrors },
		name: 'Step1',
		props: ['data', 'states', 'id'],
		data() {
			return {
				country_codes: json,
				start_at: new Date(),
				datePickerOptions: {
					disabledDate(date) {
						return date > new Date();
					}
				},
				message: '',
				spouceDetail: false,
				spousesBeneficaryRecordId: null,
				loading: false,
				activeTab: 1,
				description: '',
				otherDesc: false,
				form: new Form(),
				beneficiary_info: {
					appoint_special: 'No',
					add_beneficiary: 'No',
					beneficiaries: [],
					specialclauses: []
				},
				personal_info: {
					id: '',
					personal_info: {
						title: '',
						first_name: '',
						father_name: '',
						last_name: '',
						other_title: '',
						middle_name: '',
						gender: 'Male',
						date_of_birth: '',
						martial_status: '',
						address_1: '',
						address_2: '',
						city: '',
						state: '',
						pin_code: '',
						country: '',
						religion: ''
					},
					spouse_details: {
						title: '',
						other_title: '',
						first_name: '',
						last_name: '',
						middle_name: '',
						gender: 'Male',
						date_of_birth: '',
						address_1: '',
						address_2: '',
						city: '',
						state: '',
						pin_code: '',
						country: ''
					},
					id_proof: {
						identity_proof: 'Aadhaar',
						id_no: ''
					},
					employment: {
						employer_name: '',
						email_id: '',
						contact: '',
						mobile_code: '+91'
					}
				}
			};
		},
		watch: {
			dynamic: function() {
				console.log('dynamic', this.dynamic);
			}
		},
		filters: {
			capitalize: function(value) {
				if (!value) return '';
				// value = value.toString().toLowerCase()
				return value.charAt(0).toUpperCase() + value.slice(1);
			},
			caps: function(value) {
				if (!value) return '';
				return value.toUpperCase();
				// return value.charAt(0).toUpperCase() + value.slice(1)
			}
		},
		directives: {
			uppercase: {
				// directive definition
				update: function(el) {
					console.log('e', el);
					el.value = el.value.toUpperCase();
				}
			}
		},
		// directive:{
		//     uppercase: function (el) {
		//         el.value = el.value.toUpperCase()
		//     }
		// },
		methods: {
			changeSpouceGender() {
				console.log(this.personal_info.personal_info.gender);
				if (this.personal_info.personal_info.gender == 'Male') {
					this.personal_info.spouse_details.gender = 'Female';
				} else {
					this.personal_info.spouse_details.gender = 'Male';
				}
			},
			changeGender() {
				if (this.personal_info.spouse_details.gender == 'Male') {
					this.personal_info.personal_info.gender = 'Female';
				} else {
					this.personal_info.personal_info.gender = 'Male';
				}
			},
			copy() {
				this.personal_info.spouse_details.address_1 = this.personal_info.personal_info.address_1;
				this.personal_info.spouse_details.address_2 = this.personal_info.personal_info.address_2;
				this.personal_info.spouse_details.city = this.personal_info.personal_info.city;
				this.personal_info.spouse_details.state = this.personal_info.personal_info.state;
				this.personal_info.spouse_details.country = this.personal_info.personal_info.country;
				this.personal_info.spouse_details.pin_code = this.personal_info.personal_info.pin_code;
			},
			addDesc(religion) {
				if (religion != 'Muslim') {
					console.log('des');
					this.description =
						"The legality of the WILL shall be the responsibility of the person executing the WILL and the website or its promoters shall in no way be bound by any law regarding the same. WILL shall be governed by the executant's personal laws or any other relevant laws as the case may be.";
				} else if (religion == 'Muslim') {
					this.description =
						"The legality of the WILL shall be the responsibility of the person executing the WILL and the website or its promoters shall in no way be bound by any law regarding the same. WILL shall be governed by the executant's personal laws or any other relevant laws as the case may be. Under Islamic law of inheritance (Sharia law), a Muslim testator can bequeath only one-third of his property by Will and requires atleast 2/3 of the deceased's property to be inherited by the line of succession.";
				}
				setTimeout(
					function() {
						this.description = '';
					}.bind(this),
					12000
				);
				if (religion == 'Other') {
					this.otherDesc = true;
				}
			},

			getFieldRule(Title, FieldName = null, FieldType = null) {
				return {
					required: true,
					message: `${Title} field is required`
				};
			},
			getemailFieldRule() {
				return {
					required: true,
					type: 'email',
					message: 'Enter valid Email',
					trigger: 'change'
				};
			},
			getTitleRule(Title, FieldName = null, FieldType = null) {
				return {
					required: true,
					message: `field required`
				};
			},
			DisplaySpouceDetail(value) {
				if (value == 'Married') {
					this.spouceDetail = true;
				} else {
					this.spouceDetail = false;
				}
			},
			submit(formName) {
				this.personal_info.id_proof.id_no = this.personal_info.id_proof.id_no.toUpperCase();
				this.$refs.personal_info.validate((valid) => {
					window.scrollTo({ top: 0, behavior: 'smooth' });
					if (valid) {
						this.loading = true;

						this.form = new Form({
							activeTab: this.activeTab,
							personal_info: this.personal_info,
							beneficiary_info: this.beneficiary_info
						});

						if (this.id != 0) {
							console.log('id', this.id);
							this.form
								.post(route('api.will.wills.savedata', { id: this.id }))

								.then((response) => {
									console.log('id', this.id);
									this.loading = false;
									if (response.errors == false) {
										this.$emit('changeActiveTab', this.activeTab + 1);
										this.$emit('updateFormData', response.data);
									} else {
										if (response.dashboardRoute != undefined) {
											window.location = response.dashboardRoute;
										}
									}
								})
								.catch((error) => {
									this.loading = false;
								});
						} else {
							this.form
								.post('/api/will/formData/add')
								.then((response) => {
									this.loading = false;
									if (response.errors == false) {
										this.message = '';
										this.$emit('changeActiveTab', this.activeTab + 1);
										this.$emit('updateFormData', response.data);
									} else {
										if (response.dashboardRoute != undefined) {
											window.location = response.dashboardRoute;
										}
									}
								})
								.catch((error) => {
									this.loading = false;
								});
						}
					} else {
						console.log('error submit!!');
						return false;
					}
				});
			},
			// change(){
			//     console.log('id');
			//
			//     this.$emit('changeActiveTab', this.activeTab + 1);
			//     this.$emit('updateFormData', response.data);
			// },
			enableOption() {
				this.message = '';
			}
		},
		mounted() {
			this.loading = true;
			if (this.data != undefined && this.data.personal_info != undefined) {
				if (this.data.personal_info.personal_info != undefined) {
					for (var col in this.data.personal_info.personal_info) {
						this.personal_info.personal_info[
							col
						] = this.data.personal_info.personal_info[col];
					}
				}
				if (
					this.data.personal_info.spouse_details != undefined &&
					this.data.personal_info.spouse_details.title != undefined
				) {
					this.personal_info.spouse_details = this.data.personal_info.spouse_details;
				}
				if (this.data.personal_info.id_proof != undefined) {
					this.personal_info.id_proof = this.data.personal_info.id_proof;
				}
				if (this.data.personal_info.employment != undefined) {
					for (var col in this.data.personal_info.employment) {
						this.personal_info.employment[
							col
						] = this.data.personal_info.employment[col];
					}
					// this.personal_info.employment = this.data.personal_info.employment;
				}
				console.log('data', this.personal_info);

				console.log(this.personal_info.personal_info.title);
			} else {
				this.personal_info.id = 'PI' + new Date().valueOf();
			}
			if (this.personal_info.personal_info.martial_status == 'Married') {
				this.spouceDetail = true;
			}
			if (
				this.data != undefined &&
				this.data.beneficiary_info !== undefined
			) {
				// this.data.guardian_info = [];
				this.beneficiary_info = this.data.beneficiary_info;
			}
			if (
				this.data.beneficiary_info !== undefined &&
				this.data.beneficiary_info.beneficiaries !== undefined
			) {
				this.beneficiary_info = this.data.beneficiary_info;
				for (var col in this.data.beneficiary_info.beneficiaries) {
					if (
						this.data.beneficiary_info.beneficiaries[col].type ===
							'primary' &&
						(this.data.beneficiary_info.beneficiaries[col]
							.relationship === 'Wife' ||
							this.data.beneficiary_info.beneficiaries[col]
								.relationship === 'Husband')
					) {
						this.spousesBeneficaryRecordId = col;
						break;
					}
					console.log(
						'this.spousesBeneficaryRecordId',
						this.data.beneficiary_info.beneficiaries[col].relationship
					);
				}
			}
			this.loading = false;
		},
		watch: {
			'personal_info.personal_info.martial_status': function() {
				console.log('data', this.data.beneficiary_info);
				if (this.personal_info.personal_info.martial_status !== 'Married') {
					this.personal_info.spouse_details.first_name = '';
					this.personal_info.spouse_details.last_name = '';
					this.personal_info.spouse_details.middle_name = '';
					this.personal_info.spouse_details.gender = '';
					this.personal_info.spouse_details.title = '';
					this.personal_info.spouse_details.date_of_birth = '';
					this.personal_info.spouse_details.address_1 = '';
					this.personal_info.spouse_details.address_2 = '';
					this.personal_info.spouse_details.city = '';
					this.personal_info.spouse_details.state = '';
					this.personal_info.spouse_details.pin_code = '';
					this.personal_info.spouse_details.country = '';
					if (
						this.data.beneficiary_info !== undefined &&
						this.data.beneficiary_info.beneficiaries !== undefined
					) {
						for (var col in this.data.beneficiary_info.beneficiaries) {
							if (
								this.data.beneficiary_info.beneficiaries[col].type ===
									'primary' &&
								(this.data.beneficiary_info.beneficiaries[col]
									.relationship === 'Wife' ||
									this.data.beneficiary_info.beneficiaries[col]
										.relationship === 'Husband')
							) {
								console.log(
									'3',
									this.data.beneficiary_info.beneficiaries[col].type
								);
								this.beneficiary_info.beneficiaries.splice(col, 1);
								// this.data.beneficiary_info.beneficiaries.splice(col, 1);
								this.spousesBeneficaryRecordId = null;
								// break;
							}
							// console.log('this.spousesBeneficaryRecordId',this.data.beneficiary_info.beneficiaries[col].relationship);
						}
					}
				}
				if (this.personal_info.personal_info.martial_status === 'Married') {
					if (this.personal_info.personal_info.gender === 'Male')
						this.personal_info.spouse_details.gender = 'Female';
					else this.personal_info.spouse_details.gender = 'Male';
					var brecord = this.personal_info.spouse_details;
					if (this.personal_info.spouse_details.gender === 'Male')
						brecord.relationship = 'Husband';
					else brecord.relationship = 'Wife';
					brecord.type = 'primary';
					if (this.spousesBeneficaryRecordId !== null) {
						this.beneficiary_info.beneficiaries[
							this.spousesBeneficaryRecordId
						] = brecord;
					} else {
						brecord.id = 'PB' + new Date().valueOf();
						this.beneficiary_info.beneficiaries.push(brecord);
					}
					// this.form = new Form({activeTab: this.activeTab, personal_info: this.personal_info, beneficiary_info: this.beneficiary_info});
				}
			},
			'personal_info.personal_info.title': function() {
				if (this.personal_info.personal_info.title != 'Other') {
					if (this.personal_info.personal_info.other_title != '') {
						this.personal_info.personal_info.other_title = '';
					}
				}
			}
		}
	};
</script>

<style scoped>
	.tooltips {
		word-break: break-word;
	}
</style>
