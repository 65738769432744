<template>
<!-- <h1>Step9</h1> -->
    <el-form v-loading="loading" :ref="formName" :model="recordForm" label-width="120px" label-position="top">
        <div class="row">
            <div class="col-sm-12 col-md-12 col-lg-8">
                <div class="wizard-des">
                    <p>These are not legally enforceable, but will act as an indication to your loved ones on your wishes.</p>
                </div>
                <div class="radio-details-tab  tab-card">
                    <span class="will-radio-title pr-3">Do you wish to add your funeral wises in your will?</span>
                    <div class="yn-radio">
                        <el-radio v-model="recordForm.add_funeral" label="Yes">Yes</el-radio>
                        <el-radio v-model="recordForm.add_funeral" label="No">No</el-radio>
                    </div>
                </div>

                <div class=" tab-card" v-if="recordForm.add_funeral == 'Yes'">
                    <h2 class="will-create-title">Funeral</h2>
                    <div class="form-row">
                        <div class="form-group col-sm-12 col-md-6 col-lg-12 col-xl-6">
                            <el-form-item label="What would you like to do with your body?" prop="funeral" :rules="getFieldRule('Funeral')">

                                <el-select v-model="recordForm.funeral">
                                    <el-option label="Buried in a Cemetery" value="Buried in a Cemetery" selected></el-option>
                                    <el-option label="Let my Family Decide" value="Let my Family Decide"></el-option>
                                    <el-option label="Donated to Science" value="Donated to Science"></el-option>
                                    <el-option label="Cremated" value="Cremated"></el-option>
                                    <el-option label="Other" value="Other"></el-option>
                                </el-select>
                            </el-form-item>
                        </div>
                    </div>
                </div>
                <div class=" tab-card" v-if="(recordForm.funeral == 'Buried in a Cemetery' || recordForm.funeral == 'Donated to Science' || recordForm.funeral == 'Cremated' || recordForm.funeral == 'Other') && recordForm.funeral != '' && recordForm.add_funeral == 'Yes'" >
                    <h2 class="will-create-title">{{recordForm.funeral}}</h2>
                    <div class="form-row">
                        <div class="form-group col-sm-12 col-md-6 col-lg-12 col-xl-6">
                            <!--<label v-if="funeral == 'Buried in a Cemetery'" for="cemetery" class="funeral-lable">Which Cemetery do you like to be buried?</label>-->
                            <!--<label v-else-if="funeral == 'Donated to Science'" for="cemetery" class="funeral-lable">Which instituation do you wish to donate your body or ant organ to?</label>-->
                            <!--<label v-else-if="funeral == 'Cremated'" for="cemetery" class="funeral-lable">What do you want to be Cremated?</label>-->
                            <!--<label v-else-if="funeral == 'Other'" for="cemetery" class="funeral-lable">What do you want to your body?</label>-->
                            <el-form-item v-if="recordForm.funeral == 'Buried in a Cemetery'" prop="body_part" label="Which Cemetery do you like to be buried?" :rules="getFieldRule('Details')">
                                <el-input type="text" @input="value => recordForm.body_part = value" :value="recordForm.body_part | capitalize"  placeholder="Please Add Details"></el-input>
                            </el-form-item>
                            <el-form-item v-else-if="recordForm.funeral == 'Donated to Science'" prop="body_part" label="Which instituation do you wish to donate your body?" :rules="getFieldRule('Details')">
                                <el-input type="text" @input="value => recordForm.body_part = value" :value="recordForm.body_part | capitalize"  placeholder="Please Add Details"></el-input>
                            </el-form-item>
                            <el-form-item v-else-if="recordForm.funeral == 'Cremated'" prop="body_part" for="cemetery" label="Where do you want your body to be Cremated?" :rules="getFieldRule('Details')">
                                <el-input type="text" @input="value => recordForm.body_part = value" :value="recordForm.body_part | capitalize"  placeholder="Please Add Details"></el-input>
                            </el-form-item>
                            <el-form-item v-else-if="recordForm.funeral == 'Other'" prop="body_part" label="Please provide your funeral wishes below" :rules="getFieldRule('Details')">
                                <el-input type="text" @input="value => recordForm.body_part = value" :value="recordForm.body_part | capitalize"  placeholder="Please Add Details"></el-input>
                            </el-form-item>
                        </div>
                        <div class="form-group col-sm-6 col-md-6 col-lg-6">
                            <!--                            <label v-if="funeral == 'Buried in a Cemetery'" for="curiedc" class="funeral-lable">Any additional wishes about your resting place?</label>-->
                            <!--                            <label v-else-if="funeral == 'Donated to Science'" for="cemetery" class="funeral-lable">Any instruction to the instutation?</label>-->
                            <!--                            <label v-else-if="funeral == 'Cremated'" for="cemetery" class="funeral-lable">What do you wish to do with the ash?</label>-->
                            <!--                            <label v-else-if="funeral == 'Other'" for="cemetery" class="funeral-lable">Any additional wishes about your resting place?</label>-->
                            <el-form-item v-if="recordForm.funeral == 'Buried in a Cemetery'" prop="description" label="Any additional wishes about your resting place?"
                                          :rules="getFieldRule('Details')">
                                <el-input type="text" @input="value => recordForm.description = value" :value="recordForm.description | capitalize" placeholder="Please Add Details"></el-input>
                            </el-form-item>
                            <el-form-item v-else-if="recordForm.funeral == 'Donated to Science'" prop="description" label="Any instruction to the instutation?" :rules="getFieldRule('Details')">
                                <el-input type="text" @input="value => recordForm.description = value" :value="recordForm.description | capitalize" placeholder="Please Add Details"></el-input>
                            </el-form-item>
                            <el-form-item v-else-if="recordForm.funeral == 'Cremated'" prop="description" label="What do you wish to do with the ash?" :rules="getFieldRule('Details')">
                                <el-input type="text" @input="value => recordForm.description = value" :value="recordForm.description | capitalize"  placeholder="Please Add Details"></el-input>
                            </el-form-item>
                            <!--<el-form-item v-else-if="recordForm.funeral == 'Other'" prop="description"  label="Any additional wishes about your resting place?" :rules="getFieldRule('Details')">
                                <el-input type="text" @input="value => recordForm.description = value" :value="recordForm.description | capitalize" placeholder="Please Add Details"></el-input>
                            </el-form-item>-->
                        </div>
                    </div>

                </div>
                <div class="radio-details-tab  tab-card">
                    <span class="will-radio-title">Do you want to add any final message that needs to be read out during your will disclosure?</span>
                    <div class="yn-radio mt-2 d-inline-block">
                        <el-radio v-model="recordForm.add_final_message" label="Yes">Yes</el-radio>
                        <el-radio v-model="recordForm.add_final_message" label="No">No</el-radio>
                    </div>
                </div>
                <div class="tab-card final-message" v-if="recordForm.add_final_message == 'Yes'">
                    <h2 class="will-create-title">Final Message</h2>
                    <div class="form-row">
                        <div class="form-group col-sm-12">
                            <div class="fun-msg">
                                <!--                                <label class="funeral-lable">Please add details below</label>-->
                                <el-form-item label="Please add details below" prop="final_detail" :rules="getFieldRule('Details')">
                                <!--                                    <textarea v-model="recordForm.final_detail" class="form-control " placeholder="Write somthing.."></textarea>-->
                                    <el-input type="textarea" @input="value => recordForm.final_detail = value" :value="recordForm.final_detail | capitalize"  class="form-control " placeholder="Write somthing.."></el-input>
                                </el-form-item>

                            </div>
                        </div>
                        <div class="form-group col-sm-12 col-md-6 col-lg-12 col-xl-6">
                            <div class="fun-msg">
                                <!--                                <label class="funeral-lable">Any additional wishes about your resting place?</label>-->
                                <!--                                <textarea v-model="recordForm.additional_wish" class="form-control" placeholder="Write somthing.."></textarea>-->
                                <!--                                <el-form-item label="Any additional wishes about your resting place?" prop="additional_wish"  :rules="getFieldRule('Details')">-->
                                <!--                                    <textarea v-model="recordForm.additional_wish" class="form-control" placeholder="Write somthing.."></textarea>-->
                                <!--                                </el-form-item>-->
                            </div>
                        </div>
                    </div>
                </div>   
                <div class="radio-details-tab tab-card">
                    <span class="will-radio-title pr-3">Do you have any additional information which you want us to consider while reviewing your Will?</span>
                     <label class="funeral-lable summary"> If you have any questions or have to give more clarity to any of the questionnaire in the Will <br> please use the box below(The information you provide here will not be part of your Will. This is to
provide clarity for us.)</label>
                     <textarea v-model="recordForm.summary_help" class="form-control"></textarea>

                </div> 
            </div>
           
            <div class="col-sm-12 col-md-12 col-lg-4 position-relative">
                <div class="will-form-image">
                    <div class="will-toon">
                        <img src="/themes/flatly/images/will9.svg" class="">
                    </div>
                </div>
                <div class="document-img">
                    <img src="/themes/flatly/images/document.svg">
                </div>

            </div>
        </div>
        <div class="row">
            <div class="col-sm-12 col-md-12 col-lg-12">
                <div class="will-rext-prev-btn">
                    <el-button class="btn btn-prev active btn-nex-prev" @click="backStep()"><i class="fa fa-angle-left"></i></el-button>
                    <el-button class="btn btn-next btn-nex-prev" @click="submit('recordForm')"><i class="fa fa-angle-right"></i></el-button>
                </div>
            </div>
        </div>
    </el-form>

</template>

<script>
    import Form from 'form-backend-validation';

    var uuid = require('uuid');

    export default {
        name: "step10",
        props: ['data','id'],
        data() {
            return {
                activeTab: 10,
                loading: false,
                form: new Form(),
                recordIndex: null,
                formName: 'recordForm',
                funeral: '',
                recordForm: {
                    add_funeral: 'No',
                    add_final_message: 'No',
                    id: '',
                    funeral: '',
                    body_part: '',
                    description: '',
                    additional_wish: '',
                    final_detail: '',
                }
            }
        },
        filters: {
            capitalize: function (value) {
                if (!value) return ''
                // value = value.toString().toLowerCase()
                return value.charAt(0).toUpperCase() + value.slice(1)
            }
        },
        methods: {
            changeState(value) {
                this.funeral = value;
            },
            getFieldRule(Title, FieldName = null, FieldType = null) {
                return {
                    required: true,
                    message: `${Title} field is required`,
                };
            },
            cancel() {
                this.recordIndex = null;
                this.resetForm();
            },
            backStep() {
                console.log('back');
                this.$emit('changeActiveTab', this.activeTab - 1);
            },
            submit() {
                window.scrollTo({top: 0, behavior: 'smooth'});
                if (this.recordForm.add_funeral == 'Yes' || this.recordForm.add_final_message == 'Yes') {
                    this.$refs[this.formName].validate((valid) => {
                        console.log('er');
                        if (valid) {
                            // this.recordForm.add_funeral = 'Yes';
                            this.loading = true;
                            this.recordForm.id = 'F' + new Date().valueOf();
                            this.form = new Form({activeTab: this.activeTab, funeral_info: this.recordForm});
                            if (this.id != 0) {
                                console.log('id',this.id);
                                this.form.post(route('api.will.wills.savedata', {id: this.id}))
                                    .then((response) => {
                                        console.log('id',this.id);
                                        this.loading = false;
                                        if (response.errors == false) {
                                            localStorage.setItem("steps", 'true');
                                            window.location = route('admin.will.will.summary',this.id);
                                        }else{
                                            if(response.dashboardRoute != undefined){
                                                window.location = (response.dashboardRoute);
                                            }
                                        }
                                    })
                                    .catch((error) => {
                                        this.loading = false;
                                    });
                            } else {
                                this.form.post('/api/will/formData/add')
                                    .then((response) => {
                                        this.loading = false;
                                        if (response.errors == false) {
                                            localStorage.setItem("steps", 'true');
                                            window.location = (response.summaryRoute);
                                            // this.$emit('changeActiveTab', this.activeTab + 1);
                                            // this.$emit('updateFormData', response.data);
                                        }else{
                                            if(response.dashboardRoute != undefined){
                                                window.location = (response.dashboardRoute);
                                            }
                                        }
                                    })
                                    .catch((error) => {
                                        this.loading = false;
                                    });
                            }
                        }
                    });
                }
                // if(this.recordForm.add_final_message == 'Yes'){
                //     this.$refs[this.formName].validate((valid) => {
                //         if (valid) {
                //             this.recordForm.add_final_message = 'Yes';
                //         } else {
                //             this.recordForm.add_final_message = 'Yes';
                //         }
                //     });
                // }
                else{
                    this.loading = true;
                    this.recordForm.id = 'F' + new Date().valueOf();
                    this.form = new Form({activeTab: this.activeTab, funeral_info: this.recordForm});
                    if (this.id != 0) {
                        console.log('id',this.id);
                        axios.post(route('api.will.wills.savedata', {id: this.id}))

                            .then((response) => {
                                console.log('id',this.id);
                                this.loading = false;
                                if (response.errors == false) {
                                    console.log('id',this.id);
                                    localStorage.setItem("steps", 'true');
                                    this.$router.push({name: 'admin.will.will.summary'})
                                }
                            })
                            .catch((error) => {
                                this.loading = false;
                            });
                    } else {
                        this.form.post('/api/will/formData/add')
                            .then((response) => {
                                this.loading = false;
                                if (response.errors == false) {
                                    localStorage.setItem("steps", 'true');
                                    window.location = (response.summaryRoute);
                                    // this.$emit('changeActiveTab', this.activeTab + 1);
                                    // this.$emit('updateFormData', response.data);
                                }
                            })
                            .catch((error) => {
                                this.loading = false;
                            });
                    }
                }
            }
        },
        mounted() {
            this.loading = true;
            if (this.data != undefined && this.data.funeral_info != undefined) {
                this.recordForm = this.data.funeral_info;
            }
            this.loading = false;
        },
        watch: {
            'recordForm.add_funeral': function () {
                if (this.recordForm.add_funeral == "No") {
                    this.recordForm.funeral = '';
                    this.recordForm.body_part = '';
                    this.recordForm.description = '';
                }
            },

            'recordForm.add_final_message': function () {
                if (this.recordForm.add_final_message == "No") {
                    this.recordForm.additional_wish = '';
                    this.recordForm.final_detail = '';
                    
                }
            },
           
        }
    };
</script>

<style scoped>
.final-message .form-control{
    padding: 0;
}
.final-message .el-textarea__inner{
    border:none;
}
.will-radio-title {
    word-break: break-word;
}
</style>
