<template>
    <div class="row">
        <div class="col-xl-12 col-md-12 col-sm-12 col-12">
            <div class="row">
                <div class="col-xl-8 col-md-12 col-sm-12 col-12">
                    <div class="d-block d-sm-none mobile-tab">
                        <div class="row">
                            <div class="col-9">
                                <div class="m-wizard-tab">

                                    <!-- Nav tabs -->

                                    <ul class="">

                                        <li>
                                            <div :class="step == 1 ? 'mtab m-personalinfo m-active':'mtab m-personalinfo'">

                                                <span class="m-step-no">1</span>

                                                <span class="m-step-title">Personal info</span>

                                            </div>

                                        </li>
                                        <li>

                                            <div :class="step == 2 ? 'mtab m-children-details m-active' : 'mtab m-children-detail'">

                                                <span class="m-step-no">2</span>

                                                <span class="m-step-title">Children Details</span>

                                            </div>

                                        </li>
                                        <li>

                                            <div :class="step == 3 ? 'mtab m-guardians-details m-active' : 'mtab m-guardians-details'">

                                                <span class="m-step-no">3</span>

                                                <span class="m-step-title">Guardian Details</span>

                                            </div>

                                        </li>
                                        <li>
                                            <div :class="step == 4 ? 'mtab m-beneficiary m-active' : 'mtab m-beneficiary'">
                                                <span class="m-step-no">4</span>

                                                <span class="m-step-title">Beneficiary</span>

                                            </div>

                                        </li>
                                        <li>
                                            <div :class="step == 5 ? 'mtab m-state-property m-active' : 'mtab m-state-property'">
                                                <span class="m-step-no">5</span>

                                                <span class="m-step-title">Estate / Property</span>

                                            </div>

                                        </li>

                                        <li>

                                            <div :class="step == 6 ? 'mtab m-gifts m-active' : 'mtab m-gifts'">

                                                <span class="m-step-no">6</span>

                                                <span class="m-step-title">Gifts</span>

                                            </div>

                                        </li>
                                         <li>

                                            <div :class="step == 7 ? 'mtab m-liability m-active' : 'mtab m-liability'">

                                                <span class="m-step-no">7</span>

                                                <span class="m-step-title">Liability</span>

                                            </div>

                                        </li>
                                        <li>

                                            <div :class="step == 8 ? 'mtab m-executor m-active' : 'mtab m-executor'">

                                                <span class="m-step-no">8</span>

                                                <span class="m-step-title">Executor</span>

                                            </div>

                                        </li>
                                        <li>

                                            <div :class="step == 9 ? 'mtab m-tradegy-clause m-active' : 'mtab m-tradegy-clause'">
                                                <span class="m-step-no">9</span>

                                                <span class="m-step-title">Tradegy Clause</span>

                                            </div>

                                        </li>
                                        <li>

                                            <div :class="step == 10 ? 'mtab m-funeral-message m-active' : 'mtab m-funeral-message'">
                                                <span class="m-step-no">10</span>

                                                <span class="m-step-title">Funeral & Message</span>

                                            </div>

                                        </li>

                                    </ul>

                                </div>
                                <!--                                <div class="wizard-tab">-->
                                <!-- Nav tabs -->
                                <!--                                    <ul class="nav nav-tabs">-->
                                <!--                                        <li class="nav-item">-->
                                <!--                                            <a :class="step == 1 ? 'nav-link active':'nav-link' ">-->
                                <!--                                                <span class="step-no">1</span>-->
                                <!--                                                <span class="step-title">Personal info</span>-->
                                <!--                                            </a>-->
                                <!--                                        </li>-->
                                <!--                                        <li class="nav-item">-->
                                <!--                                            <a :class="step == 2 ? 'nav-link active':'nav-link' " >-->
                                <!--                                                <span class="step-no">2</span>-->
                                <!--                                                <span class="step-title">Children Details</span>-->
                                <!--                                            </a>-->
                                <!--                                        </li>-->
                                <!--                                        <li class="nav-item">-->
                                <!--                                            <a :class="step == 3 ? 'nav-link active':'nav-link' " >-->
                                <!--                                                <span class="step-no">3</span>-->
                                <!--                                                <span class="step-title">Guardian Details</span>-->
                                <!--                                            </a>-->
                                <!--                                        </li>-->
                                <!--                                        <li class="nav-item">-->
                                <!--                                            <a :class="step == 4 ? 'nav-link active':'nav-link' " >-->
                                <!--                                                <span class="step-no">4</span>-->
                                <!--                                                <span class="step-title">Beneficiary</span>-->
                                <!--                                            </a>-->
                                <!--                                        </li>-->
                                <!--                                        <li class="nav-item">-->
                                <!--                                            <a :class="step == 5 ? 'nav-link active':'nav-link' " >-->
                                <!--                                                <span class="step-no">5</span>-->
                                <!--                                                <span class="step-title">State / Property</span>-->
                                <!--                                            </a>-->
                                <!--                                        </li>-->
                                <!--                                        <li class="nav-item">-->
                                <!--                                            <a :class="step == 6 ? 'nav-link active':'nav-link' " >-->
                                <!--                                                <span class="step-no">6</span>-->
                                <!--                                                <span class="step-title">Gifts</span>-->
                                <!--                                            </a>-->
                                <!--                                        </li>-->
                                <!--                                        <li class="nav-item">-->
                                <!--                                            <a :class="step == 7 ? 'nav-link active':'nav-link' ">-->
                                <!--                                                <span class="step-no">7</span>-->
                                <!--                                                <span class="step-title">Executor</span>-->
                                <!--                                            </a>-->
                                <!--                                        </li>-->
                                <!--                                        <li class="nav-item">-->
                                <!--                                            <a :class="step == 8 ? 'nav-link active':'nav-link' " >-->
                                <!--                                                <span class="step-no">8</span>-->
                                <!--                                                <span class="step-title">Tradegy Clause</span>-->
                                <!--                                            </a>-->
                                <!--                                        </li>-->
                                <!--                                        <li class="nav-item">-->
                                <!--                                            <a :class="step == 9 ? 'nav-link active':'nav-link' " >-->
                                <!--                                                <span class="step-no">9</span>-->
                                <!--                                                <span class="step-title">Funeral & Message</span>-->
                                <!--                                            </a>-->
                                <!--                                        </li>-->
                                <!--                                    </ul>-->
                                <!--                                </div>-->
                            </div>
                            <div class="col-3">
                                <div class="will-mobile-toon">
                                    <img src="/themes/flatly/images/will1.svg" alt="">
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="wizard-tab">
                        <!-- Nav tabs -->
                        <ul class="nav nav-tabs">
                            <li class="nav-item">
                                <a :class="step == 1 ? 'nav-link personalinfo active':'nav-link personalinfo' ">
                                    <span class="step-no">1</span>
                                    <span class="step-title">Personal info</span>
                                </a>
                            </li>
                            <li class="nav-item">
                                <a :class="step == 2 ? 'nav-link children-details active':'nav-link children-details'">
                                    <span class="step-no">2</span>
                                    <span class="step-title">Children Details</span>
                                </a>
                            </li>
                            <li class="nav-item">
                                <a :class="step == 3 ? 'nav-link guardians-details active':'nav-link guardians-details' ">
                                    <span class="step-no">3</span>
                                    <span class="step-title">Guardian Details</span>
                                </a>
                            </li>
                            <li class="nav-item">
                                <a :class="step == 4 ? 'nav-link beneficiary  active':'nav-link beneficiary ' ">
                                    <span class="step-no">4</span>
                                    <span class="step-title">Beneficiary</span>
                                </a>
                            </li>
                            <li class="nav-item">
                                <a :class="step == 5 ? 'nav-link state-property active':'nav-link state-property' ">
                                    <span class="step-no">5</span>
                                    <span class="step-title">Estate / Property</span>
                                </a>
                            </li>
                            <li class="nav-item">
                                <a :class="step == 6 ? 'nav-link gifts active':'nav-link gifts' ">
                                    <span class="step-no">6</span>
                                    <span class="step-title">Gifts</span>
                                </a>
                            </li>
                            <li class="nav-item">
                                <a :class="step == 7 ? 'nav-link liability active':'nav-link liability' ">
                                    <span class="step-no">7</span>
                                    <span class="step-title">Liability</span>
                                </a>
                            </li>
                            <li class="nav-item">
                                <a :class="step == 8 ? 'nav-link executor active':'nav-link executor' ">
                                    <span class="step-no">8</span>
                                    <span class="step-title">Executor</span>
                                </a>
                            </li>
                            <li class="nav-item">
                                <a :class="step == 9 ? 'nav-link tradegy-clause active':'nav-link tradegy-clause' ">
                                    <span class="step-no">9</span>
                                    <span class="step-title">Tradegy Clause</span>
                                </a>
                            </li>
                            <li class="nav-item">
                                <a :class="step == 10 ? 'nav-link funeral-message active':'nav-link funeral-message' ">
                                    <span class="step-no">10</span>
                                    <span class="step-title">Funeral & Message</span>
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-sm-12 col-md-12 col-lg-12">
                    <div class="will-create-tab">
                        <!-- Tab panes -->
                        <div class="tab-content">
                            <!-- personal info tab start -->
                            <div :class="step == 1 ? 'tab-pane active show' : 'tab-pane fade'">
                                <step1 v-if="step == 1" @changeActiveTab="changeActiveTab" @updateFormData="updateFormData" :id="id"
                                       :states="states" :data="formData"></step1>
                            </div>
                            <!--children tab start-->
                            <div :class="step == 2 ? 'tab-pane active show' : 'tab-pane fade'">
                                <step2 v-if="step == 2" @changeActiveTab="changeActiveTab" @updateFormData="updateFormData" :id="id"
                                       :states="states" :data="formData"></step2>
                            </div>
                            <!-- guardian tab start -->
                            <div :class="step == 3 ? 'tab-pane active show' : 'tab-pane fade'">
                                <step3 v-if="step == 3" @changeActiveTab="changeActiveTab" @updateFormData="updateFormData" :id="id"
                                       :states="states" :data="formData"></step3>
                            </div>
                            <!--                        &lt;!&ndash; beneficiary tab start &ndash;&gt;-->
                            <div :class="step == 4 ? 'tab-pane active show' : 'tab-pane fade'">
                                <step4 v-if="step == 4" @changeActiveTab="changeActiveTab" @updateFormData="updateFormData" :id="id"
                                       :states="states" :data="formData"></step4>
                            </div>
                            <!--                         State Property tab start-->
                            <div :class="step == 5 ? 'tab-pane active show' : 'tab-pane fade'">
                                <step5 v-if="step == 5" @changeActiveTab="changeActiveTab" @updateFormData="updateFormData" :id="id"
                                       :data="formData"></step5>
                            </div>
                            <div :class="step == 6 ? 'tab-pane active show' : 'tab-pane fade'">
                                <step6 v-if="step == 6" @changeActiveTab="changeActiveTab" @updateFormData="updateFormData" :id="id"
                                       :data="formData"></step6>
                            </div>
                            <div :class="step == 7 ? 'tab-pane active show' : 'tab-pane fade'">
                                <step7 v-if="step == 7" @changeActiveTab="changeActiveTab" @updateFormData="updateFormData" :id="id"
                                       :states="states" :data="formData"></step7>
                            </div>
                            <div :class="step == 8 ? 'tab-pane active show' : 'tab-pane fade'">
                                <step8 v-if="step == 8" @changeActiveTab="changeActiveTab" @updateFormData="updateFormData" :id="id"
                                       :states="states" :data="formData"></step8>
                            </div>
                            <div :class="step == 9 ? 'tab-pane active show' : 'tab-pane fade'">
                                <step9 v-if="step == 9" @changeActiveTab="changeActiveTab" @updateFormData="updateFormData" :id="id"
                                       :states="states" :data="formData" :asset_types="asset_types"></step9>
                            </div>
                            <div :class="step == 10 ? 'tab-pane active show' : 'tab-pane fade'">
                                <step10 v-if="step == 10"@changeActiveTab="changeActiveTab" @updateFormData="updateFormData" :id="id"
                                       :data="formData"></step10>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import axios from 'axios';
    import Form from 'form-backend-validation';
    import FormErrors from '../../../../Core/Assets/js/components/FormErrors.vue';
    import step1 from './step/Step1';
    import step2 from './step/step2';
    import step3 from './step/step3';
    import step4 from './step/step4';
    import step5 from './step/step5';
    import step6 from './step/step6';
    import step7 from './step/step7';
    import step8 from './step/step8';
    import step9 from './step/step9';
    import step10 from './step/step10';

    export default {
        name: 'WillSubmissionComponent',
        props: ['id', 'data', 'states', 'asset_types'],
        components: {
            step1,
            step2,
            step3,
            step4,
            step5,
            step6,
            step7,
            step8,
            step9,
            step10,
        },
        data() {
            return {
                formData: '',
                dynamic: {
                    personal_info: '',
                    children_info: [],
                    guardian_info: [],
                    beneficiary_info: [],
                    proprty_info: {},
                },
                step: 0,
                form: new Form(),
                loading: false,
            };
        },
        beforeMount() {
            // this.getConfig();
            if (this.data != undefined && this.data.activeTab > 0) {
                this.step = this.data.activeTab;
            } else {
                this.step = 1;
            }

            if (this.data != undefined) {
                this.formData = this.data;
                console.log('record', this.formData);
            }
            console.log('this.data > ', this.data);
        },
        methods: {
            updateFormData(data) {
                console.log('data', data);
                this.formData = data;
            },
            changeActiveTab(step) {
                this.step = step;
                this.form = new Form({ activeTab: this.step });
                console.log('changeActiveTab');

                if (this.id != 0) {

                    this.form.post(route('api.will.wills.savedata', { id: this.id }))
                        .then((response) => {
                        })
                        .catch((error) => {
                        });
                } else {
                    this.form.post('/api/will/formData/add')
                        .then((response) => {
                        })
                        .catch((error) => {
                        });
                }
            },
            getConfig() {
                this.loading = false;

                this.childrenInfoConfig = this.config.children_info;
                this.guardianInfoConfig = this.config.guardian_info;
                this.beneficiaryInfoConfig = this.config.beneficiary_info;
                this.propertyInfoConfig = this.config.proprty_info;

                for (var col in this.config.personal_info) {
                    this.$set(this.dynamic.personal_info, col, {});
                    if (this.config.personal_info[col].Fields !== undefined) {
                        for (var cnfg in this.config.personal_info[col].Fields) {
                            if (this.config.personal_info[col].Fields[cnfg].fieldValue != undefined) {
                                // this.$set(this.dynamic.personal_info[col], cnfg, '');
                                this.$set(this.dynamic.personal_info[col], cnfg, this.config.personal_info[col].Fields[cnfg].fieldValue);
                            } else {
                                this.$set(this.dynamic.personal_info[col], cnfg, '');
                            }
                            this.setRule(this.config.personal_info[col].Fields[cnfg], 'personal_info', col, cnfg);
                        }
                    }
                }

                var childConfig = this.childrenInfoConfig.Fields;
                if (childConfig !== undefined) {
                    for (var cnfg in childConfig) {
                        if (childConfig[cnfg].fieldValue != undefined) {
                            this.$set(this.childInfoForm, cnfg, childConfig[cnfg].fieldValue);
                        } else {
                            this.$set(this.childInfoForm, cnfg, '');
                        }
                        this.setRule(childConfig[cnfg], 'children_info', '', cnfg);
                    }
                }

                var childConfig = this.guardianInfoConfig.Fields;
                if (childConfig !== undefined) {
                    for (var cnfg in childConfig) {
                        if (childConfig[cnfg].fieldValue != undefined) {
                            this.$set(this.dynamic.guardian_info, cnfg, childConfig[cnfg].fieldValue);
                        } else {
                            this.$set(this.dynamic.guardian_info, cnfg, '');
                        }
                        this.setRule(childConfig[cnfg], 'guardian_info', '', cnfg);
                    }
                }

                for (var col in this.beneficiaryInfoConfig) {
                    // this.dynamic.personal_info = _.merge(this.dynamic.personal_info, {[col]: {}});

                    this.$set(this.dynamic.beneficiary_info, col, {});
                    var childConfig = this.beneficiaryInfoConfig[col].Fields;
                    if (childConfig !== undefined) {
                        for (var cnfg in childConfig) {
                            if (childConfig[cnfg].fieldValue != undefined) {
                                // this.$set(this.dynamic.personal_info[col], cnfg, '');
                                this.$set(this.dynamic.beneficiary_info[col], cnfg, childConfig[cnfg].fieldValue);
                            } else {
                                this.$set(this.dynamic.beneficiary_info[col], cnfg, '');
                            }
                            this.setRule(childConfig[cnfg], 'beneficiary_info', col, cnfg);
                        }
                    }
                }

                var childConfig = this.propertyInfoConfig.Fields;
                if (childConfig !== undefined) {
                    for (var cnfg in childConfig) {
                        if (childConfig[cnfg].fieldValue != undefined) {
                            this.$set(this.dynamic.proprty_info, cnfg, childConfig[cnfg].fieldValue);
                        } else {
                            this.$set(this.dynamic.proprty_info, cnfg, '');
                        }
                        this.setRule(childConfig[cnfg], 'proprty_info', '', cnfg);
                    }
                }
                this.getContent();
                this.loading = true;
                console.log('dynamic', this.dynamic);
            },
            enableOption() {
                this.message = '';
            },
            foewardStep() {
                this.step = this.step - 1;
                this.message = '';
            },
            // editChilInfo(record, index) {
            //     this.edit = true;
            //     this.deleteIndex = index;
            //     for (const cnfg in this.dynamic.child_detail[index]) {
            //         this.$set(this.childInfoForm, cnfg, '');
            //         this.childInfoForm[cnfg] = this.dynamic.child_detail[index][cnfg];
            //     }
            //     // this.childInfoForm = {};
            //
            //     // this.childInfoForm = record;
            // },
            // editChildRecord() {
            //     for (const cnfg in this.childInfoForm) {
            //         this.dynamic.child_detail[this.deleteIndex] = _.merge(this.childInfoForm, { [cnfg]: this.childInfoForm[cnfg] });
            //     }
            //     this.edit = false;
            //     this.childInfoForm = {};
            //     this.$refs.dynamic.clearValidate();
            // },
            // deleteChildInfo() {
            //     this.dynamic.child_detail.splice(this.deleteIndex, 1);
            //     this.childInfoForm = {};
            // },
            // addChild() {
            //     this.childAdded = true;
            //     this.$set(this.dynamic, 'child_detail', []);
            //     this.$set(this.dynamic.child_detail, this.childrecordIndex, {});
            //     for (const cnfg in this.childInfoForm) {
            //         this.dynamic.child_detail[this.childrecordIndex] = _.merge(this.childInfoForm, { [cnfg]: this.childInfoForm[cnfg] });
            //     }
            //     console.log('child', this.dynamic.child_detail);
            //     this.childrecordIndex = this.childrecordIndex + 1;
            //     this.childInfoForm = {};
            //     this.$refs.dynamic.clearValidate();
            // },
            // chidrenInfo(value) {
            //     if (value == 'Yes') {
            //         this.childInfoShow = true;
            //     } else {
            //         this.childInfoShow = false;
            //     }
            // },
            editGuardianInfo(record, index) {
                this.edit = true;
                this.deleteGuardianIndex = index;
                for (const cnfg in this.dynamic.gurdian_detail[index]) {
                    this.$set(this.dynamic.guardian_info, cnfg, '');
                    this.dynamic.guardian_info[cnfg] = this.dynamic.gurdian_detail[index][cnfg];
                }
            },
            editGuardianRecord() {
                for (const cnfg in this.dynamic.guardian_info) {
                    this.dynamic.child_detail[this.deleteGuardianIndex] = _.merge(this.dynamic.guardian_info, { [cnfg]: this.dynamic.guardian_info[cnfg] });
                }
                this.edit = false;
                this.dynamic.guardian_info = {};
                this.$refs.dynamic.clearValidate();
            },
            deleteGuardianInfo() {
                this.dynamic.gurdian_detail.splice(this.deleteGuardianIndex, 1);
                this.dynamic.guardian_info = {};
            },
            addGuardian() {
                this.gurdianAdded = true;
                this.$set(this.dynamic, 'gurdian_detail', []);
                this.$set(this.dynamic.gurdian_detail, this.guardianRecordIndex, {});
                for (const cnfg in this.dynamic.guardian_info) {
                    this.dynamic.gurdian_detail[this.guardianRecordIndex] = _.merge(this.dynamic.guardian_info, { [cnfg]: this.dynamic.guardian_info[cnfg] });
                }
                console.log('child', this.dynamic.gurdian_detail);
                this.guardianRecordIndex = this.guardianRecordIndex + 1;
                this.dynamic.guardian_info = {};
                this.$refs.dynamic.clearValidate();
            },
            GuardianInfo(value) {
                if (value == 'Yes') {
                    this.GuardainForm = true;
                } else {
                    this.GuardainForm = false;
                }
            },

            SpecialInfo(value) {
                if (value == 'Yes') {
                    this.BeneficiarySpecialForm = true;
                } else {
                    this.BeneficiarySpecialForm = false;
                }
            },
            editSpecialClauseInfo(record, index) {
                this.deleteCluaseIndex = index;
                this.specialedit = true;
                for (const cnfg in this.dynamic.beneficiary_detail.specialCluase_detail[index]) {
                    this.$set(this.dynamic.beneficiary_info.special_clause, cnfg, '');
                    this.dynamic.beneficiary_info.special_clause[cnfg] = this.dynamic.beneficiary_detail.specialCluase_detail[index][cnfg];
                }
            },
            editSpecialClauseRecord() {
                for (const cnfg in this.dynamic.beneficiary_info.special_clause) {
                    this.dynamic.beneficiary_detail.specialCluase_detail[this.deleteCluaseIndex] = _.merge(this.dynamic.beneficiary_info.special_clause, { [cnfg]: this.dynamic.beneficiary_info.special_clause[cnfg] });
                }
                this.specialedit = false;
                this.dynamic.beneficiary_info.special_clause = {};
                this.$refs.dynamic.clearValidate();
            },
            deleteSpecialClauseInfo() {
                this.dynamic.beneficiary_detail.specialCluase_detail.splice(this.deleteCluaseIndex, 1);
                this.dynamic.beneficiary_info.special_clause = {};
            },
            addSpecialClause() {
                this.specialCluaseAdded = true;
                if (this.dynamic.beneficiary_detail == undefined) {
                    this.$set(this.dynamic, 'beneficiary_detail', []);
                }
                if (this.dynamic.beneficiary_detail.specialCluase_detail == undefined) {
                    this.$set(this.dynamic.beneficiary_detail, 'specialCluase_detail', []);
                }
                this.$set(this.dynamic, 'gurdian_detail', []);
                this.$set(this.dynamic.beneficiary_detail.specialCluase_detail, this.specialClauseRecordIndex, {});
                for (const cnfg in this.dynamic.beneficiary_info.special_clause) {
                    this.dynamic.beneficiary_detail.specialCluase_detail[this.specialClauseRecordIndex] = _.merge(this.dynamic.beneficiary_info.special_clause, { [cnfg]: this.dynamic.beneficiary_info.special_clause[cnfg] });
                }
                console.log('child', this.dynamic.beneficiary_detail.specialCluase_detail);
                this.specialClauseRecordIndex = this.specialClauseRecordIndex + 1;
                this.dynamic.beneficiary_info.special_clause = {};
                this.$refs.dynamic.clearValidate();
            },
            editBeneficiaryInfo(record, index) {
                this.deleteBeneficiaryIndex = index;
                this.edit = true;
                for (const cnfg in this.dynamic.beneficiary_detail.specialCluase_detail[index]) {
                    this.$set(this.dynamic.beneficiary_info.beneficiary_info, cnfg, '');
                    this.dynamic.beneficiary_info.beneficiary_info[cnfg] = this.dynamic.beneficiary_detail.specialCluase_detail[index][cnfg];
                }
                // this.childInfoForm = record;
            },
            editBeneficiaryRecord() {
                for (const cnfg in this.dynamic.beneficiary_info.beneficiary_info) {
                    this.dynamic.beneficiary_detail.beneficiary_detail[this.deleteBeneficiaryIndex] = _.merge(this.dynamic.beneficiary_info.beneficiary_info, { [cnfg]: this.dynamic.beneficiary_info.beneficiary_info[cnfg] });
                }
                this.edit = false;
                this.dynamic.beneficiary_info.beneficiary_info = {};
                this.$refs.dynamic.clearValidate();
            },
            deleteBeneficiaryInfo() {
                this.dynamic.beneficiary_detail.beneficiary_detail.splice(this.deleteBeneficiaryIndex, 1);
                this.dynamic.beneficiary_info.beneficiary_info = {};
            },
            addBeneficiary() {
                this.beneficiaryAdded = true;
                if (this.dynamic.beneficiary_detail == undefined) {
                    this.$set(this.dynamic, 'beneficiary_detail', []);
                }
                if (this.dynamic.beneficiary_detail.beneficiary_detail == undefined) {
                    this.$set(this.dynamic.beneficiary_detail, 'beneficiary_detail', []);
                }
                this.$set(this.dynamic, 'gurdian_detail', []);
                this.$set(this.dynamic.beneficiary_detail.beneficiary_detail, this.beneficiaryRecordIndex, {});
                for (const cnfg in this.dynamic.beneficiary_info.beneficiary_info) {
                    this.dynamic.beneficiary_detail.beneficiary_detail[this.beneficiaryRecordIndex] = _.merge(this.dynamic.beneficiary_info.beneficiary_info, { [cnfg]: this.dynamic.beneficiary_info.beneficiary_info[cnfg] });
                }
                console.log('child', this.dynamic.beneficiary_detail.beneficiary_detail);
                this.beneficiaryRecordIndex = this.beneficiaryRecordIndex + 1;
                this.dynamic.beneficiary_info.beneficiary_info = {};
                this.$refs.dynamic.clearValidate();
            },
            BeneficiaryInfo(value) {
                if (value == 'Yes') {
                    this.BeneficiaryForm = true;
                } else {
                    this.BeneficiaryForm = false;
                }
            },
            AssetTypeChange(type) {
                this.propertyType = type;
            },
            DisplaySpouceDetail(value) {
                if (value == 'married') {
                    this.spouceDetail = true;
                } else {
                    this.spouceDetail = false;
                }
            },
            getContent() {

            },
            getFieldRule(Title, FieldName = null, FieldType = null) {
                return {
                    required: true,
                    message: `${Title} field required`,
                };
            },
            setRule(config, parentField, col, cnfg) {
                // console.log('config',config);
                const { validations } = config;
                if (validations !== undefined) {
                    const required = validations.split('|');


                    // this.dynamicFieldsRules[parentField + '.' + col + '.' + cnfg] = [];
                    if (this.dynamicFieldsRules[parentField] == undefined) {
                        this.dynamicFieldsRules[parentField] = [];
                    }
                    if (col != '') {
                        if (this.dynamicFieldsRules[parentField][col] == undefined) {
                            this.dynamicFieldsRules[parentField][col] = [];
                        }
                        if (this.dynamicFieldsRules[parentField][col][cnfg] == undefined) {
                            this.dynamicFieldsRules[parentField][col][cnfg] = [];
                        }
                        if (cnfg != 'contact') {
                            this.dynamicFieldsRules[parentField][col][cnfg].push({
                                required: true,
                                message: `${config.title} field required`,
                            });
                        } else {
                            this.dynamicFieldsRules[parentField][col][cnfg].push({
                                required: true,
                                message: `${config.title} numeric value is required`,
                            });
                        }
                    } else {
                        if (this.dynamicFieldsRules[parentField][cnfg] == undefined) {
                            this.dynamicFieldsRules[parentField][cnfg] = [];
                        }

                        if (cnfg != 'contact') {
                            this.dynamicFieldsRules[parentField][cnfg].push({
                                required: true,
                                message: `${config.title} field required`,
                            });
                        } else {
                            this.dynamicFieldsRules[parentField][cnfg].push({
                                required: true,
                                type: 'number',
                                message: `${config.title} field required`,
                            });
                        }
                    }
                }
            },
            forSubmit(formRef) {
                console.log('dynamic', this.dynamic);
                this.form = new Form(this.dynamic);
                this.$refs[formRef].clearValidate();
                this.$refs[formRef].validate((valid) => {
                    // console.log('form',this.form.errors);
                    if (valid) {
                        this.form.post('/api/will/formData/add')
                            .then((response) => {
                                if (response.errors == false) {
                                    this.message = '';
                                    this.step = this.step + 1;
                                    this.$message({
                                        type: 'success',
                                        message: response.message,
                                    });
                                } else {
                                    console.log('dd');
                                    this.step = this.step + 1;
                                    console.log(this.step);
                                    // this.message = 'You must be at least 18 years old';
                                }
                            })
                            .catch((error) => {
                                this.message = 'You must be atleast 18 years old';
                                this.save = false;
                                this.$notify.error({
                                    title: 'Error',
                                    message: 'There are some errors in the form.',
                                });
                            });
                    } else {

                    }
                });
            },
            getRoute() {
                return route('api.will.wills.savedata');
            },
        },
    };
</script>

<style>
    .will-create-tab .el-input.is-active .el-input__inner, .el-input__inner:focus {
        border-color: #BB2044 !important;
    }

    .will-create-tab .el-radio__input.is-active .el-radio__inner, .el-radio__inner:focus {
        border-color: #BB2044 !important;
    }

    .will-create-tab .btn-next {
        background: #BB2044 !important;
    }

    .will-create-tab .btn-prev {
        background: #dddddd !important;
        color: #454545 !important;
    }

    .will-create-tab .will-form-wizard .form-control {
        border-width: 1px !important;
    }

    .will-create-tab .el-input__inner {
        font-size: 16px !important;
        height: 45px !important;
        padding: .375rem .45rem !important;
        color: #000000 !important;
    }

    .will-create-tab .btn-nex-prev.active {
        background-color: #454545 !important;
        color: #ffffff !important;
    }

    .will-create-tab.btn-sm {
        padding: .4rem 1rem !important;
        font-size: 0.875rem !important;
        line-height: 1.5 !important;
        border-radius: .2rem !important;
    }

    .will-create-tab .mini-btn {
        border: none !important;
        text-transform: capitalize !important;
    }

    .will-create-tab .will-create-tab .mr15 {
        margin-right: 15px !important;
    }

    .will-create-tab .add-btn {
        background-color: #F4DDE3 !important;
        color: #BB2044 !important;
    }

    .will-create-tab .delete-btn {
        background: #bb2044 !important;
        color: #ffffff !important;
    }

    .will-create-tab .cancel-btn {
        background-color: #828282 !important;
        color: #ffffff !important;
    }

    .will-create-tab .el-form-item {
        margin-bottom: 0px !important;
    }

    .will-create-tab .error-message {
        color: red;
        font-size: 15px;
    }

    .will-create-tab .el-input__prefix {
        left: inherit !important;
        transition: all .3s !important;
        right: 8px !important;
    }

    .will-create-tab .el-form-item {
        margin-bottom: 0px !important;
    }

    .will-create-tab .el-radio__inner::after {
        width: 11px;
        height: 11px;
        border-radius: 100%;
        background-color: #BB2044;
        content: "";
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%) scale(0);
        transition: transform .15s ease-in;
    }

    /*.item__label {*/
    /*     padding: 0 0 0px !important;*/
    /*}*/
    /*.el-form--label-top .el-form-item__label {*/
    /*    padding: 0 0 0px !important;*/

    /*}*/
    .el-form-item__label {
        line-height: 1px !important;
    }

    .el-form-item__error {
        position: sticky !important;
        top: 0% !important;
    }


    .el-radio__inner {
        width: 20px !important;
        height: 20px !important;
    }

    .will-create-tab .el-radio__input.is-checked .el-radio__inner {
        background-color: #ffffff !important;
        border: 1px solid #BB2044 !important;
    }

    .will-create-tab .el-radio__input.is-checked + .el-radio__label {
        color: #666666 !important;
    }

    .will-create-tab .el-select .el-input.is-focus .el-input__inner {
        border-color: #BB2044 !important;
    }

    .will-create-tab .el-select .el-input__inner:focus {
        border-color: #BB2044 !important;
    }

    .will-create-tab .el-select {
        display: block !important;
    }

    .will-create-tab .el-date-editor {
        display: block !important;
    }

    .will-create-tab .el-date-editor.el-input {
        width: 100% !important;
    }

    .will-create-tab .el-form-item.is-error .el-input__inner {
        border: 1px solid #ced4da;
    }

    .el-form-item.is-required:not(.is-no-asterisk) .el-form-item__label-wrap > .el-form-item__label:after, .el-form-item.is-required:not(.is-no-asterisk) > .el-form-item__label:after {
        content: '*';
        color: #F56C6C;
        margin-right: 4px;
    }

    .el-form-item.is-required:not(.is-no-asterisk) .el-form-item__label-wrap > .el-form-item__label:before, .el-form-item.is-required:not(.is-no-asterisk) > .el-form-item__label:before {
        content: none !important;
    }

</style>
