<template>
    <div class="card card-default shadow-none border-0 p-0">
        <div v-loading="loading">
            <div class="card-body">
                <h4 class="mb-3">
                    {{ 'Payment Invoice' }}
                </h4>
                <div class="will-invoice">
                    <div class="row">
                        <div class="col">
                            <img
                                src="/themes/flatly/images/logo-bg.svg"
                                alt="Will Guru"
                                class="invoice-logo"
                            >
                        </div>
                        <div class="col text-right">
                            <h4 class="text-muted "><b>WillGuru</b></h4>
                            <p v-if="data.address != undefined && data.address != '' && data.address != false" class="text-muted m-0 ">{{strip_html_tags(data.address)}}</p>
                            <p class="text-muted m-0 ">{{data.mobile}}</p>
                            <p class="text-muted m-0 ">{{data.email}}</p>
                            <p class="text-muted m-0 "><a href="https://www.willguru.in">www.willguru.in</a> </p>
                        </div>
                    </div>
                    <hr>
                    <div class="row contacts justify-content-between px-2">
                        <div class="col-12 col-md-6 text-left">
                            <div class="text-muted"><b>INVOICE TO:</b></div>
                            <p v-if="data.will != undefined" class="text-muted m-0">{{ data.will.full_name }}</p>
                            <p v-if="data.will != undefined" class="text-muted m-0 "> {{ data.will.full_address }}</p>
                            <p v-if="data.will != undefined" class="text-muted m-0"><i>{{ data.will.email }}</i></p>
                            <p v-if="data.will != undefined" class="text-muted m-0"><i>{{ data.will.mobile }}</i></p>
                        </div>
                        <div class="col-12 col-md-6 text-left payment_mode_div">
<!--                            <div class="text-gray"><b>ORDER ID:</b></div>-->
                            <div>
                                <p class="text-muted mb-0">{{ data.transaction_id }}</p>
                                <p  v-if="data.will != undefined" class="text-muted mb-1">
                                    Date :{{ dateFormat(data.updated_at) }}
                                </p>
                                <p class="m-0 text-muted"><i>Payment Mode : <templete v-if="data.payment_mode == 'CREDIT_CARD'"> {{'CREDIT CARD'}}</templete><templete v-else>{{ data.payment_mode }}</templete></i></p>
                            </div>
                        </div>
                    </div>
                    <div class="mt-3 mb-4">
                        <div class="table-responsive">
                            <table class="data-table table ">
                                <thead>
                                    <tr>
                                        <th>#</th>
                                        <th>Item</th>
                                        <th>Price</th>
                                        <th>Qty</th>
                                        <th>Total</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>1</td>
                                        <td>Payment for service</td>
<!--                                        <td>{{ data.status }}</td>-->
                                        <td>{{Math.round((data.order_amount + Number.EPSILON) * 100) / 100}}</td>
<!--                                        <td>-->
<!--                                            <span-->
<!--                                                v-if="data.coupon_code!= null && data.coupon_code != ''"-->
<!--                                            >{{ data.coupon_code }}</span>-->
<!--                                            <span v-else>N/A</span>-->
<!--                                        </td>-->
                                        <td>1</td>
<!--                                        <td>-->
<!--                                            <span-->
<!--                                                v-if="data.discount_value!= null && data.discount_value != '' && data.discount_type != null && data.discount_type != ''"-->
<!--                                            >{{ data.discount_value }} ({{ data.discount_type }})</span>-->
<!--                                            <span v-else>N/A</span>-->
<!--                                        </td>-->

                                        <td>{{Math.round((data.order_amount + Number.EPSILON) * 100) / 100}} </td>
                                    </tr>
                                    <!--                                <tr class="bg-light">-->
                                    <!--                                    <td class="text-right" colspan="6">-->
                                    <!--                                        <b>SUBTOTAL</b>-->
                                    <!--                                    </td>-->
                                    <!--                                    <td>{{data.subtotal}}</td>-->
                                    <!--                                </tr>-->
                                    <!--                                <tr class="bg-light">-->
                                    <!--                                    <td class="text-right" colspan="6">-->
                                    <!--                                        <b>Tax</b>-->
                                    <!--                                    </td>-->
                                    <!--                                    <td>{{data.subtotal}}</td>-->
                                    <!--                                </tr>-->
<!--                                    <tr class="bg-light">-->
<!--                                        <td class="text-right" colspan="4">-->
<!--                                            <h6 class="m-0"><b>Sub Total (<i class="fa fa-rupee"></i>):</b></h6>-->
<!--                                        </td>-->
<!--                                        <td><h5 class="m-0"><b>{{data.subtotal}}bh
</b></h5></td>-->
<!--                                    </tr>-->

                                    <tr class="bg-light">
                                        <td class="bg-light text-right" colspan="4">
                                            <h6 class="m-0"><b>Total (<i class="fa fa-rupee"></i>) :</b></h6>
                                        </td>
                                        <td class="bg-light"><h6 class="m-0"><b>{{
                                        Math.round((data.order_amount + Number.EPSILON) * 100) / 100}}</b></h6></td>
                                    </tr>
                                    <tr class="bg-light">

                                        <td class="bg-light text-right" colspan="4">
                                            <h6 class="m-0"><b>Discount (<i class="fa fa-rupee"></i>) :</b></h6>
                                        </td>
                                        <td class="bg-light"><h6 class="m-0"><b>{{ data.discount_amount }}</b></h6></td>
                                    </tr>
                                    <tr class="bg-light">

                                        <td class="bg-light text-right" colspan="4">
                                            <h6 class="m-0"><b>GRAND TOTAL (<i class="fa fa-rupee"></i>) :</b></h6>
                                        </td>
                                        <td class="bg-light"><h6 class="m-0"><b>{{Math.round((data.total + Number.EPSILON) * 100) / 100}}</b></h6></td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    
                    <h3 class="text-center"><b>Thank you!</b></h3>
<!--                    <div class="notices mt-3">-->
<!--                        <div>NOTICE:</div>-->
<!--                        <div class="notice">-->
<!--                            A finance charge of 1.5% will be made on unpaid balances after 30 days.-->
<!--                        </div>-->
<!--                    </div>-->
                    <hr>
                    <p class="text-muted text-center m-0">
                        <i> This is an electronically generated invoice and is valid without the signature and seal</i>
                    </p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import moment from 'moment';

    export default {
        name: 'PaymentReport',
        props: ['id'],
        data() {
            return {
                loading: false,
                data: {},
            };
        },
        mounted() {
            this.getRecord();
        },
        methods: {
            strip_html_tags(str) {

                if ((str === null) || (str === ''))
                    return false;
                else
                    str = str.toString();
                return str.replace(/<[^>]*>/g, '');
            },
            dateFormat(value) {
                return moment(String(value)).format('DD/MM/yyyy');
            },
            getRecord() {
                this.loading = true;
                axios.get(`/api/will/payments/${this.id}`)
                    .then((response) => {
                        this.data = response.data.data;
                        this.loading = false;
                    });
            },
        },
    };
</script>

<style>

</style>
