<template>
<!-- <h1>step7</h1> -->
    <el-form v-loading="loading" :ref="formName" :model="recordForm" label-width="120px" label-position="top">
        <div class="row">
            <div class="col-sm-12 col-md-12 col-lg-8">
                <div class="wizard-des">
                    <p>The executor of the Will is the person who is tasked with carrying out your wishes in accordance with your Will. This is a very important role. The
                        executor should be someone you trust, who is capable and dependable and can be relied upon to carry out your wishes accurately.
                       You should obtain the consent of this person to act as executor of your Will before naming him. Executor should be more than 18 years old.</p>
                    <p>Remember, your executor will be in charge of paying all your debts from your estate and can decide which asset to be used in order to pay off the debt.
                        Only the residual estate after paying off all your debts can be handed over to the beneficiaries in your Will.
                    </p>
                </div>

             <!--   <div class="radio-details-tab  tab-card">
                    <span class="will-radio-title pr-3">Do you want to add executor details?</span>
                    <div class="yn-radio">
                        <el-radio v-model="executor_info.add_executor" label="Yes" value="Yes"></el-radio>
                        <el-radio v-model="executor_info.add_executor" label="No" value="No"></el-radio>
                    </div>
                </div>-->

                <div class=" tab-card" v-if="executor_info.add_executor == 'Yes'">
                    <h2 class="will-create-title">Executor Details</h2>
                    <div class="form-row">
                        <div class="form-group col-3 col-sm-2 col-md-2 col-lg-2">
                            <el-form-item label="Title" prop="title" :rules="getTitleRule('Title')">

                                <el-select v-model="recordForm.title">
                                    <el-option label="Mr" value="Mr"></el-option>
                                    <el-option label="Mrs" value="Mrs"></el-option>
                                    <el-option label="Ms" value="Ms"></el-option>
                                    <el-option label="Miss" value="Miss"></el-option>
                                    <el-option label="Master" value="Master"></el-option>
                                    <!--<el-option label="Dr" value="Dr"></el-option>-->
                                    <!--<el-option label="Other" value="Other"></el-option>-->
                                </el-select>
                            </el-form-item>
                            <div v-if="form.errors.has('title')" class="el-form-item__error"
                                 v-text="form.errors.first('title')"></div>
                        </div>
                        <div class="form-group col-9 col-sm-4 col-md-4 col-lg-4">
                            <el-form-item label="First name" prop="first_name" :rules="getFieldRule('First name')">
                                <el-input maxlength="20" @input="value => recordForm.first_name = value" :value="recordForm.first_name | capitalize" type="text"
                                          placeholder="First Name"></el-input>
                                <div v-if="form.errors.has('first_name')" class="el-form-item__error"
                                     v-text="form.errors.first('first_name')"></div>
                            </el-form-item>
                        </div>
                        <div class="form-group col-sm-6 col-md-6 col-lg-6">
                            <el-form-item label="Last name" prop="last_name" :rules="getFieldRule('Last name')">
                                <el-input maxlength="20" @input="value => recordForm.last_name = value" :value="recordForm.last_name | capitalize" type="text"
                                          placeholder="Last Name"></el-input>
                                <div v-if="form.errors.has('last_name')" class="el-form-item__error"
                                     v-text="form.errors.first('last_name')"></div>
                            </el-form-item>
                        </div>
                        <!--<div class="form-group col-3 col-sm-2 col-md-2 col-lg-2"  v-if="recordForm.title == 'Other'">
                            <el-form-item label="Other title" prop="other_title" :rules="getFieldRule('Title')">
                                <el-input v-model="recordForm.other_title" type="text"
                                          placeholder="other title"></el-input>
                            </el-form-item>
                        </div>
                        <div class="form-group col-sm-6 col-md-6 col-lg-6"  v-if="recordForm.title == 'Other'">
                        </div>
                        <div class="form-group col-9 col-sm-4 col-md-4 col-lg-4"  v-if="recordForm.title == 'Other'">
                        </div>-->
                        <div class="form-group col-sm-6 col-md-6 col-lg-6">
                            <el-form-item label="Middle name">
                                <el-input maxlength="20" @input="value => recordForm.middle_name = value" :value="recordForm.middle_name | capitalize"
                                          placeholder="Middle Name"></el-input>
                                <div v-if="form.errors.has('middle_name')" class="el-form-item__error"
                                     v-text="form.errors.first('middle_name')"></div>
                            </el-form-item>
                        </div>
                        <div class="form-group col-sm-6 col-md-6 col-lg-6">
                            <el-form-item label="Relationship" prop="relationship" :rules="getFieldRule('Relationship')">
                                <el-input maxlength="20" @input="value => recordForm.relationship = value" :value="recordForm.relationship | capitalize" placeholder="Relationship"></el-input>
<!--                                <el-input maxlength="20" v-model="recordForm.relationship" placeholder="Relationship"></el-input>-->
                                <div v-if="form.errors.has('relationship')" class="el-form-item__error"
                                     v-text="form.errors.first('relationship')"></div>
                            </el-form-item>
                        </div>
                        <div class="col-sm-6 col-md-6 col-lg-6 executor-radio type-tooltip">
                            <div class="tooltips">
                                <span class="fa fa-question question_tooltip fa-tooltip"></span>
                                <div class="tooltip-box">
                                    The person named under <b>“Secondary”</b> will have the powers only if your selected primary person(s) predecease you.
                                </div>
                            </div>
                            <el-form-item label="Type" prop="type" :rules="getFieldRule('Type')">
                                <el-radio v-model="recordForm.type" label="Primary">Primary</el-radio>
                                <el-radio v-model="recordForm.type" :disabled="executor_info.records.length == 0  ? true : false" label="Secondary">Secondary</el-radio>
                            </el-form-item>
                        </div>
                    </div>
                    <h2 class="will-create-subtitle">Address
                        <el-button type="text" class="add-copy">(To copy your address please click <span @click="copy()" class="click_here">here</span>)</el-button>
                    </h2>
                    <div class="form-row">
                        <div class="form-group col-sm-6 col-md-6 col-lg-6">
                            <el-form-item label="Address 1" prop="address_1" :rules="getFieldRule('Address 1')">
                                <el-input maxlength="50" @input="value => recordForm.address_1 = value" :value="recordForm.address_1 | capitalize"
                                          placeholder="Address 1"></el-input>
                                <div v-if="form.errors.has('address_1')" class="el-form-item__error"
                                     v-text="form.errors.first('address_1')"></div>
                            </el-form-item>
                        </div>
                        <div class="form-group col-sm-6 col-md-6 col-lg-6">
                            <el-form-item label="Address 2" prop="address_2">
                            <el-input maxlength="50" @input="value => recordForm.address_2 = value" :value="recordForm.address_2 | capitalize"
                                      placeholder="Address 2"></el-input>
                            </el-form-item>
                        </div>
                        <div class="form-group col-sm-3 col-md-3 col-lg-3">
                            <el-form-item label="City" prop="city" :rules="getFieldRule('City')">
                                <el-input maxlength="20" @input="value => recordForm.city = value" :value="recordForm.city | capitalize" placeholder="City"></el-input>
                                <div v-if="form.errors.has('city')" class="el-form-item__error"
                                     v-text="form.errors.first('city')"></div>
                            </el-form-item>
                        </div>
                        <div class="form-group col-sm-3 col-md-3 col-lg-3">
                            <el-form-item label="State" prop="state" :rules="getFieldRule('State')">
                                <!--<el-select filterable  allow-create v-model="recordForm.state" placeholder="State">
                                    <el-option
                                            v-for="(record,index) in states"
                                            v-bind:key="index"
                                            :label="record.state"
                                            v-bind:value="record.state">
                                    </el-option>
                                </el-select>-->
                                <el-input maxlength="20" @input="value => recordForm.state = value" :value="recordForm.state | capitalize" placeholder="State"></el-input>

                                <div v-if="form.errors.has('state')" class="el-form-item__error"
                                     v-text="form.errors.first('state')"></div>
                            </el-form-item>
                        </div>

                        <div class="form-group col-sm-3 col-md- col-lg-3">
                            <el-form-item label="Pin Code" prop="pin_code">
                                <el-input maxlength="10" v-model="recordForm.pin_code" placeholder="Pin code"></el-input>
                               
                            </el-form-item>
                        </div>
                        <div class="form-group col-sm-3 col-md-3 col-lg-3">
                            <el-form-item label="Country" prop="country" :rules="getFieldRule('Country')">
                                <!--<el-select v-model="recordForm.country">
                                    <el-option label="India" value="India"></el-option>
                                </el-select>-->
                                <el-input maxlength="20" @input="value => recordForm.country = value" :value="recordForm.country | capitalize"
                                          placeholder="Country"></el-input>

                                <div v-if="form.errors.has('country')" class="el-form-item__error"
                                     v-text="form.errors.first('country')"></div>
                            </el-form-item>
                        </div>

                    </div>
                </div>

                <div class="will-btn-group row" v-if="executor_info.add_executor == 'Yes'">
                    <div class="col-3 col-sm-6 col-md-6 col-lg-6 text-left">
                        <el-button class="delete-btn mini-btn btn btn-sm" v-if="recordIndex != null" @click="deleteItem(recordIndex)">delete</el-button>
                    </div>
                    <div class="col-9 col-sm-6 col-md-6 col-lg-6 text-right">
                        <el-button v-if="recordIndex != null" class="cancel-btn  mini-btn btn mr15 btn-sm" @click="cancel()">cancel</el-button>
                        <el-button v-if="recordIndex == null" title="Add Details" class="add-btn  mini-btn btn btn-sm" :loading="loading" :disabled="loading" @click="addRecord()">Add Executor</el-button>
                        <el-button v-else title="Update Details" class="add-btn  mini-btn btn btn-sm" :loading="loading" :disabled="loading" @click="addRecord()">Update Executor</el-button>
                    </div>

                </div>
                <div v-if="message != ''" class="personalinfo-tab tab-card">
                    <h2 class="will-create-title error-message text-center">{{ message }}</h2>
                </div>
            </div>
            <div class="col-sm-12 col-md-12 col-lg-4 position-relative">
                <div class="will-form-image">
                    <div class="will-toon">
                        <img src="/themes/flatly/images/will7.svg" class="">
                    </div>

                </div>
                <div class="document-img">
                    <img src="/themes/flatly/images/document.svg">
                </div>
                <div class="details-add" v-if="executor_info.records.length > 0">
                    <h3 class="details-add-title">Executor</h3>
                    <div class="tab-card guardian-details-list details-list" v-for="(info,index) in executor_info.records" v-if="executor_info.records.length > 0">
                        <h2 class="guardian-name">{{ makeName(info.title,info.first_name,info.middle_name,info.last_name) }}</h2>
                        <span class="guardian-relation">{{ info.relationship}}</span>
                        <span class="guardian-type">{{ info.type }}</span>
                        <p class="child-address">{{ makeAddress(info.address_1,info.address_2,info.state,info.city,info.pin_code) }}</p>

                        <!--                        <span class="guardian-type">{{ info.first_name}}</span>-->
                        <!--                        <p class="guardian-address">3471 Locust Street, MI - Michigan, 49686</p>-->
                        <el-button type="text" title="Update Details" @click="editRecord(index)"><a class="fa fa-pencil"></a></el-button>
                    </div>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col-sm-12 col-md-12 col-lg-12">
                <div class="will-rext-prev-btn">
                    <el-button class="btn btn-prev active btn-nex-prev" @click="backStep()"><i class="fa fa-angle-left"></i></el-button>
                    <el-button class="btn btn-next btn-nex-prev" @click="submit()"><i class="fa fa-angle-right"></i></el-button>
                </div>
            </div>
        </div>

    </el-form>
</template>

<script>
    import Form from 'form-backend-validation';
    import Common from '../../mixins/commonMethod';
    import {MessageBox} from "element-ui";

    var uuid = require('uuid');

    export default {
        name: "step8",
        props: ['data', 'id', 'states'],
        mixins: [Common],
        data() {
            return {
                activeTab: 8,
                message: '',
                loading: false,
                form: new Form(),
                recordIndex: null,
                executor_info: {
                    add_executor: 'Yes',
                    records: []
                },
                formName: 'recordForm',
                recordForm: {
                    id: '',
                    title: '',
                    other_title: '',
                    first_name: '',
                    last_name: '',
                    type: 'Primary',
                    address_1: '',
                    address_2: '',
                    city: '',
                    state: '',
                    pin_code: '',
                    country: '',
                    middle_name: '',
                    relationship: '',
                }
            }
        },
        filters: {
            capitalize: function (value) {
                if (!value) return ''
                // value = value.toString().toLowerCase()
                return value.charAt(0).toUpperCase() + value.slice(1)
            }
        },
        methods: {
            copy() {
                this.recordForm.address_1 = this.data.personal_info.personal_info.address_1;
                this.recordForm.address_2 = this.data.personal_info.personal_info.address_2;
                this.recordForm.city = this.data.personal_info.personal_info.city;
                this.recordForm.state = this.data.personal_info.personal_info.state;
                this.recordForm.country = this.data.personal_info.personal_info.country;
                this.recordForm.pin_code = this.data.personal_info.personal_info.pin_code;
            },
            deleteItem(index) {
                MessageBox.confirm(`Do you really want to delete?`,
                    "Confirmation",
                    {
                        confirmButtonText: "OK",
                        cancelButtonText: "Cancel",
                        type: "warning"
                    }).then(() => {
                        this.loading = true;
                        this.form = new Form({ activeTab: this.activeTab, executor_info: this.executor_info });
                        if (this.id != 0) {
                            console.log('id', this.id);
                            this.form.post(route('api.will.wills.savedata', { id: this.id }))

                                .then((response) => {
                                    console.log('id', this.id);
                                    this.loading = false;
                                    if (response.errors == false) {
                                        if (addInfo != 'Yes') {
                                            this.$emit('changeActiveTab', this.activeTab + 1);
                                            this.$emit('updateFormData', response.data);
                                        }
                                    }

                                })
                                .catch((error) => {
                                    this.loading = false;
                                });
                        } else {
                            this.form.post('/api/will/formData/add')
                                .then((response) => {
                                    this.loading = false;
                                    if (response.errors == false) {
                                        this.message = '';
                                        if (addInfo != 'Yes') {
                                            this.$emit('changeActiveTab', this.activeTab + 1);
                                            this.$emit('updateFormData', response.data);
                                        }
                                    } else {
                                        if (response.dashboardRoute != undefined) {
                                            window.location = (response.dashboardRoute);
                                        }
                                    }
                                })
                                .catch((error) => {
                                    this.loading = false;
                                });
                        }
                    this.deleteRecord(index);
                }).catch(() => {
                });
            },
            cancel() {
                this.recordIndex = null;
                this.resetForm();
            },
            backStep() {
                this.$emit('changeActiveTab', this.activeTab - 1);
            },
            getFieldRule(Title, FieldName = null, FieldType = null) {
                return {
                    required: true,
                    message: `${Title} field is required`,
                };
            },
            editRecord(index) {

                this.recordIndex = index;
                this.executor_info.add_executor = 'Yes';
                var record = {};
                for (const cnfg in this.executor_info.records[index]) {
                    record = _.merge(record, { [cnfg]: this.executor_info.records[index][cnfg] })
                }
                this.recordForm = record;
            },
            deleteRecord(deleteIndex) {
                this.executor_info.records.splice(deleteIndex, 1);
                this.recordIndex = null;
                var isPrimary = 0;
                console.log(this.executor_info.records.length);
                for(var col in this.executor_info.records){
                    if(this.executor_info.records[col].type == 'Primary'){
                        isPrimary = 1;
                        break;
                    }
                }
                if(isPrimary === 0 && this.executor_info.records.length >= 1){
                    console.log(this.executor_info.records[0].type);
                    this.executor_info.records[0].type = 'Primary';
                }
                this.resetForm();
                // if (this.executor_info.records.length == 0)
                    // this.executor_info.add_executor = 'No';
                this.submit('Yes');
            },
            getTitleRule(Title, FieldName = null, FieldType = null) {
                return {
                    required: true,
                    message: `field required`,
                };
            },
            resetForm() {
                this.$refs[this.formName].resetFields();
                this.recordForm.middle_name = '';
            },
            addRecord() {
                this.loading = true;
                this.message = '';
                this.$refs[this.formName].validate((valid) => {
                    if (valid) {
                        this.form = new Form({ activeTab: this.activeTab, executor_record: this.recordForm });
                        if (this.id != 0) {
                            console.log('id', this.id);
                            this.form.post(route('api.will.wills.savedata', { id: this.id }))

                                .then((response) => {
                                    this.loading = false;
                                    if (response.errors == false) {
                                        this.addInfo();
                                    }
                                    else{
                                        if(response.dashboardRoute != undefined){
                                            window.location = (response.dashboardRoute);
                                        }
                                    }
                                })
                                .catch((error) => {
                                    this.loading = false;
                                });
                        } else {
                            this.form.post('/api/will/formData/add')
                                .then((response) => {
                                    this.loading = false;
                                    if (response.errors == false) {
                                        this.addInfo();
                                    } else {
                                        if (response.dashboardRoute != undefined) {
                                            window.location = (response.dashboardRoute);
                                        }
                                    }
                                })
                                .catch((error) => {
                                    this.loading = false;
                                });
                        }
                    }
                    else {
                        this.loading = false;
                    }
                });
                // this.loading = false;
            },
            addInfo() {
                var record = {};
                for (const cnfg in this.recordForm) {
                    record = _.merge(record, { [cnfg]: this.recordForm[cnfg] })
                }
                if (this.recordIndex == null) {
                    record.id = uuid.v1();
                    console.log('record', record);
                    this.executor_info.records.push(record);
                } else {
                    this.executor_info.records[this.recordIndex] = record;
                    this.recordIndex = null;
                }
                this.resetForm();
                this.submit('Yes');
            },
            submit(addInfo = null) {
                window.scrollTo({top: 0, behavior: 'smooth'});
                this.message = '';
                if (this.executor_info.add_executor == 'Yes' && this.executor_info.records.length == 0) {
                    console.log('error');
                    this.$refs[this.formName].validate((valid) => {
                        if (valid) {
                            this.executor_info.add_executor = 'Yes';
                            this.message = "Please click on 'Add Executor' button to save the details";
                        } else {
                            this.executor_info.add_executor = 'Yes';
                        }
                    });
                }
                else {
                    this.loading = true;
                    this.form = new Form({ activeTab: this.activeTab, executor_info: this.executor_info });
                    if (this.id != 0) {
                        console.log('edit id', this.id);
                        this.form.post(route('api.will.wills.savedata', { id: this.id }))

                            .then((response) => {
                                console.log('id', this.id);
                                this.loading = false;
                                if (response.errors == false) {
                                    if (addInfo != 'Yes') {
                                        this.$emit('changeActiveTab', this.activeTab + 1);
                                        this.$emit('updateFormData', response.data);
                                    }
                                }

                            })
                            .catch((error) => {
                                this.loading = false;
                            });
                    } else {
                        console.log('add id', this.id);
                        this.form.post('/api/will/formData/add')
                            .then((response) => {
                                this.loading = false;
                                if (response.errors == false) {
                                    this.message = '';
                                    if (addInfo != 'Yes') {
                                        this.$emit('changeActiveTab', this.activeTab + 1);
                                        this.$emit('updateFormData', response.data);
                                    }
                                } else {
                                    if (response.dashboardRoute != undefined) {
                                        window.location = (response.dashboardRoute);
                                    }
                                }
                            })
                            .catch((error) => {
                                this.loading = false;
                            });
                    }
                }
            }
        },
        mounted() {
            this.loading = true;
            this.executor_info.add_executor = 'Yes';
            if (this.data != undefined && this.data.executor_info != undefined) {
                this.executor_info.records = this.data.executor_info.records;

                if (this.executor_info.records.length > 0) {
                    this.executor_info.add_executor = 'Yes';
                }
            }
            this.loading = false;
        },
        watch: {
            'executor_info.add_executor': function () {
                if (this.executor_info.add_executor == "No") {
                    this.executor_info.records = [];
                }
            },
            'recordForm.title': function () {
                if (this.recordForm.title != "Other") {
                    if (this.recordForm.other_title != '') {
                        this.recordForm.other_title = ''
                    }
                }
            }
        }
    };

</script>

<style>
    .tooltips{
        word-break: break-word;
    }
</style>
