<template>

    <div class="card card-default shadow-none">
        <div v-loading="loading">
            <div class="card-header">
                <h5 class="m-0">
                    {{ 'Add Comment' }}
                </h5>
            </div>
            <div class="card-body">
                <div id="errorDiv" v-if="message != ''">
                    <h2 class="will-create-title alert alert-success fade in alert-dismissable text-center">{{ message }}</h2>
                </div>

                <el-form ref="comment" @keydown="form.errors.clear($event.target.name)" :model="comment"
                         label-width="120px" label-position="top">

                        <div class="row">
                            <div class="form-group col-sm-12 col-md-12 col-lg-12">
                                <el-form-item label="Post Comment" prop="comment_note" :rules="getFieldRule('Comment')">
                                    <el-input v-model="comment.comment_note" type="textarea"
                                              placeholder="Enter Comment"></el-input>
                                </el-form-item>
                                <div v-if="form.errors.has('comment_note')" class="el-form-item__error"
                                     v-text="form.errors.first('comment_note')"></div>
                            </div>
                    <!--        <div class="form-group col-sm-3 col-md-3 col-lg-3">
                                <el-form-item label="Visibility Type" prop="visibility_type"
                                              :rules="getFieldRule('Type')">

                                    <el-radio-group size="small" v-model="comment.visibility_type">
                                        <el-radio-button :label="'Public'">{{ 'Public' }}</el-radio-button>
                                        <el-radio-button :label="'Private'">{{ 'Private' }}</el-radio-button>
                                    </el-radio-group>
                                </el-form-item>
                                <div v-if="form.errors.has('visibility_type')" class="el-form-item__error"
                                     v-text="form.errors.first('visibility_type')"></div>

                            </div>-->
                        </div>
                        <div class="form-group col-sm-12 col-md-12 col-lg-12">

                            <el-form-item>
                                <el-button type="primary" @click="submitComment()">
                                    {{ 'Create' }}
                                </el-button>
                            </el-form-item>
                        </div>

                </el-form>
                <!--<div class="timeline mb-0">
                    <div v-for="(value,index) in data" v-if="data.length > 0">
                        <i class="fa fa-comment bg-danger circle"></i>
                        <div class="timeline-item">
                            <span class="time"><i class="fa fa-clock"></i> {{dateFormat(value.created_at)}}</span>
                            <h3 class="timeline-header no-border"><a>{{value.user.full_name }}</a><small>{{value.user_type
                                }}</small>
                            </h3>
                            <div class="timeline-body">
                                {{value.comment_note }}
                            </div>
                        </div>
                    </div>
                    <div v-else>
                        <h5 class="text-center">No History Exist</h5>
                    </div>
                </div>-->
            </div>
        </div>
    </div>
</template>

<script>
    import Form from 'form-backend-validation';
    import axios from 'axios';
    import moment from 'moment';

    export default {
        name: "Communication",
        props: ['id','user_id','ref','addby'],
        data() {
            return {
                loading: false,
                form: new Form(),
                data: {},
                message: '',
                comment: {
                    comment_note: '',
                    visibility_type: 'Public',
                    user_id: 0,
                    will_id: 0,
                    user_type: '',
                },
            }
        },
        methods: {
            getFieldRule(Title) {
                return {
                    required: true,
                    message: `${Title} field required`,
                };
            },
            dateFormat(value) {
                return moment(String(value)).format('D/M/Y hh:mm:A');
            },
            submitComment() {
                this.loading = true;
                this.comment.user_id = this.user_id;
                this.comment.will_id = this.id;
                this.form = new Form(this.comment);
                if(this.addby !== undefined){
                    this.form.user_type = 'will_user'
                }
                this.$refs.comment.validate((valid) => {
                    if (valid) {
                        this.form.post(`/api/will/comment/add`)
                            .then((response) => {
                                this.loading = false;
                                this.message = response.message;
                                var element = document.getElementsByClassName("alert");
                                element[0].classList.remove("hide");
                                console.log(this.message);
                            })
                            // .catch((error) => {
                            //     this.loading = false;
                            //     this.$notify.error({
                            //         title: 'Error',
                            //         message: 'There are some errors in the form.',
                            //     });
                            //
                            // });
                        this.$refs.comment.resetFields();
                    } else {
                        this.loading = false;
                    }
                });
            },
        },
        mounted() {
            this.comment.user_id = this.user_id;
            this.message = '';
            // this.getRecord();
        },
    }
</script>

<style scoped>
    .el-button--primary {
        color: #FFF;
        background-color: #bb2044;
        border-color: #bb2044;
    }
    .success-message{
        color: green;
    }
</style>
