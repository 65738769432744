<template>
	<el-form
		v-loading="loading"
		:ref="formName"
		:model="giftForm"
		label-width="120px"
		label-position="top"
	>
		<div class="row">
			<div class="col-sm-12 col-md-12 col-lg-8">
				<div class="wizard-des">
					<p>
						A specific gift is a particular possession or amount of money
						that you’d like to leave someone in your Will. For example,
						sentimental things like jewellery or photo albums or Stamp
						Collection or valuable gifts like a car or 1,00,000 cash to
						charity. Specific gifts must be described precisely so that
						it’s clear for your executors and avoids any disputes.
					</p>
				</div>
				<div class="radio-details-tab tab-card">
					<span class="will-radio-title pr-3"
						>Do you want to add gift details?</span
					>

					<div class="yn-radio">
						<el-radio v-model="gift_info.add_gift" label="Yes"
							>Yes</el-radio
						>
						<el-radio v-model="gift_info.add_gift" label="No"
							>No</el-radio
						>
					</div>
				</div>
				<div class="tab-card" v-if="gift_info.add_gift == 'Yes'">
					<h2 class="will-create-title">Gift Details</h2>

					<div class="form-row">
						<div class="form-group col-sm-6 col-md-6 col-lg-6">
							<el-form-item
								label="Type"
								prop="type"
								:rules="getFieldRule('Type')"
							>
								<el-select v-model="giftForm.type">
									<el-option value="Jewellery">Jewellery</el-option>
									<el-option value="Cash">Cash</el-option>
									<el-option value="Collectable"
										>Collectable</el-option
									>
									<el-option value="Vehicle">Vehicle</el-option>
									<el-option value="Other">Other</el-option>
								</el-select>
							</el-form-item>
						</div>
						<div class="form-group col-sm-6 col-md-6 col-lg-6">
							<el-form-item
								label="Description"
								v-if="giftForm.type == 'Cash'"
								prop="description"
								:rules="getFieldRule('Description')"
							>
								<el-input
									maxlength="50"
									type="text"
									@input="(value) => (giftForm.description = value)"
									:value="giftForm.description | capitalize"
									placeholder="1 lakh"
								></el-input>
								<div
									v-if="form.errors.has('description')"
									class="el-form-item__error"
									v-text="form.errors.first('description')"
								></div>
							</el-form-item>
							<el-form-item
								label="Description"
								v-else-if="giftForm.type == 'Vehicle'"
								prop="description"
								:rules="getFieldRule('Description')"
							>
								<el-input
									maxlength="50"
									type="text"
									@input="(value) => (giftForm.description = value)"
									:value="giftForm.description | capitalize"
									placeholder="Honda Civic-Reg no.XXX"
								></el-input>
								<div
									v-if="form.errors.has('description')"
									class="el-form-item__error"
									v-text="form.errors.first('description')"
								></div>
							</el-form-item>
							<el-form-item
								label="Description"
								v-else-if="giftForm.type == 'Jewellery'"
								prop="description"
								:rules="getFieldRule('Description')"
							>
								<el-input
									maxlength="50"
									type="text"
									@input="(value) => (giftForm.description = value)"
									:value="giftForm.description | capitalize"
									placeholder="E.G. 100gm Gold"
								></el-input>
								<div
									v-if="form.errors.has('description')"
									class="el-form-item__error"
									v-text="form.errors.first('description')"
								></div>
							</el-form-item>
							<el-form-item
								label="Description"
								v-else-if="giftForm.type == 'Collectable'"
								prop="description"
								:rules="getFieldRule('Description')"
							>
								<el-input
									maxlength="50"
									type="text"
									@input="(value) => (giftForm.description = value)"
									:value="giftForm.description | capitalize"
									placeholder="Eg: Stamp Collection"
								></el-input>
								<div
									v-if="form.errors.has('description')"
									class="el-form-item__error"
									pe
									v-text="form.errors.first('description')"
								></div>
							</el-form-item>
							<el-form-item
								label="Description"
								v-else
								prop="description"
								:rules="getFieldRule('Description')"
							>
								<el-input
									maxlength="50"
									type="text"
									@input="(value) => (giftForm.description = value)"
									:value="giftForm.description | capitalize"
									placeholder="Add description"
								></el-input>
								<div
									v-if="form.errors.has('description')"
									class="el-form-item__error"
									v-text="form.errors.first('description')"
								></div>
							</el-form-item>
						</div>
						<div class="form-group col-sm-6 col-md-6 col-lg-6">
							<el-form-item
								label="Location"
								prop="location"
								:rules="getFieldRule('Location')"
							>
								<el-input
									maxlength="50"
									type="text"
									@input="(value) => (giftForm.location = value)"
									:value="giftForm.location | capitalize"
									placeholder="E.G. SBI Noida Bank Locker"
								></el-input>
								<div
									v-if="form.errors.has('location')"
									class="el-form-item__error"
									v-text="form.errors.first('location')"
								></div>
							</el-form-item>
						</div>
					</div>
					<h2 class="will-create-subtitle">
						Beneficiary
						<!--                    <a href="#" class="add-copy">(Add Beneficiary)</a>-->
						<el-button type="text" class="add-copy" v-if="!addbeneficiarybutton" @click="activateAddBeneficiary()" style="color:blue !important"
							>(Add new beneficiary)</el-button
						>
						<span class="beneficiary-total"
							><span class="text-danger"
								>{{ primaryBeneficiariesTotal }}%</span
							>
							/ <small>100%</small>
						</span>
					</h2>
					<div class="form-row beneficiary-add mb-3">
						<div
							class="col-sm-6 col-md-6 col-lg-6"
							v-for="(info, index) in data.beneficiary_info
								.beneficiaries"
							v-if="
								giftForm.secondary_beneficiaries[info.id] ==
									undefined ||
									(giftForm.secondary_beneficiaries[info.id] !=
										undefined &&
										giftForm.secondary_beneficiaries[info.id] == 0)
							"
						>
							<div class="row">
								<div
									class="form-group col-8 col-sm-6 col-md-6 col-lg-6"
								>
									<label class="wg form-check-label"
										>{{ info.first_name }} {{ info.last_name }}
										<input
											type="checkbox"
											:checked="
												giftForm.primary_beneficiaries[info.id] !=
													undefined &&
												giftForm.primary_beneficiaries[info.id] > 0
													? true
													: false
											"
											@change="
												changeBeneficiary($event, info, 'primary')
											"
										/>
										<span class="checkmark"></span>
									</label>
								</div>
								<div class="form-group col-4 col-md-6 col-lg-6">
									<input
										type="number"
										v-model="giftForm.primary_beneficiaries[info.id]"
										v-on:keyup="allocatePercentageValue('Primary')"
										v-on:change="allocatePercentageValue('Primary')"
										class="form-control form-control-sm"
										placeholder="Allocation%"
									/>
								</div>
							</div>
						</div>
					</div>
					<div
						class="allocationerror mt-2"
						v-if="primaryBeneficiariesTotal != 100"
					>
						<p>Please allocate Exactly 100%</p>
					</div>
					<div class="radio-details-tab w-100 mb-3 d-inline-block">
						<span class="will-radio-title pr-1"
							>Do you want to Add Secondary Beneficiary</span
						>
						<div
							class="type-tooltip will-tooltip"
							style="position: relative; display: inline-block"
						>
							<div class="tooltips">
								<span
									class="fa fa-question fa-tooltip question_tooltip"
									style="position: relative; left: 0px; top: -10px"
								></span>
								<div
									class="tooltip-box"
									style="width: 350px; left: 7px; bottom: 45px"
								>
									The person named under <b>“Secondary”</b> will have
									the powers only if your selected primary person(s)
									predecease you.
								</div>
							</div>
						</div>
						<div
							class="yn-radio"
							style="position: relative; display: inline-block; left: 20px"
						>
							<el-radio
								v-model="giftForm.second_beneestatey"
								label="Yes"
								>Yes</el-radio
							>
							<el-radio
								v-model="giftForm.second_beneestatey"
								label="No"
								>No</el-radio
							>
						</div>
					</div>
					<span v-if="giftForm.second_beneestatey == 'Yes'">
						<h2 class="will-create-subtitle ">Secondary Beneficiary
						<el-button type="text" class="add-copy" v-if="!addbeneficiarybutton" @click="activateAddBeneficiary()" style="color:blue !important">(Add Beneficiary)</el-button>
						<span class="beneficiary-total"><span
								class="text-danger">{{secondryBeneficiariesTotal}}%</span> / <small>100%</small> </span>
						</h2>
						<div class="form-row beneficiary-add mb-3">
							<div class="col-sm-6 col-md-6 col-lg-6"
								v-for="(info,index) in data.beneficiary_info.beneficiaries"
								v-if="giftForm.primary_beneficiaries[info.id] == undefined  || (giftForm.primary_beneficiaries[info.id] != undefined && giftForm.primary_beneficiaries[info.id] == 0)">
								<div class="row">
									<div class="form-group col-8 col-sm-6 col-md-6 col-lg-6">
										<label class="wg form-check-label">{{info.first_name}} {{info.last_name}} <input
												type="checkbox"
												:checked="giftForm.secondary_beneficiaries[info.id]!=undefined && giftForm.secondary_beneficiaries[info.id] > 0 ? true:false"
												@change="changeBeneficiary($event,info)"> <span class="checkmark"></span>
										</label>
									</div>
									<div class="form-group col-4 col-md-6 col-lg-6">
										<input type="number" v-model="giftForm.secondary_beneficiaries[info.id]"
											v-on:keyup="allocatePercentageValue('Secondary')"
											v-on:change="allocatePercentageValue('Secondary')"
											class="form-control form-control-sm" placeholder="Allocation%">
									</div>
								</div>
							</div>
						</div>
						<div class="allocationerror mt-2" v-if="secondryBeneficiariesTotal != 100">
							<p>Please allocate Exactly 100%</p>
						</div>
                	</span>
					<div v-if="addbeneficiarybutton">OR</div>
					<el-form v-if="addbeneficiarybutton" v-loading="loading" ref="beneficiaryForm" :model="beneficiaryForm" label-width="120px"
                     label-position="top">
                            <h2 class="will-create-title">Add New Beneficiary</h2>
                            <div class="form-row">
                                <div class="form-group col-3 col-sm-2 col-md-2 col-lg-2">
                                    <el-form-item label="Title" prop="title" :rules="getTitleRule('Title')">
                                        <el-select v-model="beneficiaryForm.title">
                                            <el-option label="Mr" value="Mr"></el-option>
                                            <el-option label="Mrs" value="Mrs"></el-option>
                                            <el-option label="Ms" value="Ms"></el-option>
                                            <el-option label="Miss" value="Miss"></el-option>
                                            <el-option label="Master" value="Master"></el-option>
                                            <!--<el-option label="Dr" value="Dr"></el-option>-->
<!--                                            <el-option label="Other" value="Other"></el-option>-->
                                        </el-select>
                                        <div v-if="form.errors.has('title')" class="el-form-item__error"
                                             v-text="form.errors.first('title')"></div>
                                    </el-form-item>
                                </div>
								<div class="form-group col-9 col-sm-4 col-md-4 col-lg-4">
                                    <el-form-item label="First name" prop="first_name"
                                                  :rules="getFieldRule('First name')">
                                        <el-input maxlength="20" @input="value => beneficiaryForm.first_name = value"
                                                  :value="beneficiaryForm.first_name | capitalize" type="text"
                                                  placeholder="First Name"></el-input>
                                        <div v-if="form.errors.has('first_name')" class="el-form-item__error"
                                             v-text="form.errors.first('first_name')"></div>
                                    </el-form-item>
                                </div>
                                <div class="form-group col-sm-6 col-md-6 col-lg-6">
                                    <el-form-item label="Last name" prop="last_name" :rules="getFieldRule('Last name')">
                                        <el-input maxlength="20" @input="value => beneficiaryForm.last_name = value"
                                                  :value="beneficiaryForm.last_name | capitalize" type="text"
                                                  placeholder="Last name"></el-input>
                                        <div v-if="form.errors.has('last_name')" class="el-form-item__error"
                                             v-text="form.errors.first('last_name')"></div>
                                    </el-form-item>
                                </div>
								<div class="form-group col-sm-6 col-md-6 col-lg-6">
                                    <el-form-item label="Middle name">
                                        <el-input maxlength="20" @input="value => beneficiaryForm.middle_name = value"
                                                  :value="beneficiaryForm.middle_name | capitalize" type="text"
                                                  placeholder="Middle name"></el-input>
                                        <div v-if="form.errors.has('middle_name')" class="el-form-item__error"
                                             v-text="form.errors.first('middle_name')"></div>

                                    </el-form-item>
                                </div>
                                <div class="form-group col-sm-6 col-md-6 col-lg-6">
                                    <el-form-item label="Gender" prop="gender" :rules="getFieldRule('Gender')">
                                        <el-radio v-model="beneficiaryForm.gender" label="Male">Male</el-radio>
                                        <el-radio v-model="beneficiaryForm.gender" label="Female">Female</el-radio>
                                    </el-form-item>
                                </div>
                                <div class="form-group col-sm-6 col-md-6 col-lg-6">
                                    <el-form-item label="Relationship" prop="relationship"
                                                  :rules="getFieldRule('relationship')">
                                        <el-input maxlength="20" @input="value => beneficiaryForm.relationship = value"
                                                  :value="beneficiaryForm.relationship | capitalize" type="text"
                                                  placeholder="Relationship"></el-input>
                                        <div maxlength="20" v-if="form.errors.has('relationship')"
                                             class="el-form-item__error"
                                             v-text="form.errors.first('relationship')"></div>
                                    </el-form-item>
                                </div>				
								<!-- <div class="col-sm-6 col-md-6 col-lg-6 type-tooltip">
									<div class="tooltips">
                                        <span class="fa fa-question fa-tooltip"></span>
                                        <div class="tooltip-box">
                                            The person named under <b>“Secondary”</b> will have
                                            the powers only if your selected primary person(s)
                                            predecease you.
                                        </div>
                                    </div>
                                    <el-form-item label="Type" prop="type" :rules="getFieldRule('Type')">
                                        <el-radio v-model="beneficiaryForm.type" label="Primary">Primary</el-radio>
                                        <el-radio v-model="beneficiaryForm.type" label="Secondary">Secondary</el-radio>
                                    </el-form-item>
                                </div>				 -->
							</div>
							<h2 class="will-create-subtitle">Address<el-button type="text" class="add-copy">(To copy your address please click <span @click="copybeneficiaryAddress()" class="click_here">here</span>)</el-button></h2>
							<div class="form-row">
                                <div class="form-group col-sm-6 col-md-6 col-lg-6">
                                    <el-form-item label="Address 1" prop="address_1" :rules="getFieldRule('Address 1')">
                                        <el-input maxlength="50" @input="value => beneficiaryForm.address_1 = value"
                                                  :value="beneficiaryForm.address_1 | capitalize"
                                                  placeholder="Address 1"></el-input>
                                        <div v-if="form.errors.has('address_1')" class="el-form-item__error"
                                             v-text="form.errors.first('address_1')"></div>
                                    </el-form-item>
                                </div>
                                <div class="form-group col-sm-6 col-md-6 col-lg-6">
                                    <el-form-item label="Address 2" prop="address_2">
                                        <el-input maxlength="50"@input="value => beneficiaryForm.address_2 = value"
                                                  :value="beneficiaryForm.address_2 | capitalize"
                                                  placeholder="Address 2"></el-input>
                                    </el-form-item>
                                </div>
                                <div class="form-group col-sm-3 col-md-3 col-lg-3">
                                    <el-form-item label="City" prop="city" :rules="getFieldRule('City')">
                                        <el-input maxlength="20" @input="value => beneficiaryForm.city = value"
                                                  :value="beneficiaryForm.city | capitalize"
                                                  placeholder="City"></el-input>
                                        <div v-if="form.errors.has('city')" class="el-form-item__error"
                                             v-text="form.errors.first('title')"></div>
                                    </el-form-item>
                                </div>
                                <div class="form-group col-sm-3 col-md-3 col-lg-3">
                                    <el-form-item label="State" prop="state" :rules="getFieldRule('State')">
                                        <el-input maxlength="20" @input="value => beneficiaryForm.state = value"
                                                  :value="beneficiaryForm.state | capitalize"
                                                  placeholder="State"></el-input>

                                        <div v-if="form.errors.has('state')" class="el-form-item__error"
                                             v-text="form.errors.first('title')"></div>
                                    </el-form-item>
                                </div>

                                <div class="form-group col-sm-3 col-md- col-lg-3">
                                    <el-form-item label="Pin Code" prop="pin_code">
                                        <el-input maxlength="10" v-model="beneficiaryForm.pin_code"
                                                  placeholder="Pin code"></el-input>
                                    </el-form-item>
                                </div>
                                <div class="form-group col-sm-3 col-md-3 col-lg-3">
                                    <el-form-item label="Country" prop="country" :rules="getFieldRule('Country')">
                                        <el-input maxlength="20" @input="value => beneficiaryForm.country = value"
                                                  :value="beneficiaryForm.country | capitalize"
                                                  placeholder="Country"></el-input>

                                        <div v-if="form.errors.has('country')" class="el-form-item__error"
                                             v-text="form.errors.first('country')"></div>
                                    </el-form-item>
                                </div>

                            </div>
							<div class="will-btn-group row p-1">
								<div class="col-12 col-sm-12 col-md-12 col-lg-12 p-0 text-right">
									<el-button title="Cancel" class="add-btn mt-4 mini-btn btn btn-sm"
											:loading="loading" :disabled="loading" @click="cancelAddBeneficiary()">Cancel
									</el-button>
									<el-button title="Add Details" class="add-btn mt-4 mini-btn btn btn-sm"
											:loading="loading" :disabled="loading" @click="addBenificiaryRecord('beneficary')">Add Beneficiary
									</el-button>
								</div>
							</div>												 
					 </el-form>
				</div>
				<div class="will-btn-group row" v-if="gift_info.add_gift == 'Yes' && !addbeneficiarybutton">
					<div class="col-3 col-sm-6 col-md-6 col-lg-6 text-left">
						<el-button
							v-if="recordIndex != null"
							title="Delete Details"
							class="delete-btn mini-btn btn btn-sm"
							@click="deleteItem(recordIndex)"
							>delete
						</el-button>
					</div>
					<div class="col-9 col-sm-6 col-md-6 col-lg-6 text-right">
						<el-button
							v-if="recordIndex != null"
							class="cancel-btn mini-btn btn mr15 btn-sm"
							@click="cancel()"
							>cancel
						</el-button>
						<el-button
							v-if="recordIndex == null"
							title="Add Details"
							class="add-btn mini-btn btn btn-sm"
							:loading="loading"
							:disabled="loading"
							@click="addRecord()"
							>add Gift
						</el-button>
						<el-button
							v-else
							title="Update Details"
							class="add-btn mini-btn btn btn-sm"
							:loading="loading"
							:disabled="loading"
							@click="addRecord()"
							>edit Gift
						</el-button>
					</div>
				</div>
				<div v-if="message != ''" class="personalinfo-tab tab-card">
					<h2 class="will-create-title error-message text-center">
						{{ message }}
					</h2>
				</div>
			</div>
			<div class="col-sm-12 col-md-12 col-lg-4 position-relative">
				<div class="will-form-image">
					<div class="will-toon">
						<img src="/themes/flatly/images/will6.svg" class="" />
					</div>
				</div>
				<div class="document-img">
					<img src="/themes/flatly/images/document.svg" />
				</div>
				<div
					class="details-add mov-immov-card"
					v-if="gift_info.records.length > 0"
				>
					<div class="row">
						<div class="col-md-6 col-lg-12 col-xl-12 col-12">
							<!--<h3 class="details-add-title">Movable</h3>-->
							<div
								class="tab-card estate-details-list details-list"
								v-for="(info, index) in gift_info.records"
							>
								<h2 class="asset-type">{{ info.type }}</h2>
								<span class="asset-location"
									>{{ info.description }} ,<small>{{
										info.location
									}}</small></span
								>
								<p
									class="beni-type"
									v-if="checkBeniciariesAssociated(info, 'Primary')"
								>
									Primary
								</p>
								<span
									class="asset-part"
									v-if="checkBeniciariesAssociated(info, 'Primary')"
								>
									<span
										v-if="
											info.primary_beneficiaries[item.id] !=
												undefined &&
												info.primary_beneficiaries[item.id] > 0
										"
										v-for="(item, index) in data.beneficiary_info
											.beneficiaries"
									>
										{{ item.first_name }} {{ item.last_name }}
										{{ info.primary_beneficiaries[item.id] }}%
									</span>
								</span>

								<hr
									v-if="checkBeniciariesAssociated(info, 'Secondary')"
								/>

								<p
									class="beni-type"
									v-if="checkBeniciariesAssociated(info, 'Secondary')"
								>
									Secondary
								</p>
								<span
									class="asset-part"
									v-if="checkBeniciariesAssociated(info, 'Secondary')"
								>
									<!--  <span v-if="info.beneficiaries[item.id]!=undefined && info.beneficiaries[item.id] > 0 && item.type == 'Secondary'"
                                          v-for="(item,index) in data.beneficiary_info.beneficiaries">
                                        {{item.first_name}} {{item.last_name}} {{ info.beneficiaries[item.id] }}%
                                    </span>-->
									<span
										v-if="
											info.secondary_beneficiaries[item.id] !=
												undefined &&
												info.secondary_beneficiaries[item.id] > 0
										"
										v-for="(item, index) in data.beneficiary_info
											.beneficiaries"
									>
										{{ item.first_name }} {{ item.last_name }}
										{{ info.secondary_beneficiaries[item.id] }}%
									</span>
								</span>
								<el-button
									type="text"
									title="Update Details"
									@click="editRecord(index)"
									><a class="fa fa-pencil"></a
								></el-button>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="row">
			<div class="col-sm-12 col-md-12 col-lg-12">
				<div class="will-rext-prev-btn">
					<el-button
						class="btn btn-prev active btn-nex-prev"
						@click="backStep('5')"
						><i class="fa fa-angle-left"></i
					></el-button>
					<el-button class="btn btn-next btn-nex-prev" @click="submit()"
						><i class="fa fa-angle-right"></i>
					</el-button>
				</div>
			</div>
		</div>
	</el-form>
</template>

<script>
	import Form from 'form-backend-validation';
	import { MessageBox } from 'element-ui';

	export default {
		name: 'step6',
		props: ['data', 'id'],
		data() {
			return {
				activeTab: 6,
				message: '',
				formName: 'giftForm',
				loading: false,
				recordIndex: null,
				form: new Form(),
				primaryBeneficiariesTotal: 0,
				secondryBeneficiariesTotal: 0,
				addbeneficiarybutton: false,
				gift_info: {
					add_gift: 'No',
					records: []
				},
				giftForm: {
					second_beneestatey: 'No',
					beneficiaries: {},
					primary_beneficiaries: {},
					secondary_beneficiaries: {},
					type: '',
					description: '',
					location: ''
				},
				beneficiaryForm: {
                    title: '',
                    type: '',
                    first_name: '',
                    other_title: '',
                    last_name: '',
                    middle_name: '',
                    relationship: '',
                    address_1: '',
                    address_2: '',
                    gender: 'Male',
                    city: '',
                    state: '',
                    pin_code: '',
                    country: '',

                },
				beneficiaryRecordIndex: null,
			};
		},
		filters: {
			capitalize: function(value) {
				if (!value) return '';
				// value = value.toString().toLowerCase()
				return value.charAt(0).toUpperCase() + value.slice(1);
			}
		},
		methods: {
			cancel() {
				this.giftForm.beneficiaries = {};
				this.giftForm.primary_beneficiaries = {};
				this.giftForm.secondary_beneficiaries = {};
				this.$refs.giftForm.resetFields();
				this.recordIndex = null;
			},
			activateAddBeneficiary(){
				this.addbeneficiarybutton = true;
			},
			cancelAddBeneficiary(){
				this.addbeneficiarybutton = false;
			},
			deleteItem(index) {
				MessageBox.confirm(
					`Do you really want to delete?`,
					'Confirmation',
					{
						confirmButtonText: 'OK',
						cancelButtonText: 'Cancel',
						type: 'warning'
					}
				)
					.then(() => {
						this.deleteRecord(index);
					})
					.catch(() => {});
			},
			editRecord(index) {
				this.recordIndex = index;
				this.gift_info.add_gift = 'Yes';

				var record = {};
				for (const cnfg in this.gift_info.records[index]) {
					record = _.merge(record, {
						[cnfg]: this.gift_info.records[index][cnfg]
					});
				}

				this.giftForm = record;
				this.giftForm.secondary_beneficiaries = {};
				this.allocatePercentageValue('Primary');
				this.allocatePercentageValue('Secondary');
			},
			deleteRecord(deleteIndex) {
				this.gift_info.records.splice(deleteIndex, 1);
				this.recordIndex = null;
				this.cancel();
				if (this.gift_info.records.length == 0)
					this.gift_info.add_gift = 'No';
				this.submit('Yes');
			},
			resetForm() {
				this.$refs[this.formName].resetFields();
			},
			addRecord() {
				if (this.loading == true) return false;
				this.loading = true;
				this.message = '';
				this.$refs[this.formName].validate((valid) => {
					if (
						valid &&
						this.primaryBeneficiariesTotal == 100 &&
						(this.giftForm.second_beneestatey == 'No' ||
							(this.giftForm.second_beneestatey == 'Yes' &&
								this.secondryBeneficiariesTotal == 100))
					) {
						this.form = new Form({
							activeTab: this.activeTab,
							gift_record: this.giftForm
						});
						if (this.id != 0) {
							this.form
								.post(route('api.will.wills.savedata', { id: this.id }))

								.then((response) => {
									this.loading = false;
									console.log('id', this.id);
									if (response.errors == false) {
										this.addInfo();
									} else {
										if (response.dashboardRoute != undefined) {
											window.location = response.dashboardRoute;
										}
									}
								})
								.catch((error) => {
									this.loading = false;
								});
						} else {
							this.form
								.post('/api/will/formData/add')
								.then((response) => {
									this.loading = false;
									if (response.errors == false) {
										this.addInfo();
									} else {
										if (response.dashboardRoute != undefined) {
											window.location = response.dashboardRoute;
										}
									}
								})
								.catch((error) => {
									this.loading = false;
								});
						}
					} else {
						this.loading = false;
					}
				});

				// this.loading = false;
			},
			addInfo() {
				var record = {};
				for (const cnfg in this.giftForm) {
					record = _.merge(record, { [cnfg]: this.giftForm[cnfg] });
				}
				if (this.recordIndex == null) {
					record.id = 'SP' + new Date().valueOf();
					this.gift_info.records.push(record);
				} else {
					this.gift_info.records[this.recordIndex] = record;
					this.recordIndex = null;
				}
				this.giftForm.beneficiaries = {};
				this.giftForm.secondary_beneficiaries = {};
				this.giftForm.primary_beneficiaries = {};
				this.$refs.giftForm.resetFields();
				this.secondryBeneficiariesTotal = 0;
				this.primaryBeneficiariesTotal = 0;
				this.submit('Yes');
			},
			addInfoBeneficiary() {
				var beneficiaryRecord = {};
				beneficiaryRecord.id = "PB" + new Date().valueOf();
				beneficiaryRecord.title = this.beneficiaryForm.title;
				beneficiaryRecord.type = this.beneficiaryForm.type;
				beneficiaryRecord.first_name = this.beneficiaryForm.first_name;
				beneficiaryRecord.other_title = this.beneficiaryForm.other_title;
				beneficiaryRecord.last_name = this.beneficiaryForm.last_name;
				beneficiaryRecord.middle_name = this.beneficiaryForm.middle_name;
				beneficiaryRecord.relationship = this.beneficiaryForm.relationship;
				beneficiaryRecord.address_1 = this.beneficiaryForm.address_1;
				beneficiaryRecord.address_2 = this.beneficiaryForm.address_2;
				beneficiaryRecord.gender = this.beneficiaryForm.gender;
				beneficiaryRecord.city = this.beneficiaryForm.city;
				beneficiaryRecord.state = this.beneficiaryForm.state;
				beneficiaryRecord.pin_code = this.beneficiaryForm.pin_code;
				beneficiaryRecord.country = this.beneficiaryForm.country;
				this.data.beneficiary_info.beneficiaries.push(beneficiaryRecord);
				this.form = new Form({activeTab: this.activeTab, beneficiary_info: this.data.beneficiary_info});
				this.form.post('/api/will/formData/add')
					.then((response) => {
						this.loading = false;
						if (response.errors == false) {
							this.message = '';
							beneficiaryRecord = {};
							this.$refs.beneficiaryForm.resetFields();
							this.addbeneficiarybutton = false;
							this.beneficiaryForm.middle_name = '';
							if (addInfo != 'Yes') {
								this.$emit('changeActiveTab', this.activeTab + 1);
								this.$emit('updateFormData', response.data);
							}
						} else {
							if (response.dashboardRoute != undefined) {
								window.location = (response.dashboardRoute);
							}
						}
					})
					.catch((error) => {
						console.log("submit 17");
						this.loading = false;
					});
					
			},
			checkBeniciariesAssociated(record, type) {
				/* for (var i in this.data.beneficiary_info.beneficiaries) {
                     for (var j in this.gift_info.records) {
                         if (this.data.beneficiary_info.beneficiaries[i].type == type && this.gift_info.records[j].beneficiaries[this.data.beneficiary_info.beneficiaries[i].id] != undefined && this.gift_info.records[j].beneficiaries[this.data.beneficiary_info.beneficiaries[i].id] > 0) {
                             return true;
                             break;
                         }
                     }
                 }*/
				if (type == 'Primary') {
					for (var i in this.data.beneficiary_info.beneficiaries) {
						if (
							record.primary_beneficiaries[
								this.data.beneficiary_info.beneficiaries[i].id
							] != undefined &&
							record.primary_beneficiaries[
								this.data.beneficiary_info.beneficiaries[i].id
							] > 0
						) {
							return true;
							break;
						}
					}
				} else {
					for (var i in this.data.beneficiary_info.beneficiaries) {
						if (
							record.secondary_beneficiaries[
								this.data.beneficiary_info.beneficiaries[i].id
							] != undefined &&
							record.secondary_beneficiaries[
								this.data.beneficiary_info.beneficiaries[i].id
							] > 0
						) {
							return true;
							break;
						}
					}
				}

				return false;
			},
			submit(addInfo = null) {
				window.scrollTo({ top: 0, behavior: 'smooth' });
				this.message = '';
				if (
					this.gift_info.add_gift == 'Yes' &&
					this.gift_info.records.length == 0
				) {
					console.log('error');
					this.$refs[this.formName].validate((valid) => {
						if (valid) {
							this.gift_info.add_gift = 'Yes';
							this.message =
								"Please click on 'Add Gift' button to save the details";
						} else {
							this.gift_info.add_gift = 'Yes';
						}
					});
				} else {
					this.form = new Form({
						activeTab: this.activeTab,
						gift_info: this.gift_info
					});
					if (this.id != 0) {
						console.log('id', this.id);
						this.form
							.post(route('api.will.wills.savedata', { id: this.id }))

							.then((response) => {
								console.log('id', this.id);
								this.loading = false;
								if (response.errors == false) {
									if (addInfo != 'Yes') {
										this.$emit('changeActiveTab', this.activeTab + 1);
										this.$emit('updateFormData', response.data);
									}
								} else {
									if (response.dashboardRoute != undefined) {
										window.location = response.dashboardRoute;
									}
								}
							})
							.catch((error) => {
								this.loading = false;
							});
					} else {
						this.form
							.post('/api/will/formData/add')
							.then((response) => {
								this.loading = false;
								if (response.errors == false) {
									if (addInfo != 'Yes') {
										this.$emit('changeActiveTab', this.activeTab + 1);
										this.$emit('updateFormData', response.data);
									}
								}
							})
							.catch((error) => {
								this.loading = false;
							});
					}
				}
			},
			generateBeneficiaryTotalVal(type, beneficaryId = 0) {
				var totalCount = 0;

				if (type == 'Primary') {
					for (var i in this.data.beneficiary_info.beneficiaries) {
						var id = this.data.beneficiary_info.beneficiaries[i].id;
						if (this.giftForm.primary_beneficiaries[id] != undefined) {
							totalCount += parseInt(
								this.giftForm.primary_beneficiaries[id]
							);
						}
					}
					if (totalCount > 0) this.primaryBeneficiariesTotal = totalCount;
					else this.primaryBeneficiariesTotal = 0;
					if (beneficaryId != 0) {
						this.giftForm.primary_beneficiaries.splice(beneficaryId);
						console.log(this.giftForm.primary_beneficiaries);
					}
				} else {
					for (var i in this.data.beneficiary_info.beneficiaries) {
						var id = this.data.beneficiary_info.beneficiaries[i].id;
						if (this.giftForm.secondary_beneficiaries[id] != undefined) {
							totalCount += parseInt(
								this.giftForm.secondary_beneficiaries[id]
							);
						}
					}
					if (totalCount > 0) this.secondryBeneficiariesTotal = totalCount;
					else this.secondryBeneficiariesTotal = 0;
					if (beneficaryId != 0) {
						this.giftForm.secondary_beneficiaries.splice(beneficaryId, 1);
					}
				}
			},
			changeBeneficiary(event, item, type) {
				if (type == 'primary') {
					if (event.target.checked == true) {
						console.log(event);
						if (
							this.giftForm.primary_beneficiaries[item.id] == undefined
						) {
							// this.giftForm.primary_beneficiaries.push({[item.id] : 0})
							this.giftForm.primary_beneficiaries[item.id] = 0;
						}
					} else {
						this.giftForm.primary_beneficiaries[item.id] = 0;
						this.generateBeneficiaryTotalVal('Primary', item.id);
					}
				} else {
					if (event.target.checked == true) {
						console.log(event);
						if (
							this.giftForm.secondary_beneficiaries[item.id] == undefined
						) {
							// this.giftForm.secondary_beneficiaries.push({[item.id] : 0})
							this.giftForm.secondary_beneficiaries[item.id] = 0;
						}
					} else {
						this.giftForm.secondary_beneficiaries[item.id] = 0;
						this.generateBeneficiaryTotalVal('Secondary', item.id);
					}
				}
			},
			allocatePercentageValue(type) {
				this.generateBeneficiaryTotalVal(type);
			},
			backStep(step) {
				this.$emit('changeActiveTab', step);
			},
			getFieldRule(Title, FieldName = null, FieldType = null) {
				if (FieldType == 'number') {
					return [
						{
							required: true,
							message: `${Title} field is required`
						},
						{
							max: 100,
							message: 'Length should be 3 to 5'
						}
					];
				} else {
					return {
						required: true,
						message: `${Title} field is required`
					};
				}
			},
            getTitleRule(Title, FieldName = null, FieldType = null) {
                return {
                    required: true,
                    message: `field required`,
                };
            },
			copybeneficiaryAddress(){
                this.beneficiaryForm.address_1 = this.data.personal_info.personal_info.address_1;
                this.beneficiaryForm.address_2 = this.data.personal_info.personal_info.address_2;
                this.beneficiaryForm.city = this.data.personal_info.personal_info.city;
                this.beneficiaryForm.state = this.data.personal_info.personal_info.state;
                this.beneficiaryForm.country = this.data.personal_info.personal_info.country;
                this.beneficiaryForm.pin_code = this.data.personal_info.personal_info.pin_code;
            },
			addBenificiaryRecord(type) {
                if(this.loading == true)
                    return false;
                this.loading = true;
                this.message = '';
                this.specialmessage = '';
                if (type == 'beneficary') {
					console.log("addBenificiaryRecord beneficary");
					
                    this.form = new Form({activeTab: this.activeTab, beneficiary_record: this.beneficiaryForm});
                    this.$refs.beneficiaryForm.validate((valid) => {
						console.log("form validation", valid);
                        if (valid) {
							console.log("addBenificiaryRecord valid");
							this.form.post('/api/will/formData/add')
								.then((response) => {
									if (response.errors == false) {
										// window.location.reload();
										// this.addInfo(type);
										this.addInfoBeneficiary();
									} else {
										this.loading = false;
										if (response.dashboardRoute != undefined) {
											window.location = (response.dashboardRoute);
										}
									}
								})
								.catch((error) => {
									console.log("addBenificiaryRecord invalid");
									this.loading = false;
								});

                        }
                        else{
                            this.loading = false;
                        }
                    });

                }
                // this.loading = false;
            }
		},
		mounted() {
			this.loading = true;
			if (
				this.data != undefined &&
				this.data.gift_info != undefined &&
				this.data.gift_info.records != undefined
			) {
				this.gift_info = this.data.gift_info;
			}
			this.loading = false;
		},
		watch: {
			'gift_info.add_gift': function() {
				if (this.gift_info.add_gift == 'No') {
					this.gift_info.records = [];
				}
			},
			'giftForm.secondary_beneficiary': function() {
				if (this.giftForm.secondary_beneficiary == 'No') {
					this.giftForm.secondary_beneficiaries = {};
				}
			}
		}
	};
</script>

<style scoped></style>