window._ = require('lodash');

/**
 * We'll load jQuery and the Bootstrap jQuery plugin which provides support
 * for JavaScript based Bootstrap features such as modals and tabs. This
 * code may be modified to fit the specific needs of your application.
 */

// try {
//     window.$ = window.jQuery = require('jquery');
//
//     require('bootstrap-sass');
// } catch (e) {}

/**
 * We'll load the axios HTTP library which allows us to easily issue requests
 * to our Laravel back-end. This library automatically handles sending the
 * CSRF token as a header based on the value of the "XSRF" token cookie.
 */

window.axios = require('axios');

window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

/**
 * Next we will register the CSRF Token as a common header with Axios so that
 * all outgoing HTTP requests automatically have it attached. This is just
 * a simple convenience so we don't have to attach every token manually.
 */

const token = document.head.querySelector('meta[name="csrf-token"]');

if (token) {
    window.axios.defaults.headers.common['X-CSRF-TOKEN'] = token.content;
} else {
    console.error('CSRF token not found: https://laravel.com/docs/csrf#csrf-x-csrf-token');
}

const userApiToken = document.head.querySelector('meta[name="user-api-token"]');

if (userApiToken) {
    window.axios.defaults.headers.common.Authorization = `Bearer ${userApiToken.content}`;
} else {
    console.error('User API token not found in a meta tag.');
}

import '@babel/polyfill';
import Vue from 'vue';
import VueEvents from 'vue-events';
// import VueRouter from 'vue-router';
// import VueSimplemde from 'vue-simplemde';
import ElLoading from "element-ui/lib/loading";
// import Dropdown from "element-ui/lib/dropdown";
import Option from "element-ui/lib/option";
// import CheckboxGroup from "element-ui/lib/checkbox-group";
// import Checkbox from "element-ui/lib/checkbox";
import DatePicker from "element-ui/lib/date-picker";
import Input from "element-ui/lib/input";
import Radio from "element-ui/lib/radio";
import Form from "element-ui/lib/form";
import Select from "element-ui/lib/select";
import FormItem from "element-ui/lib/form-item";
import Button from "element-ui/lib/button";
import RadioButton from "element-ui/lib/radio-button";
import Checkbox from "element-ui/lib/checkbox";
import CheckboxButton from "element-ui/lib/checkbox-button";
import CheckboxGroup from "element-ui/lib/checkbox-group";
import InputNumber from "element-ui/lib/input-number";
import Tooltip from "element-ui/lib/tooltip";
import 'element-theme-chalk';
import lang from 'element-ui/lib/locale/lang/en';
import locale from 'element-ui/lib/locale';
// import route from 'vue-router/src/util/route.js';
// import route from '../../vendor/tightenco/ziggy/src/js/route';

// import locale from "element-ui/lib/locale/lang/en";

locale.use(lang);
// Vue.use(VueRouter);
// Vue.use(route);
Vue.use(VueEvents);
Vue.use(ElLoading);
Vue.use(DatePicker);
Vue.use(Input);
Vue.use(Radio);
Vue.use(Select);
Vue.use(Option);
Vue.use(Form);
Vue.use(FormItem);
Vue.use(Checkbox);
Vue.use(CheckboxButton);
Vue.use(CheckboxGroup);
Vue.use(InputNumber);
Vue.use(Button);
Vue.use(RadioButton);
Vue.use(Tooltip);
require('./mixins');

Vue.component('willcreate', require('../../Modules/Will/Assets/js/components/willSubmissionComponent.vue').default);
Vue.component('willsummary', require('../../Modules/Will/Assets/js/components/WillSummary.vue').default);
Vue.component('paymentreport', require('../../Modules/Will/Assets/js/components/PaymentReport.vue').default);
Vue.component('clientcommunication', require('../../Modules/Will/Assets/js/components/ClientCommunication.vue').default);

// Vue.component('willform', require('../../Modules/Will/Assets/js/components/WillFormSubmission.vue').default);

const app = new Vue({
    el: '#app',
});
