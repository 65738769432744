<template>
<!-- <h1>Step8</h1> -->
    <el-form v-loading="loading" ref="recordForm" :model="recordForm" label-width="120px" label-position="top">

        <div class="row">
            <div class="col-sm-12 col-md-12 col-lg-8">
                <div class="wizard-des">
                    <p>Most people make a Will so that when they die, their estate is divided among family and friends
                        in the way that they (the testator) wish. But what happens if the estate is left to, say, a
                        spouse and children
                        and they all perish in the same accident as the benefactor? For obvious reasons, you should not
                        disclose the beneficiary details to anyone.</p>
                </div>
                <div class="radio-details-tab  tab-card">
                    <span class="will-radio-title pr-3">Do you want to add tragedy clause?</span>
                    <div class="yn-radio">
                        <div class="yn-radio">

                            <el-radio v-model="tradegy_info.add_tradegy" label="Yes">Yes</el-radio>
                            <el-radio v-model="tradegy_info.add_tradegy" label="No">No</el-radio>
                        </div>
                    </div>
                </div>
                <div class=" tab-card" v-if="tradegy_info.add_tradegy == 'Yes'">
                    <h2 class="will-create-title">Tragedy Clause</h2>
                    <div class="form-row">
                        <div class="form-group col-3 col-sm-2 col-md-2 col-lg-2">
                            <el-form-item label="Title" prop="title" :rules="getTitleRule('Title')">
                                <el-select size="small" v-model="recordForm.title">
                                    <el-option label="Mr" value="Mr"></el-option>
                                    <el-option label="Mrs" value="Mrs"></el-option>
                                    <el-option label="Ms" value="Ms"></el-option>
                                    <el-option label="Miss" value="Miss"></el-option>
                                    <el-option label="Master" value="Master"></el-option>
                                    <!--<el-option label="Dr" value="Dr"></el-option>-->
                                    <!--<el-option label="Other" value="Other"></el-option>-->
                                </el-select>
                            </el-form-item>
                            <div v-if="form.errors.has('title')" class="el-form-item__error"
                                 v-text="form.errors.first('title')"></div>
                        </div>
                        <div class="form-group col-9 col-sm-4 col-md-4 col-lg-4">
                            <el-form-item label="First name" prop="first_name" :rules="getFieldRule('First name')">
                                <el-input maxlength="20" @input="value => recordForm.first_name = value"
                                          :value="recordForm.first_name | capitalize" type="text"
                                          placeholder="First Name"></el-input>
                                <div v-if="form.errors.has('first_name')" class="el-form-item__error"
                                     v-text="form.errors.first('first_name')"></div>
                            </el-form-item>
                        </div>
                        <div class="form-group col-sm-6 col-md-6 col-lg-6">
                            <el-form-item label="Last name" prop="last_name" :rules="getFieldRule('Last name')">
                                <el-input maxlength="20" @input="value => recordForm.last_name = value"
                                          :value="recordForm.last_name | capitalize" type="text"
                                          placeholder="Last name"></el-input>
                                <div v-if="form.errors.has('last_name')" class="el-form-item__error"
                                     v-text="form.errors.first('last_name')"></div>
                            </el-form-item>
                        </div>
                        <!--<div class="form-group col-3 col-sm-2 col-md-2 col-lg-2"  v-if="recordForm.title == 'Other'">
                            <el-form-item label="Other title" prop="other_title" :rules="getFieldRule('Title')">
                                <el-input v-model="recordForm.other_title" type="text"
                                          placeholder="other title"></el-input>
                            </el-form-item>
                        </div>
                        <div class="form-group col-sm-6 col-md-6 col-lg-6"  v-if="recordForm.title == 'Other'">
                        </div>
                        <div class="form-group col-9 col-sm-4 col-md-4 col-lg-4"  v-if="recordForm.title == 'Other'">
                        </div>-->
                        <div class="form-group col-sm-6 col-md-6 col-lg-6">
                            <el-form-item label="Middle name">
                                <el-input maxlength="20" @input="value => recordForm.middle_name = value"
                                          :value="recordForm.middle_name | capitalize"
                                          placeholder="Middle name"></el-input>
                                <div v-if="form.errors.has('middle_name')" class="el-form-item__error"
                                     v-text="form.errors.first('middle_name')"></div>
                            </el-form-item>
                        </div>
                        <div class="form-group col-sm-6 col-md-6 col-lg-6">
                            <el-form-item label="Relationship" prop="relationship"
                                          :rules="getFieldRule('relationship')">
                                <el-input maxlength="20" @input="value => recordForm.relationship = value"
                                          :value="recordForm.relationship | capitalize"
                                          placeholder="Relationship"></el-input>
                                <div v-if="form.errors.has('relationship')" class="el-form-item__error"
                                     v-text="form.errors.first('relationship')"></div>
                            </el-form-item>
                        </div>
                    </div>
                    <h2 class="will-create-subtitle">Address
                        <el-button type="text" class="add-copy">(To copy your address please click <span @click="copy()" class="click_here">here</span>)</el-button>
                    </h2>
                    <div class="form-row">
                        <div class="form-group col-sm-6 col-md-6 col-lg-6">
                            <el-form-item label="Address 1" prop="address_1" :rules="getFieldRule('Address 1')">
                                <el-input maxlength="50" @input="value => recordForm.address_1 = value"
                                          :value="recordForm.address_1 | capitalize" placeholder="Address 1"></el-input>
                                <div v-if="form.errors.has('address_1')" class="el-form-item__error"
                                     v-text="form.errors.first('address_1')"></div>
                            </el-form-item>
                        </div>
                        <div class="form-group col-sm-6 col-md-6 col-lg-6">
                            <el-form-item maxlength="50" label="Address 2" prop="address_2">
                                <el-input @input="value => recordForm.address_2 = value"
                                          :value="recordForm.address_2 | capitalize" placeholder="Address 2"></el-input>
                            </el-form-item>
                        </div>
                        <div class="form-group col-sm-3 col-md-3 col-lg-3">
                            <el-form-item label="City" prop="city" :rules="getFieldRule('City')">
                                <el-input maxlength="20" @input="value => recordForm.city = value"
                                          :value="recordForm.city | capitalize" placeholder="City"></el-input>
                                <div v-if="form.errors.has('city')" class="el-form-item__error"
                                     v-text="form.errors.first('city')"></div>
                            </el-form-item>
                        </div>
                        <div class="form-group col-sm-3 col-md-3 col-lg-3">
                            <el-form-item label="State" prop="state" :rules="getFieldRule('State')">
                                <!--<el-select filterable  allow-create v-model="recordForm.state" placeholder="State">
                                    <el-option
                                            v-for="(record,index) in states"
                                            v-bind:key="index"
                                            :label="record.state"
                                            v-bind:value="record.state">
                                    </el-option>
                                </el-select>-->
                                <el-input maxlength="20" @input="value => recordForm.state = value"
                                          :value="recordForm.state | capitalize" placeholder="State"></el-input>

                                <div v-if="form.errors.has('state')" class="el-form-item__error"
                                     v-text="form.errors.first('state')"></div>
                            </el-form-item>
                        </div>

                        <div class="form-group col-sm-3 col-md- col-lg-3">
                            <el-form-item label="Pin Code" prop="pin_code">
                                <el-input maxlength="10" v-model="recordForm.pin_code"
                                          placeholder="Pin code"></el-input>
                                                           </el-form-item>
                        </div>
                        <div class="form-group col-sm-3 col-md-3 col-lg-3">
                            <el-form-item label="Country" prop="country" :rules="getFieldRule('Country')">
                                <!--<el-select v-model="recordForm.country">
                                    <el-option label="India" value="India"></el-option>
                                </el-select>-->
                                <el-input maxlength="20" @input="value => recordForm.country = value"
                                          :value="recordForm.country | capitalize" placeholder="Country"></el-input>

                                <div v-if="form.errors.has('country')" class="el-form-item__error"
                                     v-text="form.errors.first('country')"></div>
                            </el-form-item>
                        </div>

                    </div>

                    <div class="form-row">

                        <div class="col-12">

                            <h2 class="tragegy-asset-title" v-if="totalRemaining > 0 || editAssets != false">Assets <a>(Movable)</a>
                            </h2>

                            <div class="row tragegy-assets" v-if="totalRemaining > 0 || editAssets != false">
                                <div class="col-sm-6 col-md-6 col-lg-6 tragegy-assets-list"
                                     v-if="(remainingAssets[info.id]!=undefined && remainingAssets[info.id] > 0 || remainingAssets[info.id]==undefined || editAssets != false) && (info.asset_type != 'Land' && info.asset_type != 'Commercial Property' && info.asset_type != 'Other Immovable' && info.asset_type != 'House')" v-for="(info,index) in data.property_info.records">
                                    <h3>{{asset_types[info.asset_type]}}</h3><!--{{ info.asset_type }} -->

                                    <p v-if='info.asset_name_label!=null'>{{ info.asset_name_label }}</p>
                                    <p v-else>{{ info.asset_detail_label }}</p>
                                    <div class="form-row m-0">
                                        <div class="form-group col-3 pl-0">
                                            <label class="wg form-check-label mt-2">All <input
                                                    type="checkbox"
                                                    :checked="recordForm.properties[info.id]!=undefined  ? true:false"
                                                    @change="changeBeneficiary($event,info)"> <span
                                                    class="checkmark"></span>
                                            </label>
                                        </div>
                                        <div class="form-group col-3">
                                            <input type="text" v-model="recordForm.properties[info.id]"
                                                   v-on:keyup="allocatePercentageValue(info)"
                                                   class="form-control form-control-sm"
                                                   :placeholder="remainingAssets[info.id]!=undefined ? remainingAssets[info.id]+'%':'100%'">
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <h2 class="tragegy-asset-title" v-if="totalRemaining > 0 || editAssets != false">Assets <a>(Immovable)</a>
                            </h2>

                            <div class="row tragegy-assets" v-if="totalRemaining > 0 || editAssets != false">
                                <div class="col-sm-6 col-md-6 col-lg-6 tragegy-assets-list"
                                     v-if="(remainingAssets[info.id]!=undefined && remainingAssets[info.id] > 0 || remainingAssets[info.id]==undefined || editAssets != false) && (info.asset_type == 'Land' || info.asset_type == 'House' || info.asset_type == 'Commercial Property' || info.asset_type == 'Other Immovable' )"
                                     v-for="(info,index) in data.property_info.records">
                                    <h3>{{asset_types[info.asset_type]}}</h3><!--{{ info.asset_type }} -->

                                    <p v-if='info.asset_name_label!=null'>{{ info.asset_name_label }}</p>
                                    <p v-else>{{ info.asset_detail_label }}</p>

                                    <div class="form-row m-0">
                                        <div class="form-group col-3 pl-0">
                                            <label class="wg form-check-label mt-2">All <input
                                                    type="checkbox"
                                                    :checked="recordForm.properties[info.id]!=undefined  ? true:false"
                                                    @change="changeBeneficiary($event,info)"> <span
                                                    class="checkmark"></span>
                                            </label>
                                        </div>
                                        <div class="form-group col-3">
                                            <input type="text" v-model="recordForm.properties[info.id]"
                                                   v-on:keyup="allocatePercentageValue(info)"
                                                   class="form-control form-control-sm"
                                                   :placeholder="remainingAssets[info.id]!=undefined ? remainingAssets[info.id]+'%':'100%'">
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>

                </div>
                <div class="will-btn-group row" v-if="tradegy_info.add_tradegy == 'Yes'">
                    <div class="col-3 col-sm-6 col-md-6 col-lg-6 text-left">
                        <el-button class="delete-btn mini-btn btn btn-sm" v-if="recordIndex != null"
                                   @click="deleteItem(recordIndex)">delete
                        </el-button>
                    </div>
                    <div class="col-9 col-sm-6 col-md-6 col-lg-6 text-right">
                        <el-button v-if="recordIndex != null" class="cancel-btn  mini-btn btn mr15 btn-sm"
                                   @click="cancel()">cancel
                        </el-button>
                        <el-button v-if="recordIndex == null" title="Add Details" class="add-btn  mini-btn btn btn-sm"
                                   :loading="loading"
                                   :disabled="usedTotalMessage !== '' || loading == true ? true : false"
                                   @click="addRecord()">Add Tragedy
                        </el-button>
                        <el-button v-else title="Update Details" class="add-btn  mini-btn btn btn-sm" :loading="loading"
                                   :disabled="usedTotalMessage !== '' || loading == true ? true : false"
                                   @click="addRecord()">Update Tragedy
                        </el-button>
                    </div>

                </div>
                <div v-if="message != ''" class="personalinfo-tab tab-card">
                    <h2 class="will-create-title error-message text-center">{{ message }}</h2>
                </div>
                <div v-if="propert_message != ''" class="personalinfo-tab tab-card">
                    <h2 class="will-create-title error-message text-center">{{ propert_message }}</h2>
                </div>
                <div v-if="usedTotalMessage != ''" class="personalinfo-tab tab-card">
                    <h2 class="will-create-title error-message text-center">{{ usedTotalMessage }}</h2>
                </div>
            </div>
            <div class="col-sm-12 col-md-12 col-lg-4 position-relative">
                <div class="will-form-image">
                    <div class="will-toon">
                        <img src="/themes/flatly/images/will8.svg" class="">
                    </div>

                </div>
                <div class="document-img">
                    <img src="/themes/flatly/images/document.svg">
                </div>
                <div class="details-add" v-if="tradegy_info.records.length > 0">
                    <h3 class="details-add-title">Common Tragedy Beneficiaries</h3>
                    <div class="tab-card tradegy-clause-details details-list"
                         v-for="(item,index) in tradegy_info.records" v-if="tradegy_info.records.length > 0">
                        <h2 class="tradegy-name">{{ makeName(item.title,item.first_name,item.middle_name,item.last_name)
                            }} </h2>
                        <span class="tradegy-relation">{{item.relationship}}</span>
                        <p class="tradegy-address">{{
                            makeAddress(item.address_1,item.address_2,item.state,item.city,item.pin_code) }}</p>

                        <div class="row">
                            <div class="col-sm-12"><span class="tradegy-type">Movable</span></div>
                            <div class="col-sm-12"
                                 v-if="info.asset_type != 'Land' && info.asset_type != 'House' && info.asset_type !='Other Immovable' &&  info.asset_type !='Commercial Property' && item.properties[info.id]!=undefined && item.properties[info.id] > 0"
                                 v-for="(info,index) in data.property_info.records">
                                <h3 class="tradegy-type-name">{{asset_types[info.asset_type]}}<span>({{item.properties[info.id]}}%)</span></h3>
                                <p class="tradegy-place" v-if='info.asset_name_label!=null'>{{ info.asset_name_label
                                    }}</p>
                                <p class="tradegy-place" v-else>{{ info.asset_detail_label }}</p>
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-sm-12"><span class="tradegy-type">Immovable</span></div>
                                <div class="col-sm-12"
                                 v-if="(info.asset_type == 'Land' || info.asset_type == 'House' || info.asset_type =='Other Immovable' || info.asset_type =='Commercial Property') && item.properties[info.id]!=undefined && item.properties[info.id] > 0"
                                 v-for="(info,index) in data.property_info.records">

                                <h3 class="tradegy-type-name">{{asset_types[info.asset_type]}} <span>({{item.properties[info.id]}}%)</span></h3>

                                <p class="tradegy-place" v-if='info.asset_name_label!=null'>{{ info.asset_name_label
                                    }}</p>
                                <p class="tradegy-place" v-else>{{ info.asset_detail_label }}</p>
                            </div>
                        </div>

                        <el-button type="text" title="Update Details" @click="editRecord(index)"><a
                                class="fa fa-pencil"></a></el-button>
                    </div>
                    <h3 class="details-add-title" v-if="totalRemaining > 0">Remaining Assets</h3>
                    <div class="tab-card tradegy-clause-details details-list" v-if="totalRemaining > 0">
                        <div class="row">
                            <div class="col-sm-12"><span class="tradegy-type">Movable</span></div>
                            <div class="col-sm-12"
                                 v-if="info.asset_type != 'Land' && info.asset_type != 'House' && info.asset_type !='Other Immovable' &&  info.asset_type !='Commercial Property' && remainingAssets[info.id]!=undefined && remainingAssets[info.id] > 0"
                                 v-for="(info,index) in data.property_info.records">
                                <h3 class="tradegy-type-name">{{ info.asset_type }}
                                    <span v-if="remainingAssets[info.id]!=undefined && remainingAssets[info.id] > 0">({{ remainingAssets[info.id] }}%)</span>
                                    <span v-else-if="remainingAssets[info.id]==undefined">(100%)</span>
                                </h3>
                                <p class="tradegy-place" v-if='info.asset_name_label!=null'>{{ info.asset_name_label
                                    }}</p>
                                <p class="tradegy-place" v-else>{{ info.asset_detail_label }}</p>
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-sm-12"><span class="tradegy-type">Immovable</span></div>
                            <div class="col-sm-12"
                                 v-if="(info.asset_type == 'Land' || info.asset_type == 'House' || info.asset_type =='Other Immovable' ||  info.asset_type =='Commercial Property') &&remainingAssets[info.id]!=undefined && remainingAssets[info.id] > 0"
                                 v-for="(info,index) in data.property_info.records">
                                <h3 class="tradegy-type-name">{{ info.asset_type }}
                                    <span v-if="remainingAssets[info.id]!=undefined && remainingAssets[info.id] > 0">({{ remainingAssets[info.id] }}%)</span>
                                    <span v-else-if="remainingAssets[info.id]==undefined">(100%)</span>
                                </h3>
                                <p class="tradegy-place" v-if='info.asset_name_label!=null'>{{ info.asset_name_label
                                    }}</p>
                                <p class="tradegy-place" v-else>{{ info.asset_detail_label }}</p>
                            </div>
                        </div>
                    </div>
                    <div class="tab-card tradegy-clause-details details-list allocationerror"
                         v-if="assetsTotalAreUsed == false">
                        <h6>Allocate All Remaining Assets 100%</h6>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-sm-12 col-md-12 col-lg-12">
                <div class="will-rext-prev-btn">
                    <el-button class="btn btn-prev active btn-nex-prev" @click="backStep()"><i
                            class="fa fa-angle-left"></i></el-button>
                    <el-button class="btn btn-next btn-nex-prev" @click="submit('recordForm')"><i
                            class="fa fa-angle-right"></i></el-button>
                </div>
            </div>
        </div>
    </el-form>
</template>

<script>
    import Form from 'form-backend-validation';
    import Common from '../../mixins/commonMethod';
    import {MessageBox} from "element-ui";

    export default {
        name: "step9",
        props: ['data', 'states', 'asset_types', 'id'],
        mixins: [Common],
        data() {
            return {
                activeTab: 9,
                total: 0,
                message: '',
                propert_message: '',
                usedTotalMessage: '',
                loading: false,
                editAssets: false,
                form: new Form(),
                recordIndex: null,
                tradegy_info: {
                    add_tradegy: 'No',
                    records: []
                },
                formName: 'recordForm',
                recordForm: {
                    id: '',
                    title: '',
                    other_title: '',
                    first_name: '',
                    last_name: '',
                    middle_name: '',
                    relationship: '',
                    address_1: '',
                    address_2: '',
                    city: '',
                    state: '',
                    pin_code: '',
                    country: '',
                    properties: {}
                },
                assetsTotalAreUsed: false,
                usedTotalAssets: {},
                remainingAssets: {},
                editableProperty: {},
                totalRemaining: 100,
                isExit: 0,
            }
        },
        filters: {
            capitalize: function (value) {
                if (!value) return ''
                // value = value.toString().toLowerCase()
                return value.charAt(0).toUpperCase() + value.slice(1)
            }
        },
        methods: {
            copy() {
                this.recordForm.address_1 = this.data.personal_info.personal_info.address_1;
                this.recordForm.address_2 = this.data.personal_info.personal_info.address_2;
                this.recordForm.city = this.data.personal_info.personal_info.city;
                this.recordForm.state = this.data.personal_info.personal_info.state;
                this.recordForm.country = this.data.personal_info.personal_info.country;
                this.recordForm.pin_code = this.data.personal_info.personal_info.pin_code;
            },
            deleteItem(index) {
                MessageBox.confirm(`Do you really want to delete?`,
                    "Confirmation",
                    {
                        confirmButtonText: "OK",
                        cancelButtonText: "Cancel",
                        type: "warning"
                    }).then(() => {
                    this.deleteRecord(index);
                }).catch(() => {
                });
            },
            isRecordAvailableInMovable() {
                // var isExit = 0;
                // for(var col in this.tradegy_info.records){
                //     for(var index in this.data.property_info.records){
                //         var id = this.data.property_info.records[index].id;
                //         if(this.data.property_info.records[index].asset_type != 'Land' && this.data.property_info.records[index].asset_type != 'House' && this.data.property_info.records[index].asset_type !='Other Immovable' && this.tradegy_info.records[col].properties[id] != undefined && this.tradegy_info.records[col].properties[id] > 0){
                //             console.log(this.tradegy_info.records[col].properties[id]);
                //             this.isExit = this.isExit + 1;
                //         }
                //     }
                // }
                // if(this.isExit > 1){
                //     return true;
                // }else{
                //     return false;
                // }
            },
            isRecordAvailableInImMovable() {
                for (var col in this.tradegy_info.records) {
                    for (var index in this.data.property_info.records) {
                        var id = this.data.property_info.records[index].id;
                        if ((this.data.property_info.records.asset_type == 'Land' || this.data.property_info.records.asset_type == 'House' || this.data.property_info.records.asset_type == 'Other Immovable') && this.tradegy_info.records[col].properties[id] != undefined && this.tradegy_info.records[col].properties[id] > 0) {
                            return true;
                            break;
                        } else {
                            return false;
                        }
                    }
                }
            },
            isRecordAvailableInMovableRemaining() {
                for (var col in this.tradegy_info.records) {
                    for (var index in this.data.property_info.records) {
                        var id = this.data.property_info.records[index].id;
                        if (this.data.property_info.records[index].asset_type != 'Land' && this.data.property_info.records[index].asset_type != 'House' && this.data.property_info.records[index].asset_type != 'Other Immovable' && this.remainingAssets[id] != undefined && this.remainingAssets[id] > 0) {
                            return true;
                            break;
                        } else {
                            return false;
                        }

                    }
                }
            },
            isRecordAvailableInImMovableRemaining() {
                for (var col in this.tradegy_info.records) {
                    for (var index in this.data.property_info.records) {
                        var id = this.data.property_info.records[index].id;
                        if ((this.data.property_info.records.asset_type == 'Land' || this.data.property_info.records.asset_type == 'House' || this.data.property_info.records.asset_type == 'Other Immovable') && this.remainingAssets[id] != undefined && this.remainingAssets[id] > 0) {
                            return true;
                            break;
                        } else {
                            return false;
                        }

                    }
                }
            },
            generateRemainingAssets(check = null) {
                this.assetsTotalAreUsed = false;
                this.usedTotalAssets = {};
                this.remainingAssets = {};
                this.usedTotalMessage = '';
                this.total = 0;
                var usedAssets = [];
                for (var i in this.tradegy_info.records) {
                    for (var j in this.tradegy_info.records[i].properties) {
                        if (this.usedTotalAssets[j] != undefined) {
                            this.usedTotalAssets[j] += parseInt(this.tradegy_info.records[i].properties[j]);
                        } else {
                            this.usedTotalAssets[j] = parseInt(this.tradegy_info.records[i].properties[j]);
                        }
                        if (check == 'Yes') {
                            if (this.recordIndex === null) {
                                this.total = this.usedTotalAssets[j];
                            } else if (this.editableProperty[j] != undefined) {

                                this.total = this.usedTotalAssets[j] - this.editableProperty[j];
                            } else {
                                console.log('property', j);
                                console.log('editableProperty', this.editableProperty[j]);
                                console.log('usedtotal', this.usedTotalAssets[j]);
                                this.total = this.usedTotalAssets[j];
                            }

                            console.log('before', this.total);
                            this.total += parseInt(this.recordForm.properties[j]);
                            console.log('after', this.total);

                            if (this.total > 100) {
                                this.usedTotalMessage = "One of Your Property allocation's calculation is more than 100%, You cannot allocate more than 100%.";
                            }
                        }
                    }
                }

                for (var k in this.data.property_info.records) {
                    if (this.usedTotalAssets[this.data.property_info.records[k].id] != undefined) {
                        this.remainingAssets[this.data.property_info.records[k].id] = 100 - this.usedTotalAssets[this.data.property_info.records[k].id];
                    } else {
                        this.remainingAssets[this.data.property_info.records[k].id] = 100;
                    }


                    if (this.remainingAssets[this.data.property_info.records[k].id] == 0) {

                        this.assetsTotalAreUsed = true;
                        if (this.totalRemaining > 0) {
                            this.totalRemaining--;
                        }
                        usedAssets.push(this.data.property_info.records[k].id);
                    } else {
                        this.assetsTotalAreUsed = false;
                        this.totalRemaining++;
                        usedAssets.splice(1);
                    }
                    if (this.remainingAssets[this.data.property_info.records[k].id] < 0) {
                        usedAssets.push(this.data.property_info.records[k].id);
                    }
                    if (usedAssets.length == this.data.property_info.records.length) {

                        this.totalRemaining = 0;
                    } else {

                        this.totalRemaining++
                        this.assetsTotalAreUsed = false;

                    }

                }
            },
            changeBeneficiary(event, item) {
                if (event.target.checked == true) {
                    if (this.remainingAssets[item.id] !== undefined) {
                        this.recordForm.properties[item.id] = this.remainingAssets[item.id];
                    } else {
                        this.recordForm.properties[item.id] = 100;
                    }
                } else {
                    this.recordForm.properties[item.id] = 0;
                }
            },
            allocatePercentageValue(item) {
                this.usedTotalMessage = '';
                this.generateRemainingAssets('Yes');
            },
            getTitleRule(Title, FieldName = null, FieldType = null) {
                return {
                    required: true,
                    message: `field required`,
                };
            },
            cancel() {
                this.generateRemainingAssets();
                this.recordForm.properties = {};
                this.recordIndex = null;
                this.resetForm();
            },
            backStep() {
                this.$emit('changeActiveTab', this.activeTab - 1);
            },
            getFieldRule(Title, FieldName = null, FieldType = null) {
                return {
                    required: true,
                    message: `${Title} field is required`,
                };
            },
            editRecord(index) {
                this.recordIndex = index;
                this.tradegy_info.add_tradegy = 'Yes';
                var record = {};
                for (const cnfg in this.tradegy_info.records[index]) {
                    record = _.merge(record, {[cnfg]: this.tradegy_info.records[index][cnfg]})
                }
                this.editableProperty = this.tradegy_info.records[index].properties;
                this.editAssets = true;
                this.recordForm = record;
            },
            deleteRecord(deleteIndex) {
                // this.editableProperty = this.tradegy_info.records[index].properties;
                this.tradegy_info.records.splice(deleteIndex, 1);
                this.cancel();
                if (this.tradegy_info.records.length == 0)
                    this.tradegy_info.add_tradegy = 'No';
                this.submit('Yes');
            },
            resetForm() {
                this.$refs[this.formName].resetFields();
                this.recordForm.middle_name = '';
            },
            addRecord() {
                if (this.loading == true)
                    return false;
                this.loading = true;
                this.editAssets = false;
                this.message = '';
                this.propert_message = '';


                // this.usedTotalMessage  = '';
                this.$refs[this.formName].validate((valid) => {
                    if (valid && this.usedTotalMessage == '') {
                        this.form = new Form({activeTab: this.activeTab, tradegy_record: this.recordForm});

                        if (this.id != 0) {
                            this.form.post(route('api.will.wills.savedata', {id: this.id}))
                                .then((response) => {
                                    this.loading = false;
                                    if (response.errors == false) {
                                        this.addInfo();
                                    } else {
                                        if (response.dashboardRoute != undefined) {
                                            window.location = (response.dashboardRoute);
                                        }
                                    }
                                })
                                .catch((error) => {
                                    this.loading = false;
                                });
                        } else {
                            this.form.post('/api/will/formData/add')
                                .then((response) => {
                                    this.loading = false;
                                    if (response.errors == false) {
                                        this.addInfo();
                                    } else {
                                        if (response.dashboardRoute != undefined) {
                                            window.location = (response.dashboardRoute);
                                        }
                                    }
                                })
                                .catch((error) => {
                                    this.loading = false;
                                });
                        }

                    } else {
                        this.loading = false;
                    }
                });
                // this.loading = false;

            },
            addInfo() {
                var record = 0;
                for (var j in this.recordForm.properties) {

                    if (this.recordForm.properties[j] > 0) {
                        var record = 1;
                        break;
                    }
                }
                if (record > 0) {

                    var submit = true;
                    for (var j in this.recordForm.properties) {
                        if (this.remainingAssets[j] != undefined && this.recordForm.properties[j] > this.remainingAssets[j]) {
                            this.assetsTotalAreUsed = false;
                            submit = false;
                        }
                        if (this.remainingAssets[j] != undefined && this.recordForm.properties[j] > this.remainingAssets[j]) {
                            this.remainingAssets[j] += parseInt(this.editableProperty[j]);
                            if (this.remainingAssets[j] != undefined && this.recordForm.properties[j] > this.remainingAssets[j]) {
                                this.assetsTotalAreUsed = false;
                                submit = false;
                            } else {
                                submit = true;
                            }
                        }
                    }

                    if (submit == true) {

                        var record = {};
                        for (const cnfg in this.recordForm) {
                            record = _.merge(record, {[cnfg]: this.recordForm[cnfg]})
                        }
                        if (this.recordIndex == null) {
                            record.id = "SP" + new Date().valueOf();
                            this.tradegy_info.records.push(record);
                        } else {
                            this.tradegy_info.records[this.recordIndex] = record;
                            this.recordIndex = null;
                        }
                        this.generateRemainingAssets();
                        this.recordForm.properties = {};
                        this.recordIndex = null;
                        this.resetForm();

                        this.submitTregedy('Yes');
                    }
                } else {
                    this.propert_message = 'please allocate atleast 1 property'
                }
            },
            submitTregedy(addInfo = null) {
                window.scrollTo({top: 0, behavior: 'smooth'});
                if (this.tradegy_info.add_tradegy == 'Yes' && this.tradegy_info.records.length == 0) {
                    this.$refs[this.formName].validate((valid) => {
                        if (valid) {
                            this.tradegy_info.add_tradegy = 'Yes';
                            this.message = "Please click on 'Add Tragedy' button to save the details";
                            ;
                        } else {
                            this.tradegy_info.add_tradegy = 'Yes';
                        }
                    });
                } else {
                    if (this.tradegy_info.add_tradegy != 'Yes' && this.usedTotalMessage == '') {
                        this.loading = true;
                        this.form = new Form({activeTab: this.activeTab, tradegy_info: this.tradegy_info});
                        this.form.post('/api/will/formData/add')
                            .then((response) => {
                                this.loading = false;
                                if (response.errors == false) {
                                    if (addInfo != 'Yes') {
                                        this.$emit('changeActiveTab', this.activeTab + 1);
                                        this.$emit('updateFormData', response.data);
                                    }
                                    this.generateRemainingAssets();
                                }
                            })
                            .catch((error) => {
                                this.loading = false;
                            });
                    } else {
                        if (this.usedTotalMessage == '') {
                            this.loading = true;
                            this.form = new Form({activeTab: this.activeTab, tradegy_info: this.tradegy_info});
                            if (this.id != 0) {
                                this.form.post(route('api.will.wills.savedata', {id: this.id}))

                                    .then((response) => {
                                        this.loading = false;
                                        if (response.errors == false) {
                                            if (addInfo != 'Yes') {
                                                this.$emit('changeActiveTab', this.activeTab + 1);
                                                this.$emit('updateFormData', response.data);
                                            }
                                            this.generateRemainingAssets();
                                        }
                                    })
                                    .catch((error) => {
                                        this.loading = false;
                                    });
                            } else {
                                this.form.post('/api/will/formData/add')
                                    .then((response) => {
                                        this.loading = false;
                                        if (response.errors == false) {
                                            if (addInfo != 'Yes') {
                                                this.$emit('changeActiveTab', this.activeTab + 1);
                                                this.$emit('updateFormData', response.data);
                                            } else {
                                                if (response.dashboardRoute != undefined) {
                                                    window.location = (response.dashboardRoute);
                                                }
                                            }
                                            this.generateRemainingAssets();
                                        }
                                    })
                                    .catch((error) => {
                                        this.loading = false;
                                    });
                            }
                        }
                    }
                }
            },
            submit(addInfo = null) {
                window.scrollTo({top: 0, behavior: 'smooth'});
                if (this.tradegy_info.add_tradegy == 'Yes' && this.tradegy_info.records.length == 0) {
                    this.$refs[this.formName].validate((valid) => {
                        if (valid) {
                            this.tradegy_info.add_tradegy = 'Yes';
                            this.message = "Please click on 'Add Tragedy' button to save the details";
                            ;
                        } else {
                            this.tradegy_info.add_tradegy = 'Yes';
                        }
                    });
                } else {
                    if (this.tradegy_info.add_tradegy != 'Yes' && this.usedTotalMessage == '') {
                        this.loading = true;
                        this.form = new Form({activeTab: this.activeTab, tradegy_info: this.tradegy_info});
                        if (this.id != 0) {
                            this.form.post(route('api.will.wills.savedata', {id: this.id}))

                                .then((response) => {
                                    this.loading = false;
                                    if (response.errors == false) {
                                        if (addInfo != 'Yes') {
                                            this.$emit('changeActiveTab', this.activeTab + 1);
                                            this.$emit('updateFormData', response.data);
                                        }
                                        this.generateRemainingAssets();
                                    }
                                })
                                .catch((error) => {
                                    this.loading = false;
                                });
                        } else {
                            this.form.post('/api/will/formData/add')
                                .then((response) => {
                                    this.loading = false;
                                    if (response.errors == false) {
                                        if (addInfo != 'Yes') {
                                            this.$emit('changeActiveTab', this.activeTab + 1);
                                            this.$emit('updateFormData', response.data);
                                        }
                                        this.generateRemainingAssets();
                                    }
                                })
                                .catch((error) => {
                                    this.loading = false;
                                });
                        }
                    } else {
                        if (this.assetsTotalAreUsed == true && this.usedTotalMessage == '') {
                            this.loading = true;
                            this.form = new Form({activeTab: this.activeTab, tradegy_info: this.tradegy_info});
                            if (this.id != 0) {
                                this.form.post(route('api.will.wills.savedata', {id: this.id}))

                                    .then((response) => {
                                        this.loading = false;
                                        if (response.errors == false) {
                                            if (addInfo != 'Yes') {
                                                this.$emit('changeActiveTab', this.activeTab + 1);
                                                this.$emit('updateFormData', response.data);
                                            }
                                            this.generateRemainingAssets();
                                        }
                                    })
                                    .catch((error) => {
                                        this.loading = false;
                                    });
                            } else {
                                this.form.post('/api/will/formData/add')
                                    .then((response) => {
                                        this.loading = false;
                                        if (response.errors == false) {
                                            if (addInfo != 'Yes') {
                                                this.$emit('changeActiveTab', this.activeTab + 1);
                                                this.$emit('updateFormData', response.data);
                                            } else {
                                                if (response.dashboardRoute != undefined) {
                                                    window.location = (response.dashboardRoute);
                                                }
                                            }
                                            this.generateRemainingAssets();
                                        }
                                    })
                                    .catch((error) => {
                                        this.loading = false;
                                    });
                            }
                        }
                    }
                }
            }
        },
        mounted() {
            this.loading = true;
            if (this.data != undefined && this.data.tradegy_info != undefined) {
                this.tradegy_info = this.data.tradegy_info;
                // this.tradegy_info.add_tradegy = 'Yes';
                this.generateRemainingAssets();
            }
            this.loading = false;
        },
        watch: {
            'tradegy_info.add_tradegy': function () {
                if (this.tradegy_info.add_tradegy == "No") {
                    this.tradegy_info.records = [];
                }
            },
            'recordForm.title': function () {
                if (this.recordForm.title != "Other") {
                    if (this.recordForm.other_title != '') {
                        this.recordForm.other_title = ''
                    }
                }
            }
        }
    };

</script>

<style>

</style>
