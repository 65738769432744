<template>
    <div class="card card-default all-will-summary shadow-none p-0">
        <div v-loading="loading">
            <div class="card-header">
                <h5 class="m-0">
                    Will Summary
                </h5>
            </div>
            <div class="card-body">

                <div class="row">

                    <div class="col-lg-12 col-md-12 col-sm-12 col-12 text-center">

                        <div class="will-summary-title">

                            <img src="/themes/flatly/images/will-summary.svg">

                            <h4><span>will summary</span></h4>

                        </div>

                    </div>

                </div>

                <!-- Personal Details -->

                <div class="row">

                    <div class="col-lg-12 col-md-12 col-sm-12 col-12">

                        <div class="row">

                            <div class="col-lg-11 col-md-11 col-sm-10 col-9">

                                <div class="will-summary-subtitle">

                                    <h4><span>1</span>Personal Details</h4>

                                </div>

                            </div>

                            <div class="col-lg-1 col-md-1 col-sm-12 col-3 text-right">


                                <el-button class="btn btn-sm btn-edit" title="Update Details" type="text" v-if="edit != undefined && edit == true"
                                           @click="editRecord('1')"><a class="fa fa-pencil"></a></el-button>


                            </div>

                        </div>

                        <div class="row">

                            <div class="col-lg-6 col-md-12 col-sm-12 col-12">

                                <div class="summary-card">

                                    <h5>Basic information</h5>

                                    <div class="form-row">

                                        <div class="form-group col-6 col-md-4 col-sm-4 col-lg-4">

                                            <label>Title</label>

                                            <h6 v-if="data.personal_info != undefined && data.personal_info.personal_info != undefined && data.personal_info.personal_info.title != undefined && data.personal_info.personal_info.title != ''">
                                                {{data.personal_info.personal_info.title}}</h6>
                                            <h6 v-else> N/A </h6>
                                        </div>

                                        <div class="form-group col-6 col-md-4 col-sm-4 col-lg-4">

                                            <label>First name</label>

                                            <h6 v-if="data.personal_info != undefined && data.personal_info.personal_info != undefined && data.personal_info.personal_info.first_name != undefined && data.personal_info.personal_info.first_name != ''">
                                                {{data.personal_info.personal_info.first_name}}</h6>
                                            <h6 v-else> N/A </h6>

                                        </div>

                                        <div class="form-group col-6 col-md-4 col-sm-4 col-lg-4">

                                            <label>Last name</label>

                                            <h6 v-if="data.personal_info != undefined && data.personal_info.personal_info != undefined && data.personal_info.personal_info.last_name != undefined && data.personal_info.personal_info.last_name != ''">
                                                {{data.personal_info.personal_info.last_name}}</h6>
                                            <h6 v-else> N/A </h6>

                                        </div>

                                        <div class="form-group col-6 col-md-4 col-sm-4 col-lg-4">

                                            <label>Middle name</label>

                                            <h6 v-if="data.personal_info != undefined && data.personal_info.personal_info != undefined && data.personal_info.personal_info.middle_name != undefined && data.personal_info.personal_info.middle_name != ''">
                                                {{data.personal_info.personal_info.middle_name}}</h6>
                                            <h6 v-else> N/A </h6>

                                        </div>

                                        <div class="form-group col-6 col-md-4 col-sm-4 col-lg-4">

                                            <label>Father’s name</label>

                                            <h6 v-if="data.personal_info != undefined && data.personal_info.personal_info != undefined && data.personal_info.personal_info.father_name != undefined && data.personal_info.personal_info.father_name != ''">
                                                {{data.personal_info.personal_info.father_name}}</h6>
                                            <h6 v-else> N/A </h6>

                                        </div>

                                        <div class="form-group col-6 col-md-4 col-sm-4 col-lg-4">

                                            <label>Gender</label>

                                            <h6 v-if="data.personal_info != undefined && data.personal_info.personal_info != undefined && data.personal_info.personal_info.gender != undefined && data.personal_info.personal_info.gender != ''">
                                                {{data.personal_info.personal_info.gender}}</h6>
                                            <h6 v-else> N/A </h6>

                                        </div>

                                        <div class="form-group col-6 col-md-4 col-sm-4 col-lg-4">

                                            <label>Date of Birth</label>

                                            <h6 v-if="data.personal_info != undefined && data.personal_info.personal_info != undefined && data.personal_info.personal_info.date_of_birth != undefined && data.personal_info.personal_info.date_of_birth != ''">
                                                {{data.personal_info.personal_info.date_of_birth}}</h6>
                                            <h6 v-else> N/A </h6>

                                        </div>

                                        <div class="form-group col-6 col-md-4 col-sm-4 col-lg-4">

                                            <label>Martial Status</label>

                                            <h6 v-if="data.personal_info != undefined && data.personal_info.personal_info != undefined && data.personal_info.personal_info.martial_status != undefined && data.personal_info.personal_info.martial_status != ''">
                                                {{data.personal_info.personal_info.martial_status}}</h6>
                                            <h6 v-else> N/A </h6>

                                        </div>

                                    </div>

                                    <h5>Address</h5>

                                    <div class="form-row">

                                        <div class="form-group col-6 col-md-4 col-sm-4 col-lg-4">

                                            <label>Address 1</label>

                                            <h6 v-if="data.personal_info != undefined && data.personal_info.personal_info != undefined && data.personal_info.personal_info.address_1 != undefined && data.personal_info.personal_info.address_1 != ''">
                                                {{data.personal_info.personal_info.address_1}}</h6>
                                            <h6 v-else> N/A </h6>

                                        </div>

                                        <div class="form-group col-6 col-md-4 col-sm-4 col-lg-4">

                                            <label>Address 2</label>

                                            <h6 v-if="data.personal_info != undefined && data.personal_info.personal_info != undefined && data.personal_info.personal_info.address_2 != undefined && data.personal_info.personal_info.address_2 != ''">
                                                {{data.personal_info.personal_info.address_2}}</h6>
                                            <h6 v-else> N/A </h6>

                                        </div>

                                        <div class="form-group col-6 col-md-4 col-sm-4 col-lg-4">

                                            <label>City</label>

                                            <h6 v-if="data.personal_info != undefined && data.personal_info.personal_info != undefined && data.personal_info.personal_info.city != undefined && data.personal_info.personal_info.city != ''">
                                                {{data.personal_info.personal_info.city}}</h6>
                                            <h6 v-else> N/A </h6>

                                        </div>

                                        <div class="form-group col-6 col-md-4 col-sm-4 col-lg-4">

                                            <label>State</label>

                                            <h6 v-if="data.personal_info != undefined && data.personal_info.personal_info != undefined && data.personal_info.personal_info.state != undefined && data.personal_info.personal_info.state != ''">
                                                {{data.personal_info.personal_info.state}}</h6>
                                            <h6 v-else> N/A </h6>

                                        </div>

                                        <div class="form-group col-6 col-md-4 col-sm-4 col-lg-4">

                                            <label>Pincode</label>

                                            <h6 v-if="data.personal_info != undefined && data.personal_info.personal_info != undefined && data.personal_info.personal_info.pin_code != undefined && data.personal_info.personal_info.pin_code != ''">
                                                {{data.personal_info.personal_info.pin_code}}</h6>
                                            <h6 v-else> N/A </h6>

                                        </div>

                                        <div class="form-group col-6 col-md-4 col-sm-4 col-lg-4">

                                            <label>Country</label>

                                            <h6 v-if="data.personal_info != undefined && data.personal_info.personal_info != undefined && data.personal_info.personal_info.country != undefined && data.personal_info.personal_info.country != ''">
                                                {{data.personal_info.personal_info.country}}</h6>
                                            <h6 v-else> N/A </h6>

                                        </div>

                                    </div>

                                </div>
                                <div class="summary-card">
                                    <h5>Employment</h5>
                                    <div class="form-row">
                                        <div class="form-group col-6 col-md-4 col-sm-4 col-lg-4">
                                            <label>Employer Name</label>
                                            <h6 v-if="data.personal_info != undefined && data.personal_info.employment != undefined && data.personal_info.employment.employer_name != undefined && data.personal_info.employment.employer_name != ''">
                                                {{data.personal_info.employment.employer_name}}</h6>
                                            <h6 v-else> N/A </h6>
                                        </div>

                                        <div class="form-group col-6 col-md-4 col-sm-4 col-lg-4">

                                            <label>Email ID</label>

                                            <h6 v-if="data.personal_info != undefined && data.personal_info.employment != undefined && data.personal_info.employment.email_id != undefined && data.personal_info.employment.email_id != ''">
                                                {{data.personal_info.employment.email_id}}</h6>
                                            <h6 v-else> N/A </h6>

                                        </div>

                                        <div class="form-group col-6 col-md-4 col-sm-4 col-lg-4">

                                            <label>Contact</label>

                                            <h6 v-if="data.personal_info != undefined && data.personal_info.employment != undefined && data.personal_info.employment.contact != undefined && data.personal_info.employment.contact != ''">
                                                {{data.personal_info.employment.contact}}</h6>
                                            <h6 v-else> N/A </h6>

                                        </div>

                                    </div>

                                </div>

                            </div>

                            <div class="col-lg-6 col-md-12 col-sm-12 col-12">

                                <div v-if="data.personal_info != undefined && data.personal_info.spouse_details != undefined && data.personal_info.spouse_details.title != undefined && data.personal_info.spouse_details.title != ''"
                                     class="summary-card">

                                    <h5>Spouse Details</h5>

                                    <div class="form-row">

                                        <div class="form-group col-6 col-md-4 col-sm-4 col-lg-4">

                                            <label>Title</label>

                                            <h6 v-if="data.personal_info != undefined && data.personal_info.spouse_details != undefined && data.personal_info.spouse_details.title != undefined && data.personal_info.spouse_details.title != ''">
                                                {{data.personal_info.spouse_details.title}}</h6>
                                            <h6 v-else> N/A </h6>
                                        </div>

                                        <div class="form-group col-6 col-md-4 col-sm-4 col-lg-4">

                                            <label>First name</label>

                                            <h6 v-if="data.personal_info != undefined && data.personal_info.spouse_details != undefined && data.personal_info.spouse_details.first_name != undefined && data.personal_info.spouse_details.first_name != ''">
                                                {{data.personal_info.spouse_details.first_name}}</h6>
                                            <h6 v-else> N/A </h6>

                                        </div>

                                        <div class="form-group col-6 col-md-4 col-sm-4 col-lg-4">

                                            <label>Last name</label>

                                            <h6 v-if="data.personal_info != undefined && data.personal_info.spouse_details != undefined && data.personal_info.spouse_details.last_name != undefined && data.personal_info.spouse_details.last_name != ''">
                                                {{data.personal_info.spouse_details.last_name}}</h6>
                                            <h6 v-else> N/A </h6>

                                        </div>

                                        <div class="form-group col-6 col-md-4 col-sm-4 col-lg-4">

                                            <label>Middle name</label>

                                            <h6 v-if="data.personal_info != undefined && data.personal_info.spouse_details != undefined && data.personal_info.spouse_details.middle_name != undefined && data.personal_info.spouse_details.middle_name != ''">
                                                {{data.personal_info.spouse_details.middle_name}}</h6>
                                            <h6 v-else> N/A </h6>

                                        </div>

                                        <div class="form-group col-6 col-md-4 col-sm-4 col-lg-4">

                                            <label>Gender</label>

                                            <h6 v-if="data.personal_info != undefined && data.personal_info.spouse_details != undefined && data.personal_info.spouse_details.gender != undefined && data.personal_info.spouse_details.gender != ''">
                                                {{data.personal_info.spouse_details.gender}}</h6>
                                            <h6 v-else> N/A </h6>

                                        </div>

                                        <div class="form-group col-6 col-md-4 col-sm-4 col-lg-4">

                                            <label>Date of Birth</label>

                                            <h6 v-if="data.personal_info != undefined && data.personal_info.spouse_details != undefined && data.personal_info.spouse_details.date_of_birth != undefined && data.personal_info.spouse_details.date_of_birth != ''">
                                                {{data.personal_info.spouse_details.date_of_birth}}</h6>
                                            <h6 v-else> N/A </h6>

                                        </div>


                                    </div>

                                    <h5>Address</h5>

                                    <div class="form-row">

                                        <div class="form-group col-6 col-md-4 col-sm-4 col-lg-4">

                                            <label>Address 1</label>

                                            <h6 v-if="data.personal_info != undefined && data.personal_info.spouse_details != undefined && data.personal_info.spouse_details.address_1 != undefined && data.personal_info.spouse_details.address_1 != ''">
                                                {{data.personal_info.spouse_details.address_1}}</h6>
                                            <h6 v-else> N/A </h6>

                                        </div>

                                        <div class="form-group col-6 col-md-4 col-sm-4 col-lg-4">

                                            <label>Address 2</label>

                                            <h6 v-if="data.personal_info != undefined && data.personal_info.spouse_details != undefined && data.personal_info.spouse_details.address_2 != undefined && data.personal_info.spouse_details.address_2 != ''">
                                                {{data.personal_info.spouse_details.address_2}}</h6>
                                            <h6 v-else> N/A </h6>

                                        </div>

                                        <div class="form-group col-6 col-md-4 col-sm-4 col-lg-4">

                                            <label>City</label>

                                            <h6 v-if="data.personal_info != undefined && data.personal_info.spouse_details != undefined && data.personal_info.spouse_details.city != undefined && data.personal_info.spouse_details.city != ''">
                                                {{data.personal_info.spouse_details.city}}</h6>
                                            <h6 v-else> N/A </h6>

                                        </div>

                                        <div class="form-group col-6 col-md-4 col-sm-4 col-lg-4">

                                            <label>State</label>

                                            <h6 v-if="data.personal_info != undefined && data.personal_info.spouse_details != undefined && data.personal_info.spouse_details.state != undefined && data.personal_info.spouse_details.state != ''">
                                                {{data.personal_info.spouse_details.state}}</h6>
                                            <h6 v-else> N/A </h6>

                                        </div>

                                        <div class="form-group col-6 col-md-4 col-sm-4 col-lg-4">

                                            <label>Pincode</label>

                                            <h6 v-if="data.personal_info != undefined && data.personal_info.spouse_details != undefined && data.personal_info.spouse_details.pin_code != undefined && data.personal_info.spouse_details.pin_code != ''">
                                                {{data.personal_info.spouse_details.pin_code}}</h6>
                                            <h6 v-else> N/A </h6>

                                        </div>

                                        <div class="form-group col-6 col-md-4 col-sm-4 col-lg-4">

                                            <label>Country</label>

                                            <h6 v-if="data.personal_info != undefined && data.personal_info.spouse_details != undefined && data.personal_info.spouse_details.country != undefined && data.personal_info.spouse_details.country != ''">
                                                {{data.personal_info.spouse_details.country}}</h6>
                                            <h6 v-else> N/A </h6>

                                        </div>

                                    </div>

                                </div>

                                <div class="summary-card" v-else>
                                    <h5>Spouse Details</h5>
                                    <div class="form-row">
                                        <div class="form-group col-12 col-md-12 col-sm-12 col-lg-12">
                                            <h5 class="text-center">No Spouse Detail Added</h5>
                                        </div>
                                    </div>
                                </div>

                                <div class="summary-card">

                                    <h5>ID Proof</h5>

                                    <div class="form-row">

                                        <div class="form-group col-6 col-md-4 col-sm-4 col-lg-4">

                                            <label>Type</label>

                                            <h6 v-if="data.personal_info != undefined && data.personal_info.id_proof != undefined && data.personal_info.id_proof.identity_proof != undefined && data.personal_info.id_proof.identity_proof != ''">
                                                {{data.personal_info.id_proof.identity_proof}}</h6>
                                            <h6 v-else> N/A </h6>

                                        </div>

                                        <div class="form-group col-6 col-md-4 col-sm-4 col-lg-4">

                                            <label>Selected Id no</label>

                                            <h6 v-if="data.personal_info != undefined && data.personal_info.id_proof != undefined && data.personal_info.id_proof.id_no != undefined && data.personal_info.id_proof.id_no != ''">
                                                {{data.personal_info.id_proof.id_no}}</h6>
                                            <h6 v-else> N/A </h6>

                                        </div>

                                        <div class="form-group col-6 col-md-4 col-sm-4 col-lg-4">

                                            <label>Religion*</label>

                                            <h6 v-if="data.personal_info != undefined && data.personal_info.personal_info != undefined && data.personal_info.personal_info.religion != undefined && data.personal_info.id_proof.religion != ''">
                                                {{data.personal_info.personal_info.religion}}</h6>
                                            <h6 v-else> N/A </h6>

                                        </div>

                                    </div>

                                </div>

                            </div>


                        </div>

                    </div>

                </div>

                <!-- Child Details -->

                <div class="row">

                    <div class="col-lg-12 col-md-12 col-sm-12 col-12">

                        <div class="row">

                            <div class="col-lg-11 col-md-11 col-sm-10 col-9">

                                <div class="will-summary-subtitle">

                                    <h4><span>2</span>Child Details</h4>

                                </div>

                            </div>

                            <div class="col-lg-1 col-md-1 col-sm-12 col-3 text-right">


                                <el-button type="text" title="Update Details" v-if="edit != undefined && edit == true"
                                           class="btn btn-sm btn-edit" @click="editRecord('2')"><a class="fa fa-pencil"></a></el-button>


                            </div>

                        </div>

                        <div class="summary-card">

                            <div class="table-responsive">

                                <table class="table">

                                    <thead>

                                    <tr v-if="data.children_info != undefined && data.children_info.have_children == 'Yes' && data.children_info.records.length > 0">

                                        <th class="col4">Title</th>

                                        <th class="col7">First name</th>

                                        <th class="col7">Last name</th>

                                        <th class="col8">Middle name</th>

                                        <th class="col5">Gender</th>

                                        <th class="col7">Date of Birth</th>

                                        <th class="col7">Address 1</th>

                                        <th class="col7">Address 2</th>

                                        <th class="col6">City</th>

                                        <th class="col4">State</th>

                                        <th class="col6">Pin code</th>

                                        <th class="col5">Country</th>

                                    </tr>

                                    </thead>

                                    <tbody v-if="data.children_info != undefined && data.children_info.have_children == 'Yes' && data.children_info.records.length > 0">

                                    <tr v-for="(record,index) in data.children_info.records">

                                        <td v-if="record.title != undefined && record.title != ''"> {{record.title}}</td>
                                        <td v-else> N/A</td>

                                        <td v-if="record.first_name != undefined && record.first_name != ''">
                                            {{record.first_name}}
                                        </td>
                                        <td v-else> N/A</td>

                                        <td v-if="record.last_name != undefined && record.last_name != ''">
                                            {{record.last_name}}
                                        </td>
                                        <td v-else> N/A</td>

                                        <td v-if="record.middle_name != undefined && record.middle_name != ''">
                                            {{record.middle_name}}
                                        </td>
                                        <td v-else> N/A</td>

                                        <td v-if="record.gender != undefined && record.gender != ''">{{record.gender}}</td>
                                        <td v-else> N/A</td>

                                        <td v-if="record.date_of_birth != undefined && record.date_of_birth != ''">
                                            {{record.date_of_birth}}
                                        </td>
                                        <td v-else> N/A</td>

                                        <td v-if="record.address_1 != undefined && record.address_1 != ''">
                                            {{record.address_1}}
                                        </td>
                                        <td v-else> N/A</td>

                                        <td v-if="record.address_2 != undefined && record.address_2 != ''">
                                            {{record.address_2}}
                                        </td>
                                        <td v-else> N/A</td>

                                        <td v-if="record.city != undefined && record.city != ''">{{record.city}}</td>
                                        <td v-else> N/A</td>

                                        <td v-if="record.state != undefined && record.state != ''">{{record.state}}</td>
                                        <td v-else> N/A</td>

                                        <td v-if="record.pin_code != undefined && record.pin_code != ''">
                                            {{record.pin_code}}
                                        </td>
                                        <td v-else> N/A</td>

                                        <td v-if="record.country != undefined && record.country != ''">{{record.country}}
                                        </td>
                                        <td v-else> N/A</td>

                                    </tr>

                                    </tbody>

                                    <tbody v-else>

                                    <h5 class="text-center"> No Child details Added</h5>

                                    </tbody>

                                </table>

                            </div>

                        </div>

                    </div>

                </div>

                <!-- Guardian Details -->

                <div class="row">

                    <div class="col-lg-12 col-md-12 col-sm-12 col-12">

                        <div class="row">

                            <div class="col-lg-11 col-md-11 col-sm-10 col-9">

                                <div class="will-summary-subtitle">

                                    <h4><span>3</span>Guardian Details</h4>

                                </div>

                            </div>

                            <div class="col-lg-1 col-md-1 col-sm-12 col-3 text-right">


                                <el-button type="text" title="Update Details" v-if="edit != undefined && edit == true"
                                           class="btn btn-sm btn-edit" @click="editRecord('3')"><a class="fa fa-pencil"></a></el-button>


                            </div>

                        </div>

                        <div class="summary-card">

                            <div class="table-responsive">

                                <table class="table">

                                    <thead>

                                    <tr v-if="data.guardian_info != undefined && data.guardian_info.appoint_guardian == 'Yes' && data.guardian_info.records.length > 0">

                                        <th class="col4">Title</th>

                                        <th class="col7">First name</th>

                                        <th class="col7">Last name</th>

                                        <th class="col8">Middle name</th>

                                        <th class="col5">Gender</th>

                                        <th class="col6">Type</th>

                                        <th class="col7">Relationship</th>

                                        <th class="col7">Address 1</th>

                                        <th class="col7">Address 2</th>

                                        <th class="col6">City</th>

                                        <th class="col4">State</th>

                                        <th class="col6">Pin code</th>

                                        <th class="col5">Country</th>

                                    </tr>

                                    </thead>

                                    <tbody v-if="data.guardian_info != undefined && data.guardian_info.appoint_guardian == 'Yes' && data.guardian_info.records.length > 0">

                                    <tr v-for="(record,index) in data.guardian_info.records">

                                        <td v-if="record.title != undefined && record.title != ''"> {{record.title}}</td>
                                        <td v-else> N/A</td>

                                        <td v-if="record.first_name != undefined && record.first_name != ''">
                                            {{record.first_name}}
                                        </td>
                                        <td v-else> N/A</td>

                                        <td v-if="record.last_name != undefined && record.last_name != ''">
                                            {{record.last_name}}
                                        </td>
                                        <td v-else> N/A</td>

                                        <td v-if="record.middle_name != undefined && record.middle_name != ''">
                                            {{record.middle_name}}
                                        </td>
                                        <td v-else> N/A</td>

                                        <td v-if="record.gender != undefined && record.gender != ''">{{record.gender}}</td>
                                        <td v-else> N/A</td>

                                        <td v-if="record.type != undefined && record.type != ''">{{record.type}}</td>
                                        <td v-else> N/A</td>

                                        <td v-if="record.relationship != undefined && record.relationship != ''">
                                            {{record.relationship}}
                                        </td>
                                        <td v-else> N/A</td>

                                        <td v-if="record.address_1 != undefined && record.address_1 != ''">
                                            {{record.address_1}}
                                        </td>
                                        <td v-else> N/A</td>

                                        <td v-if="record.address_2 != undefined && record.address_2 != ''">
                                            {{record.address_2}}
                                        </td>
                                        <td v-else> N/A</td>

                                        <td v-if="record.city != undefined && record.city != ''">{{record.city}}</td>
                                        <td v-else> N/A</td>

                                        <td v-if="record.state != undefined && record.state != ''">{{record.state}}</td>
                                        <td v-else> N/A</td>

                                        <td v-if="record.pin_code != undefined && record.pin_code != ''">
                                            {{record.pin_code}}
                                        </td>
                                        <td v-else> N/A</td>

                                        <td v-if="record.country != undefined && record.country != ''">{{record.country}}
                                        </td>
                                        <td v-else> N/A</td>

                                    </tr>

                                    </tbody>

                                    <tbody v-else>

                                    <h5 class="text-center"> No Guardian Details Added</h5>

                                    </tbody>

                                </table>

                            </div>

                        </div>

                    </div>

                </div>

                <!-- Beneficiary  Details -->

                <div class="row">

                    <div class="col-lg-12 col-md-12 col-sm-12 col-12">

                        <div class="row">

                            <div class="col-lg-11 col-md-11 col-sm-10 col-9">

                                <div class="will-summary-subtitle">

                                    <h4><span>4</span>Beneficiary Details</h4>

                                </div>

                            </div>

                            <div class="col-lg-1 col-md-1 col-sm-12 col-3 text-right">


                                <el-button type="text" v-if="edit != undefined && edit == true"
                                           class="btn btn-sm btn-edit" @click="editRecord('4')"><a class="fa fa-pencil"></a></el-button>


                            </div>

                        </div>

                        <div class="summary-card">

                            <div class="table-responsive">

                                <table class="table">

                                    <thead>

                                    <tr v-if="data.beneficiary_info != undefined  && data.beneficiary_info.beneficiaries.length > 0">

                                        <th class="col4">Title</th>

                                        <th class="col7">First name</th>

                                        <th class="col7">Last name</th>

                                        <th class="col8">Middle name</th>

                                        <th class="col5">Gender</th>

                                        <th class="col7">Relationship</th>

                                        <th class="col7">Address 1</th>

                                        <th class="col7">Address 2</th>

                                        <th class="col6">City</th>

                                        <th class="col4">State</th>

                                        <th class="col6">Pin code</th>

                                        <th class="col5">Country</th>

                                    </tr>

                                    </thead>

                                    <tbody v-if="data.beneficiary_info != undefined  && data.beneficiary_info.beneficiaries.length > 0">

                                    <tr v-for="(record,index) in data.beneficiary_info.beneficiaries">

                                        <td v-if="record.title != undefined && record.title != ''"> {{record.title}}</td>
                                        <td v-else> N/A</td>

                                        <td v-if="record.first_name != undefined && record.first_name != ''">
                                            {{record.first_name}}
                                        </td>
                                        <td v-else> N/A</td>

                                        <td v-if="record.last_name != undefined && record.last_name != ''">
                                            {{record.last_name}}
                                        </td>
                                        <td v-else> N/A</td>

                                        <td v-if="record.middle_name != undefined && record.middle_name != ''">
                                            {{record.middle_name}}
                                        </td>
                                        <td v-else> N/A</td>

                                        <td v-if="record.gender != undefined && record.gender != ''">{{record.gender}}</td>
                                        <td v-else> N/A</td>

<!--                                        <td v-if="record.type != undefined && record.type != ''">{{record.type}}</td>-->
<!--                                        <td v-else> N/A</td>-->

                                        <td v-if="record.relationship != undefined && record.relationship != ''">
                                            {{record.relationship}}
                                        </td>
                                        <td v-else> N/A</td>

                                        <td v-if="record.address_1 != undefined && record.address_1 != ''">
                                            {{record.address_1}}
                                        </td>
                                        <td v-else> N/A</td>

                                        <td v-if="record.address_2 != undefined && record.address_2 != ''">
                                            {{record.address_2}}
                                        </td>
                                        <td v-else> N/A</td>

                                        <td v-if="record.city != undefined && record.city != ''">{{record.city}}</td>
                                        <td v-else> N/A</td>

                                        <td v-if="record.state != undefined && record.state != ''">{{record.state}}</td>
                                        <td v-else> N/A</td>

                                        <td v-if="record.pin_code != undefined && record.pin_code != ''">
                                            {{record.pin_code}}
                                        </td>
                                        <td v-else> N/A</td>

                                        <td v-if="record.country != undefined && record.country != ''">{{record.country}}
                                        </td>
                                        <td v-else> N/A</td>

                                    </tr>

                                    </tbody>

                                    <tbody v-else>

                                    <h5 class="text-center"> No Beneficiary Detail Added</h5>

                                    </tbody>

                                </table>

                            </div>

                            <h5 class="mt-2">Special Clause</h5>

                            <div class="table-responsive">

                                <table class="table">

                                    <thead>

                                    <tr v-if="data.beneficiary_info != undefined && data.beneficiary_info.appoint_special == 'Yes'  && data.beneficiary_info.specialclauses.length > 0">

                                        <th class="col4">Title</th>

                                        <th class="col7">First name</th>

                                        <th class="col7">Last name</th>

                                        <th class="col8">Middle name</th>


                                        <th class="col7">Relationship</th>

                                        <th class="col7">Address 1</th>

                                        <th class="col7">Address 2</th>

                                        <th class="col6">City</th>

                                        <th class="col4">State</th>

                                        <th class="col6">Pin code</th>

                                        <th class="col5">Country</th>

                                    </tr>

                                    </thead>

                                    <tbody v-if="data.beneficiary_info != undefined && data.beneficiary_info.appoint_special == 'Yes' && data.beneficiary_info.specialclauses.length > 0">

                                    <tr v-for="(record,index) in data.beneficiary_info.specialclauses">

                                        <td v-if="record.title != undefined && record.title != ''"> {{record.title}}</td>
                                        <td v-else> N/A</td>

                                        <td v-if="record.first_name != undefined && record.first_name != ''">
                                            {{record.first_name}}
                                        </td>
                                        <td v-else> N/A</td>

                                        <td v-if="record.last_name != undefined && record.last_name != ''">
                                            {{record.last_name}}
                                        </td>
                                        <td v-else> N/A</td>

                                        <td v-if="record.middle_name != undefined && record.middle_name != ''">
                                            {{record.middle_name}}
                                        </td>
                                        <td v-else> N/A</td>


                                        <td v-if="record.relationship != undefined && record.relationship != ''">
                                            {{record.relationship}}
                                        </td>
                                        <td v-else> N/A</td>

                                        <td v-if="record.address_1 != undefined && record.address_1 != ''">
                                            {{record.address_1}}
                                        </td>
                                        <td v-else> N/A</td>

                                        <td v-if="record.address_2 != undefined && record.address_2 != ''">
                                            {{record.address_2}}
                                        </td>
                                        <td v-else> N/A</td>

                                        <td v-if="record.city != undefined && record.city != ''">{{record.city}}</td>
                                        <td v-else> N/A</td>

                                        <td v-if="record.state != undefined && record.state != ''">{{record.state}}</td>
                                        <td v-else> N/A</td>

                                        <td v-if="record.pin_code != undefined && record.pin_code != ''">
                                            {{record.pin_code}}
                                        </td>
                                        <td v-else> N/A</td>

                                        <td v-if="record.country != undefined && record.country != ''">{{record.country}}
                                        </td>
                                        <td v-else> N/A</td>

                                    </tr>

                                    </tbody>

                                    <tbody v-else>

                                    <h5 class="text-center"> No Special Clause Record Added</h5>

                                    </tbody>

                                </table>

                            </div>

                        </div>


                    </div>

                </div>

                <!-- Estate Details  -->

                <div class="row">

                    <div class="col-lg-12 col-md-12 col-sm-12 col-12">

                        <div class="row">

                            <div class="col-lg-11 col-md-11 col-sm-10 col-9">

                                <div class="will-summary-subtitle">

                                    <h4><span>5</span>Estate Details</h4>

                                </div>

                            </div>

                            <div class="col-lg-1 col-md-1 col-sm-12 col-3 text-right">


                                <el-button type="text" title="Update Details" v-if="edit != undefined && edit == true"
                                           class="btn btn-sm btn-edit" @click="editRecord('5')"><a class="fa fa-pencil"></a></el-button>


                            </div>

                        </div>

                        <div class="row"
                             v-if="data.property_info != undefined && data.property_info.add_estate == 'Yes' && data.property_info.records!= undefined && data.property_info.records.length > 0">

                            <div class="col-lg-6 col-md-12 col-sm-12 col-12"
                                 v-if="data.property_info != undefined && data.property_info.add_estate == 'Yes' && data.property_info.records!= undefined && data.property_info.records.length > 0"
                                 v-for="(record,index) in data.property_info.records">

                                <div class="summary-card estate-summary">

                                    <h5 v-if="record.asset_type != undefined && record.asset_type == 'SharesStock'">Stocks & Shares</h5><h5 v-else-if="record.asset_type != undefined">{{record.asset_type}}</h5>

                                    <div class="form-row">

                                        <div class="form-group col-12 col-md-4 col-sm-4 col-lg-4"
                                             v-if="record.asset_name_label != undefined">

                                            <span v-if="record.asset_name_label == 'SharesStock'">Stocks & Shares</span><span v-else>{{record.asset_name_label}}</span>

                                        </div>

                                        <div class="form-group col-12 col-md-4 col-sm-4 col-lg-4"
                                             v-if="record.asset_detail_label != undefined">

                                            <span>{{record.asset_detail_label}}</span>

                                        </div>

                                        <div class="form-group col-12 col-md-4 col-sm-4 col-lg-4"
                                             v-if="record.asset_ac_no_label != undefined || record.mutual_fund_ac_no_label != undefined || record.Intellectual_property_label != undefined|| record.crypto_currency_ac_no_label != undefined || record.gratuity_ac_no_label != undefined ||  DigitalAssets_ac_no_label != undefined||  record.
                         demat_ac_no_label != undefined || record.WMA_ac_no_label != undefined || record.bond_ac_no_label != undefined || record.asset_ac_no_label != undefined||record.vehicle_name_label != undefined ">
                                            <span v-v-if="record.asset_ac_no_label != undefined">{{record.asset_ac_no_label}}</span>
                                            <span v-v-if="record.Intellectual_property_label != undefined">{{record.Intellectual_property_label}}</span>
                                            <span v-v-if="DigitalAssets_ac_no_label != undefined">{{record.DigitalAssets_ac_no_label}}</span>
                                            <span v-v-if="record.mutual_fund_ac_no_label != undefined">{{record.mutual_fund_ac_no_label}}</span>
                                            <span v-v-if="record.demat_ac_no_label != undefined">{{record.demat_ac_no_label}}</span>
                                            <span v-v-if="record.WMA_ac_no_label != undefined">{{record.WMA_ac_no_label}}</span>
                                            <span v-v-if="record.bond_ac_no_label != undefined">{{record.bond_ac_no_label}}</span>
                                            <span v-v-if="record.gratuity_ac_no_label != undefined">{{record.gratuity_ac_no_label}}</span>
                                            <span v-v-if="record.crypto_currency_ac_no_label != undefined">{{record.crypto_currency_ac_no_label}}</span>
                                        </div>

                                    </div>

                                    <div class="form-row">

                                        <div class="form-group col-12 col-md-6 col-sm-6 col-lg-6" v-if="checkBeniciariesAssociated(record,'Primary')">

                                            <h6>Primary Beneficiary</h6>

                                            <hr>

                                            <ul>

                                                <li v-if="record.primary_beneficiaries[item.id]!=undefined && record.primary_beneficiaries[item.id] > 0 "
                                                    v-for="(item,index) in data.beneficiary_info.beneficiaries" class="form_list">

                                                    <span class="float-left form_list_left">{{item.first_name}} {{item.middle_name}} {{item.last_name}} </span>

                                                    <span class="float-right">{{ record.primary_beneficiaries[item.id] }}%</span>

                                                </li>

                                            </ul>

                                        </div>

                                        <div class="form-group col-12 col-md-6 col-sm-6 col-lg-6" v-if="checkBeniciariesAssociated(record,'Secondary')">

                                            <h6>Secondary Beneficiary</h6>

                                            <hr>

                                            <ul>

                                                <li v-if="record.secondary_beneficiaries != undefined && record.secondary_beneficiaries[item.id]!=undefined && record.secondary_beneficiaries[item.id] > 0"
                                                    v-for="(item,index) in data.beneficiary_info.beneficiaries" class="form_list">

                                                    <span class="float-left form_list_left">{{item.first_name}} {{item.middle_name}} {{item.last_name}} </span>

                                                    <span class="float-right">{{ record.secondary_beneficiaries[item.id] }}%</span>

                                                </li>

                                                <!--                                            <li v-if="is_secondary[index] != true">-->

                                                <!--                                                <span class="float"><b>not Allocated</b></span>-->

                                                <!--                                            </li>-->


                                            </ul>

                                        </div>

                                    </div>

                                </div>

                            </div>

                        </div>
                        <div class="row" v-else>
                            <div class="col-lg-12 col-md-12 col-sm-12 col-12">

                                <div class="summary-card estate-summary" style="min-height: 4rem;">
                                    <h5 class="text-center"> No Estate Details Added</h5>
                                </div>

                            </div>
                        </div>

                    </div>

                </div>

                <!-- Gift Details  -->

                <div class="row">

                    <div class="col-lg-12 col-md-12 col-sm-12 col-12">

                        <div class="row">

                            <div class="col-lg-11 col-md-11 col-sm-10 col-9">

                                <div class="will-summary-subtitle">

                                    <h4><span>6</span>Gift Details</h4>

                                </div>

                            </div>

                            <div class="col-lg-1 col-md-1 col-sm-12 col-3 text-right">


                                <el-button type="text" title="Update Details" v-if="edit != undefined && edit == true"
                                           class="btn btn-sm btn-edit" @click="editRecord('6')"><a class="fa fa-pencil"></a></el-button>


                            </div>

                        </div>

                        <div class="row"
                             v-if="data.gift_info != undefined && data.gift_info.add_gift != 'No' && data.gift_info.records != undefined && data.gift_info.records.length > 0">

                            <div class="col-lg-6 col-md-12 col-sm-12 col-12"
                                 v-if="data.gift_info != undefined && data.gift_info.add_gift != 'No' && data.gift_info.records != undefined && data.gift_info.records.length > 0"
                                 v-for="(record,index) in data.gift_info.records">

                                <div class="summary-card estate-summary">

                                    <h5 v-if="record.type != undefined">{{record.type}}</h5>

                                    <div class="form-row">

                                        <div class="form-group col-12 col-md-4 col-sm-4 col-lg-4">

                                            <span v-if="record.description != undefined">{{record.description}}</span>

                                        </div>

                                        <div class="form-group col-12 col-md-4 col-sm-4 col-lg-4">

                                            <span v-if="record.location != undefined">{{record.location}}</span>

                                        </div>

                                    </div>

                                    <div class="form-row">

                                        <div class="form-group col-12 col-md-6 col-sm-6 col-lg-6" v-if="checkBeniciariesAssociated(record,'Primary')">

                                            <h6>Primary Beneficiary</h6>

                                            <hr>

                                            <ul>

                                                <li v-if="record.primary_beneficiaries[item.id]!=undefined && record.primary_beneficiaries[item.id] > 0"
                                                    v-for="(item,index) in data.beneficiary_info.beneficiaries" class="form_list">

                                                    <span class="float-left form_list_left">{{item.first_name}} {{item.middle_name}} {{item.last_name}} </span>

                                                    <span class="float-right">{{ record.primary_beneficiaries[item.id] }}%</span>

                                                </li>


                                            </ul>

                                        </div>

                                        <div class="form-group col-12 col-md-6 col-sm-6 col-lg-6" v-if="checkBeniciariesAssociated(record,'Secondary')">

                                            <h6>Secondary Beneficiary</h6>

                                            <hr>

                                            <ul>

                                                <li v-if="record.secondary_beneficiaries[item.id]!=undefined && record.secondary_beneficiaries[item.id] > 0"
                                                    v-for="(item,index) in data.beneficiary_info.beneficiaries" class="form_list">
 
                                                    <span class="float-left form_list_left">{{item.first_name}} {{item.middle_name}} {{item.last_name}} </span>

                                                    <span class="float-right">{{ record.secondary_beneficiaries[item.id] }}%</span>

                                                </li>


                                            </ul>

                                        </div>

                                    </div>

                                </div>

                            </div>

                        </div>

                        <div class="row" v-else>

                            <div class="col-lg-12 col-md-12 col-sm-12 col-12">

                                <div class="summary-card estate-summary" style="min-height: 4rem;">
                                    <h5 class="text-center"> No Gift Details Added</h5>
                                </div>

                            </div>
                        </div>

                    </div>

                </div>

                <!-- Liability Details -->
                <div class="row">

                    <div class="col-lg-12 col-md-12 col-sm-12 col-12">

                        <div class="row">

                            <div class="col-lg-11 col-md-11 col-sm-10 col-9">

                                <div class="will-summary-subtitle">

                                    <h4><span>7</span>Liability Details</h4>

                                </div>

                            </div>

                            <div class="col-lg-1 col-md-1 col-sm-12 col-3 text-right">


                                <el-button type="text" title="Update Details" v-if="edit != undefined && edit == true"
                                           class="btn btn-sm btn-edit" @click="editRecord('7')"><a class="fa fa-pencil"></a></el-button>


                            </div>

                        </div>

                        <div class="row"
                             v-if="data.liability_info != undefined && data.liability_info.add_liability == 'Yes' && data.liability_info.records.length > 0">
                            <div class="col-lg-6 col-md-12 col-sm-12 col-12"
                                 v-if="data.liability_info != undefined && data.liability_info.add_liability == 'Yes' && data.liability_info.records.length > 0"
                                 v-for="(record,index) in data.liability_info.records">

                                <div class="summary-card estate-summary">
                                    <h5>{{ record.asset_type }} - {{record.bank_name}}</h5>
                                    <ul class="mb-2 p-1">
                                        <li>
                                            <span class="float-left">Account Number</span>
                                            <span class="float-right">{{ record.account_number }}</span>
                                        </li>
                                        <li>
                                            <span class="float-left">Amount</span>
                                            <span class="float-right">{{ record.amount }}</span>
                                        </li>
                                    </ul>
                                    <!-- <h6 class="px-1">Note:</h6> -->
                                    <!-- <p class="px-1">{{record.summary_help}}</p> -->

                                    <div class="form-row" v-if="record.properties">
                                        <div class="form-group col-12 col-md-6 col-sm-6 col-lg-6" v-if="checkAssociated(record,'Movable')">
                                            <h6>Movable</h6>
                                            <hr>
                                            <ul>
                                                <li v-if="info.asset_type != 'Land' && info.asset_type != 'House' && info.asset_type != 'Commercial Property' && info.asset_type != 'Other Immovable' && record.properties[info.id]!=undefined && record.properties[info.id] > 0"
                                                    v-for="(info,index) in data.property_info.records" class="form_list">
                                                    <span class="float-left form_list_left">{{asset_types[info.asset_type]}} [{{info.asset_name_label ? info.asset_name_label : ''}}{{info.asset_ac_no_label ? ' - '+info.asset_ac_no_label : ''}}{{info.asset_detail_label ? ' - '+info.asset_detail_label: ''}}]</span>
                    
                                                    <span class="float-right">{{ record.properties[info.id] }}%</span>
                                                </li>
                                            </ul>
                                        </div>
                    
                                        <div class="form-group col-12 col-md-6 col-sm-6 col-lg-6" v-if="checkAssociated(record,'Immovable')">
                                            <template v-if="data.property_info.records != undefined" v-for="(info,index) in filteredItems(data.property_info.records, record)">
                                                <template v-if='index === 0'>
                                                    <h6>Immovable</h6>
                                                    <hr>
                                                </template>
                                            </template>
                                            <ul>
                                                <li v-if="(info.asset_type == 'Land' || info.asset_type == 'House' || info.asset_type == 'Commercial Property' || info.asset_type == 'Other Immovable') && record.properties[info.id]!=undefined && record.properties[info.id] > 0 && data.property_info.records != undefined"
                                                    v-for="(info,index) in data.property_info.records" class="form_list">

                                                    <span class="float-left form_list_left">{{asset_types[info.asset_type]}}[{{info.asset_name_label ? info.asset_name_label : ''}}{{info.asset_detail_label ? ' - '+info.asset_detail_label: ''}}]</span>

                                                    <span class="float-right">{{ record.properties[info.id] }}%</span>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div class="px-1" v-if="record.properties.length === 0">Please clear the liability from my estate</div>
                                </div>

                            </div>

                        </div>

                        <div class="row" v-else>

                            <div class="col-lg-12 col-md-12 col-sm-12 col-12">

                                <div class="summary-card estate-summary" style="min-height: 4rem;">
                                    <h5 class="text-center"> No Liability Details Added</h5>
                                </div>

                            </div>
                        </div>

                    </div>

                </div>

                <!-- Executors Details -->

                <div class="row">

                    <div class="col-lg-12 col-md-12 col-sm-12 col-12">

                        <div class="row">

                            <div class="col-lg-11 col-md-11 col-sm-10 col-9">

                                <div class="will-summary-subtitle">

                                    <h4><span>8</span>Executors Details</h4>

                                </div>

                            </div>

                            <div class="col-lg-1 col-md-1 col-sm-12 col-3 text-right">


                                <el-button type="text" title="Update Details" v-if="edit != undefined && edit == true"
                                           class="btn btn-sm btn-edit" @click="editRecord('8')"><a class="fa fa-pencil"></a></el-button>


                            </div>

                        </div>

                        <div class="summary-card">

                            <div class="table-responsive">

                                <table class="table">

                                    <thead>

                                    <tr v-if="data.executor_info != undefined && data.executor_info.add_executor == 'Yes' && data.executor_info.records.length > 0">

                                        <th class="col4">Title</th>

                                        <th class="col7">First name</th>

                                        <th class="col7">Last name</th>

                                        <th class="col8">Middle name</th>

                                        <th class="col7">Type</th>

                                        <th class="col7">Relationship</th>

                                        <th class="col7">Address 1</th>

                                        <th class="col7">Address 2</th>

                                        <th class="col6">City</th>

                                        <th class="col4">State</th>

                                        <th class="col6">Pin code</th>

                                        <th class="col5">Country</th>

                                    </tr>

                                    </thead>

                                    <tbody v-if="data.executor_info != undefined && data.executor_info.add_executor == 'Yes' && data.executor_info.records.length > 0">

                                    <tr v-for="(record,index) in data.executor_info.records">

                                        <td v-if="record.title != undefined && record.title != ''">{{record.title}}</td>
                                        <td v-else> N/A</td>

                                        <td v-if="record.first_name != undefined && record.first_name != ''">{{record.first_name}}</td>
                                        <td v-else> N/A</td>

                                        <td v-if="record.last_name != undefined && record.last_name != ''">{{record.last_name}}</td>
                                        <td v-else> N/A</td>

                                        <td v-if="record.middle_name != undefined && record.middle_name != ''">{{record.middle_name}}</td>
                                        <td v-else> N/A</td>

                                        <td v-if="record.type != undefined && record.type != ''">{{record.type}}</td>
                                        <td v-else> N/A</td>

                                        <td v-if="record.relationship != undefined && record.relationship != ''">{{record.relationship}}</td>
                                        <td v-else> N/A</td>

                                        <td v-if="record.address_1 != undefined && record.address_1 != ''">{{record.address_1}}</td>
                                        <td v-else> N/A</td>

                                        <td v-if="record.address_2 != undefined && record.address_2 != ''">{{record.address_2}}</td>
                                        <td v-else> N/A</td>

                                        <td v-if="record.city != undefined && record.city != ''">{{record.city}}</td>
                                        <td v-else> N/A</td>

                                        <td v-if="record.state != undefined && record.state != ''">{{record.state}}</td>
                                        <td v-else> N/A</td>

                                        <td v-if="record.pin_code != undefined && record.pin_code != ''">{{record.pin_code}}</td>
                                        <td v-else> N/A</td>

                                        <td v-if="record.country != undefined && record.country != ''">{{record.country}}</td>
                                        <td v-else> N/A</td>

                                    </tr>

                                    </tbody>

                                    <tbody v-else>

                                    <h5 class="text-center"> No Executors Details Added</h5>

                                    </tbody>

                                </table>

                            </div>

                        </div>

                    </div>

                </div>

                <!-- Tragedy Clause -->

                <div class="row">

                    <div class="col-lg-12 col-md-12 col-sm-12 col-12">

                        <div class="row">

                            <div class="col-lg-11 col-md-11 col-sm-10 col-9">

                                <div class="will-summary-subtitle">

                                    <h4><span>9</span>Tragedy Clause</h4>

                                </div>

                            </div>

                            <div class="col-lg-1 col-md-1 col-sm-12 col-3 text-right">


                                <el-button type="text" title="Update Details" v-if="edit != undefined && edit == true"
                                           class="btn btn-sm btn-edit" @click="editRecord('9')"><a class="fa fa-pencil"></a></el-button>


                            </div>

                        </div>

                        <div class="row"
                             v-if="data.tradegy_info != undefined && data.tradegy_info.add_tradegy == 'Yes' && data.tradegy_info.records.length > 0">

                            <div class="col-lg-6 col-md-12 col-sm-12 col-12"
                                 v-if="data.tradegy_info != undefined && data.tradegy_info.add_tradegy == 'Yes' && data.tradegy_info.records.length > 0"
                                 v-for="(record,index) in data.tradegy_info.records">

                                <div class="summary-card estate-summary">


                                    <div class="form-row">

                                        <div class="form-group col-12 col-md-6 col-sm-6 col-lg-6">

                                            <span>{{record.title}}. {{record.first_name}} {{record.middle_name}} {{record.last_name}} </span>

                                        </div>

                                        <div class="form-group col-12 col-md-6 col-sm-6 col-lg-6">

                                            <span>{{record.relationship}}</span>

                                        </div>

                                        <!--                                    <div class="form-group col-12 col-md-4 col-sm-4 col-lg-4">-->

                                        <!--                                        <span>745821452111</span>-->

                                        <!--                                    </div>-->

                                    </div>

                                    <div class="form-row">

                                        <div class="form-group col-12 col-md-12 col-sm-12 col-lg-12">

                                            <span>{{ makeAddress(record.address_1,record.address_2,record.state,record.city,record.pin_code) }}</span>

                                        </div>

                                    </div>

                                    <div class="form-row">

                                        <div class="form-group col-12 col-md-6 col-sm-6 col-lg-6" v-if="checkAssociated(record,'Movable')">

                                            <h6>Movable</h6>

                                            <hr>

                                            <ul>

                                                <li v-if="info.asset_type != 'Land' && info.asset_type != 'House' && info.asset_type != 'Commercial Property' && info.asset_type != 'Other Immovable' && record.properties[info.id]!=undefined && record.properties[info.id] > 0"
                                                    v-for="(info,index) in data.property_info.records">

                                                    <span class="float-left">{{asset_types[info.asset_type]}}</span>

                                                    <span class="float-right">{{ record.properties[info.id] }}%</span>

                                                </li>


                                            </ul>

                                        </div>

                                        <div class="form-group col-12 col-md-6 col-sm-6 col-lg-6" v-if="checkAssociated(record,'Immovable')">

                                            <h6>Immovable</h6>

                                            <hr>

                                            <ul>

                                                <li v-if="(info.asset_type == 'Land' || info.asset_type == 'House' || info.asset_type == 'Commercial Property' || info.asset_type == 'Other Immovable') && record.properties[info.id]!=undefined && record.properties[info.id] > 0 && data.property_info.records != undefined"
                                                    v-for="(info,index) in data.property_info.records">

                                                    <span class="float-left">{{asset_types[info.asset_type]}}</span>

                                                    <span class="float-right">{{ record.properties[info.id] }}%</span>

                                                </li>

                                            </ul>

                                        </div>

                                    </div>

                                </div>

                            </div>

                        </div>

                        <div class="row" v-else>

                            <div class="col-lg-12 col-md-12 col-sm-12 col-12">

                                <div class="summary-card estate-summary" style="min-height: 4rem;">
                                    <h5 class="text-center"> No Tragedy Clause Added</h5>
                                </div>

                            </div>
                        </div>

                    </div>

                </div>

                <!-- Funeral Clause -->

                <div class="row">

                    <div class="col-lg-12 col-md-12 col-sm-12 col-12">

                        <div class="row">

                            <div class="col-lg-11 col-md-11 col-sm-10 col-9">

                                <div class="will-summary-subtitle">

                                    <h4><span>10</span>Funeral &amp; Message</h4>

                                </div>

                            </div>

                            <div class="col-lg-1 col-md-1 col-sm-12 col-3 text-right">


                                <el-button type="text" title="Update Details" v-if="edit != undefined && edit == true"
                                           class="btn btn-sm btn-edit" @click="editRecord('10')"><a class="fa fa-pencil"></a></el-button>


                            </div>

                        </div>

                        <div class="row">

                            <div class="col-lg-6 col-md-12 col-sm-12 col-12"
                                 v-if="data.funeral_info != undefined && data.funeral_info.add_funeral != undefined && data.funeral_info.add_funeral == 'Yes' ">

                                <div class="summary-card funeral-summary">

                                    <h6>Funeral</h6>

                                    <h6>What would you like to do with your body?</h6>

                                    <p>{{data.funeral_info.funeral}}</p>

                                </div>

                            </div>

                            <div class="col-lg-6 col-md-12 col-sm-12 col-12"
                                 v-if="data.funeral_info != undefined && data.funeral_info.add_funeral != undefined && data.funeral_info.add_funeral == 'Yes' && data.funeral_info.funeral !== 'Let my Family Decide'">

                                <div class="summary-card funeral-summary">

                                    <h6>Funeral</h6>

                                    <h6><template v-if="data.funeral_info.funeral === 'Buried in a Cemetery'">Which Cemetery do you like to be buried?</template>
                                        <template v-else-if="data.funeral_info.funeral === 'Donated to Science'">Which instituation do you wish to donate your body?</template>
                                        <template v-else-if="data.funeral_info.funeral === 'Cremated'">Where do you want your body to be Cremated?</template>
                                        <template v-else-if="data.funeral_info.funeral === 'Other'">What would you like to do with your body?</template></h6>


                                    <p v-if="data.funeral_info.body_part != undefined && data.funeral_info.body_part != ''">
                                        {{data.funeral_info.body_part}}</p>
                                    <p v-else>N/A</p>

                                    <h6>
                                        <template v-if="data.funeral_info.funeral === 'Buried in a Cemetery'">Any additional wishes about your resting place?</template>
                                        <template v-else-if="data.funeral_info.funeral === 'Donated to Science'">Any instruction to the instutation?</template>
                                        <template v-else-if="data.funeral_info.funeral === 'Cremated'">What do you wish to do with the ash?</template>
                                        <!--<template v-else-if="data.funeral_info.funeral === 'Other'">Any additional wishes about your resting place?</template>-->
                                    </h6>

                                    <template v-if="data.funeral_info.funeral !== 'Other'">
                                        <p v-if="data.funeral_info.description != undefined && data.funeral_info.description != ''">
                                            {{data.funeral_info.description}}</p>
                                        <p v-else>N/A</p>
                                    </template>

                                </div>

                            </div>

                            <div class="col-lg-12 col-md-12 col-sm-12 col-12" v-if="data.funeral_info != undefined && (data.funeral_info.final_detail != undefined && data.funeral_info.final_detail != '')">

                                <div class="summary-card funeral-summary">

                                    <h6>Farewell Message</h6>

                                    <p v-if="data.funeral_info.final_detail != undefined && data.funeral_info.final_detail != ''">
                                    <pre class="text">{{data.funeral_info.final_detail}}</pre> </p>

                                    <p v-else>N/A</p>

                                </div>

                            </div>

                            
                            


                           <!-- <div class="col-lg-6 col-md-12 col-sm-12 col-12"
                                 v-if="data.funeral_info != undefined && data.funeral_info.add_final_message != undefined && data.funeral_info.add_final_message == 'Yes'">

                                <div class="summary-card funeral-summary">

                                    <h6>Additional Detail</h6>

                                    <p v-if="data.funeral_info.additional_wish != undefined && data.funeral_info.additional_wish != ''">
                                        {{data.funeral_info.additional_wish}}</p>
                                    <p v-else>N/A</p>

                                </div>

                            </div>-->

                            <div v-else class="col-lg-12 col-md-12 col-sm-12 col-12">
                                <div class="summary-card funeral-summary" style="min-height: 4rem;">
                                    <h5 class="text-center"> No Farewell message added</h5>
                                </div>
                            </div>

                            
                            
                            

                        </div>

                        <div class="row">

                            <div class="col-lg-11 col-md-11 col-sm-10 col-9">

                                <div class="will-summary-subtitle">

                                    <h4><span>11</span>Clarity Message</h4>

                                </div>

                            </div>

                            <div class="col-lg-1 col-md-1 col-sm-12 col-3 text-right">


                                <el-button type="text" title="Update Details" v-if="edit != undefined && edit == true"
                                           class="btn btn-sm btn-edit" @click="editRecord('10')"><a class="fa fa-pencil"></a></el-button>


                            </div>
                            <!-- <div class="col-lg-11 col-md-11 col-sm-10 col-9">

                                <div class="will-summary-subtitle">

                                    <h4><span>11</span>Clarity Message</h4>
                                        <el-button type="text" title="Update Details" v-if="edit != undefined && edit == true"
                                           class="btn btn-sm btn-edit" @click="editRecord('10')"><a class="fa fa-pencil"></a></el-button>
                                </div>
                            </div> -->

                            <div class="col-lg-12 col-md-12 col-sm-12 col-12">
                                <div class="summary-card funeral-summary" style="min-height: 4rem;">
  <h5 v-if="data.funeral_info && data.funeral_info.summary_help || 'No Clarity Message Added' !== 'No Clarity Message Added'">
    Do you have any additional information which you want us to consider while reviewing your Will?
  </h5>
  <p v-if="data.funeral_info && data.funeral_info.summary_help" class="text-left summary_help_text">
    {{ data.funeral_info.summary_help }}
  </p>
  <h5 v-else class="text-center">No Clarity Message Added</h5>
</div>

                            </div> 
                        </div>

                  
                    </div>

                </div>
                
            
            
                <table style="min-width:78%">
                        <tr>
                            <td v-if="fromStep">
                                <!-- <div class="btn-go-back mb-3 gotoBackSummery" @click="submitWillBack()">
                                        <a  style="color:#bb2044;s"><i  style="min-width: inherit; vertical-align: bottom; "></i><b>Back</b></a>
                                </div> -->
                                <button type="button" class="el-button btn btn-prev active btn-nex-prev el-button--default" @click="submitWillBack()"><span><i class="fa fa-angle-left"></i></span></button>
                            </td>
                            <td>
                                <div class="row" v-if="submit != undefined && submit == true && edit != undefined && edit == true">
                                     <div class="col-lg-12 col-md-12 col-sm-12 col-12 text-center mt-3 mb-3">
                                        <el-button class="btn btn-red hvr-bounce-to-right" @click="submitWill()">SUBMIT THIS WILL</el-button>
                                    </div>
                                </div>
                            </td>
                         </tr>
                </table>
            </div>
        </div>
    </div>


</template>

<script>
    import Form from 'form-backend-validation';
    import Common from '../mixins/commonMethod';
    import {MessageBox} from "element-ui";

    export default {
        name: "WillSummary",
        props: ['summary', 'id', 'edit', 'submit', 'asset_types'],
        mixins: [Common],
        data() {
            let fromSteps =  null;
            if(localStorage.getItem("steps") == 'true'){
                fromSteps =  true;
            }else{
                fromSteps =  false;
            }
            localStorage.setItem('steps', null);
            return {
                loading: false,
                form: new Form(),
                loading: false,
                data: {},
                is_secondary: [],
                fromStep : fromSteps,
            }
        },
        methods: {
            checkAssociated(record, type){
                if (type == 'Movable') {
                    for (var i in this.data.property_info.records) {
                        console.log('hj1',this.data.property_info.records[i].asset_type);
                        // console.log(this.data.property_info.records[i].asset_type);
                        if (this.data.property_info.records[i].asset_type != 'Land' && this.data.property_info.records[i].asset_type != 'House' && this.data.property_info.records[i].asset_type != 'Other Immovable' &&  this.data.property_info.records[i].asset_type !='Commercial Property' && record.properties[this.data.property_info.records[i].id] != undefined && record.properties[this.data.property_info.records[i].id] > 0) {
                            console.log('hj2',record.properties[this.data.property_info.records[i].id]);
                            return true;
                            break;
                        }
                    }
                } else {
                    console.log("revord123", record, 'this.data.property_info.records', this.data.property_info.records);
                    for (var i in this.data.property_info.records) {
                        console.log('hj3',this.data.property_info.records[i].asset_type);
                        if ((this.data.property_info.records[i].asset_type == 'Land' || this.data.property_info.records[i].asset_type == 'House' || this.data.property_info.records[i].asset_type =='Other Immovable' ||  this.data.property_info.records[i].asset_type ||'Commercial Property') && record.properties[this.data.property_info.records[i].id] != undefined && record.properties[this.data.property_info.records[i].id] > 0) {
                            console.log('hj4',record.properties[this.data.property_info.records[i].id]);
                            return true;
                            break;
                        }
                    }
                }
                return false;
            },
            checkBeniciariesAssociated(record, type) {
                if (type == 'Primary') {
                    for (var i in this.data.beneficiary_info.beneficiaries) {
                        console.log('record',record);
                        console.log(record.primary_beneficiaries);
                        if (record.primary_beneficiaries != undefined && record.primary_beneficiaries[this.data.beneficiary_info.beneficiaries[i].id] != undefined && record.primary_beneficiaries[this.data.beneficiary_info.beneficiaries[i].id] > 0) {
                            return true;
                            break;
                        }
                    }
                } else {
                    for (var i in this.data.beneficiary_info.beneficiaries) {
                        if (record.secondary_beneficiaries != undefined && record.secondary_beneficiaries[this.data.beneficiary_info.beneficiaries[i].id] != undefined && record.secondary_beneficiaries[this.data.beneficiary_info.beneficiaries[i].id] > 0) {
                            return true;
                            break;
                        }
                    }
                }
                return false;
            },
            isSecondaryAllocate() {
                console.log('ff');
                this.loading = true;
                for (var index in this.data.property_info.records) {
                    var record = this.data.property_info.records[index];
                    console.log(record);
                    for (var col in this.data.beneficiary_info.beneficiaries) {
                        var id = this.data.beneficiary_info.beneficiaries[col];
                        if (record.beneficiaries[id] != undefined && record.beneficiaries[id] > 0 && this.data.beneficiary_info.beneficiaries[col].type == 'Secondary') {
                            // this.is_secondary[index] = true;
                            // this.is_secondary = _.merge(this.is_secondary, {[index]: true});
                        } else {
                            this.is_secondary.push({index: false});
                        }
                    }
                }
                console.log(this.is_secondary);

                this.loading = false;
            },
            submitWill() {
               MessageBox.confirm(
                "You are about to submit the Will for review. Once submitted, you will not be able to edit your Will. Please make sure all the details are correct in the 'Will Summary' before submitting. Select 'Yes' to Submit or 'No' to go back to the Will Summary page.",
                "Confirmation",
                {
                    confirmButtonText: "Yes",
                    cancelButtonText: "No",
                    type: "warning",
                }
                )
                .then(() => {
                    // User clicked 'Yes'
                    window.location = '/will/submit-will';
                })
                .catch(() => {
                    // User clicked 'No' or closed the dialog
                    this.$message({
                    type: 'info',
                    message: 'Submission cancelled.',
                    });
                    // You can add additional logic here if needed
                });
            },
            submitWillBack() {
                window.location = ('/will/form');
            },
            editRecord(step) {
                this.form = new Form({activeTab: step});
                if (this.id != undefined && this.id != null && this.id != 0) {
                    this.form.post(route('api.will.wills.savedata', {id: this.id}))
                        .then((response) => {
                            console.log('id', this.id);
                            this.loading = false;
                            if (response.errors == false) {
                                window.location = route('admin.will.will.edit', this.id);
                            }
                        })
                        .catch((error) => {
                            this.loading = false;
                        });
                } else {
                    this.form.post('/api/will/formData/add')
                        .then((response) => {
                            window.location = ('/will/form');
                        })
                        .catch((error) => {
                        });
                }
            },
            filteredItems(data, record){
                let arr = data.filter(info => info.asset_type == 'Land' || info.asset_type == 'House' || info.asset_type == 'Commercial Property' || info.asset_type == 'Other Immovable');
                return arr.filter(element => (record.properties[element.id] != 0 && record.properties[element.id] != undefined))
            }
        },
        mounted() {
            this.loading = true;
            if (this.id != undefined && this.id > 0 && this.summary == undefined) {
                axios.get(route('api.will.wills.getSummary', this.id))
                    .then((response) => {
                        this.loading = false;
                        this.data = response.data.data;
                        this.asset_types = response.data.asset_types;
                        console.log('liability data1', this.data.liability_info);
                    });
            } else {
                this.data = this.summary;
                console.log('liability data1', this.data.liability_info);
                this.loading = false;
            }
            console.log('data', this.data);
            console.log('edit', this.edit);

            // this.isSecondaryAllocate();
        },
    }
</script>

<style>
    .will-summary .el-button--text {
        background: #fff !important;
        color: #bb2044 !important;
    }

    .text {
        font-family: "Source Sans Pro",-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol" !important;
        font-size: 100.5% !important;
    }
    
    .form_list{
        display: flex !important;
    }

    .form_list_left{
        word-wrap: break-word;
        margin-right: 5px;
        width: 80%;
    }

</style>
