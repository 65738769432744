<template>
	<el-form
		v-loading="loading"
		:ref="formName"
		:model="propertyForm"
		label-width="120px"
		label-position="top"
	>
		<div class="row">
			<div class="col-sm-12 col-md-12 col-lg-8">
				<div class="wizard-des">
					<p>
						All the assets mentioned in this should be fully owned by you.
						Indian courts do not have jurisdiction outside India and hence
						it is advisable not to add any assets outside India. You will
						need to write a separate WILL for your assets outside India
						depending on the rules of the country the asset is in. Please
						make sure that the beneficiary in your WILL is matching the
						beneficiary mentioned in the respective account.
					</p>
				</div>

				<div class="tab-card" v-if="property_info.add_estate == 'Yes'">
					<h2 class="will-create-title">Estate Details</h2>

					<div class="form-row">
						<div class="form-group col-sm-6 col-md-6 col-lg-6">
							<el-form-item
								label="Asset Type"
								prop="asset_type"
								:rules="getFieldRule('Address 2')"
							>
								<el-select
									v-model="propertyForm.asset_type"
									@change="clearErrors()"
								>
									<el-option
										value="Bank Account"
										label="Bank Account"
									></el-option>
									<el-option
										value="Bank Locker"
										label="Bank Locker"
									></el-option>
									<el-option
										value="Fixed Deposit"
										label="Fixed Deposit"
									></el-option>
									<el-option
										value="Life Insurance"
										label="Life Insurance"
									></el-option>
									<el-option
										value="Pension"
										label="Pension"
									></el-option>
									<el-option
										value="SharesStock"
										label="Stocks & Shares"
									></el-option>
									<el-option
										value="House"
										label="House/Flat"
									></el-option>
									<el-option value="Land" label="Land"></el-option>
									<el-option
										value="Commercial Property"
										label="Commercial Property"
									></el-option>
									<el-option value="PPF" label="PPF"></el-option>
									<el-option
										value="Physical Shares"
										label="Physical Shares"
									></el-option>
									<el-option
										value="Bond/Debentures"
										label="Bond/Debentures"
									></el-option>
									<el-option
										value="Vehicle"
										label="Vehicle"
									></el-option>
									<el-option
										value="Gratuity"
										label="Gratuity"
									></el-option>
									<el-option
										value="Wealth Management Account"
										label="Wealth Management Account"
									></el-option>
									<el-option
										value="Mutual Fund"
										label="Mutual Fund"
									></el-option>
									<el-option
										value="Demat Account"
										label="Demat Account"
									></el-option>
									<el-option
										value="Digital Assets"
										label="Digital Assets"
									></el-option>
									<el-option
										value="Jewellery"
										label="Jewellery"
									></el-option>
									<el-option
										value="Intellectual property"
										label="Intellectual property"
									></el-option>
									<el-option
										value="Crypto Currency"
										label="Crypto Currency"
									></el-option>
									<el-option
										value="Other Movable"
										label="Other Movable"
									></el-option>
									<el-option
										value="Other Immovable"
										label="Other Immovable"
									></el-option>
								</el-select>
							</el-form-item>
						</div>
						<div class="form-group col-sm-6 col-md-6 col-lg-6">
							<el-form-item
								label="Name of the issuer"
								prop="asset_name_label"
								:rules="
									getFieldRule(
										'Name of the issuer',
										'Name of the issuer',
										'alphanumericwithspecialchars'
									)
								"
								v-if="propertyForm.asset_type == 'Physical Shares'"
							>
								<el-input
									@input="
										(value) => (propertyForm.asset_name_label = value)
									"
									:value="propertyForm.asset_name_label | capitalize"
									type="text"
									placeholder="Name of the share holder company"
								></el-input>
								<div
									v-if="form.errors.has('asset_name_label')"
									class="el-form-item__error"
									v-text="form.errors.first('asset_name_label')"
								></div>
							</el-form-item>
							<el-form-item
								label="PPF Provider"
								prop="asset_name_label"
								:rules="
									getFieldRule(
										'PPF Provider',
										'PPF Provider',
										'alphanumericwithspecialchars'
									)
								"
								v-if="propertyForm.asset_type == 'PPF'"
							>
								<el-input
									@input="
										(value) => (propertyForm.asset_name_label = value)
									"
									:value="propertyForm.asset_name_label | capitalize"
									type="text"
									placeholder="Name of the PPF provider"
								></el-input>
								<div
									v-if="form.errors.has('asset_name_label')"
									class="el-form-item__error"
									v-text="form.errors.first('asset_name_label')"
								></div>
							</el-form-item>
							<el-form-item
								label="Demat provider"
								prop="asset_name_label"
								:rules="
									getFieldRule(
										'Demat provider',
										'Demat provider',
										'alphanumericwithspecialchars'
									)
								"
								v-if="propertyForm.asset_type == 'Demat Account'"
							>
								<el-input
									@input="
										(value) => (propertyForm.asset_name_label = value)
									"
									:value="propertyForm.asset_name_label | capitalize"
									type="text"
									placeholder="Name of the Demat account provider"
								></el-input>
								<div
									v-if="form.errors.has('asset_name_label')"
									class="el-form-item__error"
									v-text="form.errors.first('asset_name_label')"
								></div>
							</el-form-item>

							<el-form-item
								label="Bond/Debentures Provider"
								prop="asset_name_label"
								:rules="
									getFieldRule(
										'Bond/Debentures Provider',
										'Bond/Debentures Provider',
										'alphanumericwithspecialchars'
									)
								"
								v-if="propertyForm.asset_type == 'Bond/Debentures'"
							>
								<el-input
									@input="
										(value) => (propertyForm.asset_name_label = value)
									"
									:value="propertyForm.asset_name_label | capitalize"
									type="text"
									placeholder="Name of the issuing company"
								></el-input>
								<div
									v-if="form.errors.has('asset_name_label')"
									class="el-form-item__error"
									v-text="form.errors.first('asset_name_label')"
								></div>
							</el-form-item>

							<el-form-item
								label="Type of vehicle"
								prop="asset_name_label"
								:rules="
									getFieldRule(
										'Type of vehicle',
										'Type of vehicle',
										'alphanumericwithspecialchars'
									)
								"
								v-if="propertyForm.asset_type == 'Vehicle'"
							>
								<el-input
									@input="
										(value) => (propertyForm.asset_name_label = value)
									"
									:value="propertyForm.asset_name_label | capitalize"
									type="text"
									placeholder="Two-Wheeler/Car/Truck/Van"
								></el-input>
								<div
									v-if="form.errors.has('asset_name_label')"
									class="el-form-item__error"
									v-text="form.errors.first('asset_name_label')"
								></div>
							</el-form-item>

							<el-form-item
								label="Intellectual property Provider"
								prop="asset_name_label"
								:rules="
									getFieldRule(
										'Intellectual property Provider',
										'Intellectual property Provider',
										'alphanumericwithspecialchars'
									)
								"
								v-if="
									propertyForm.asset_type == 'Intellectual property'
								"
							>
								<el-input
									@input="
										(value) => (propertyForm.asset_name_label = value)
									"
									:value="propertyForm.asset_name_label | capitalize"
									type="text"
									placeholder=" Trade mark/Copyright/Patent/ Design"
								></el-input>
								<div
									v-if="form.errors.has('asset_name_label')"
									class="el-form-item__error"
									v-text="form.errors.first('asset_name_label')"
								></div>
							</el-form-item>
							<el-form-item
								label="Digital Assets"
								prop="asset_name_label"
								:rules="
									getFieldRule(
										'Digital Assets Provider',
										'Digital Assets Provider ',
										'alphanumericwithspecialchars'
									)
								"
								v-if="propertyForm.asset_type == 'Digital Assets'"
							>
								<el-input
									@input="
										(value) => (propertyForm.asset_name_label = value)
									"
									:value="propertyForm.asset_name_label | capitalize"
									type="text"
									placeholder=" PDF/Video/Audio/images/Graphics/Presentation/Animation"
								></el-input>
								<div
									v-if="form.errors.has('asset_name_label')"
									class="el-form-item__error"
									v-text="form.errors.first('asset_name_label')"
								></div>
							</el-form-item>
							<el-form-item
								label="Jewellery"
								prop="asset_name_label"
								:rules="
									getFieldRule(
										'Jewellery type',
										'Jewellery type ',
										'alphanumericwithspecialchars'
									)
								"
								v-if="propertyForm.asset_type == 'Jewellery'"
							>
								<el-input
									@input="
										(value) => (propertyForm.asset_name_label = value)
									"
									:value="propertyForm.asset_name_label | capitalize"
									type="text"
									placeholder="Gold necklace/Gold coin/ Silver pendant "
								></el-input>
								<div
									v-if="form.errors.has('asset_name_label')"
									class="el-form-item__error"
									v-text="form.errors.first('asset_name_label')"
								></div>
							</el-form-item>

							<el-form-item
								label="Gratuity Provider"
								prop="asset_name_label"
								:rules="
									getFieldRule(
										'Gratuity Provider',
										'Gratuity Provider',
										'alphanumericwithspecialchars'
									)
								"
								v-if="propertyForm.asset_type == 'Gratuity'"
							>
								<el-input
									@input="
										(value) => (propertyForm.asset_name_label = value)
									"
									:value="propertyForm.asset_name_label | capitalize"
									type="text"
									placeholder=" Name of the Gratuity provider"
								></el-input>
								<div
									v-if="form.errors.has('asset_name_label')"
									class="el-form-item__error"
									v-text="form.errors.first('asset_name_label')"
								></div>
							</el-form-item>
							<el-form-item
								label="Mutual Fund Provider"
								prop="asset_name_label"
								:rules="
									getFieldRule(
										'Mutualfund Provider',
										'Mutual Fund Provider',
										'alphanumericwithspecialchars'
									)
								"
								v-if="propertyForm.asset_type == 'Mutual Fund'"
							>
								<el-input
									@input="
										(value) => (propertyForm.asset_name_label = value)
									"
									:value="propertyForm.asset_name_label | capitalize"
									type="text"
									placeholder="Name of the Mutual Fund Provider"
								></el-input>
								<div
									v-if="form.errors.has('asset_name_label')"
									class="el-form-item__error"
									v-text="form.errors.first('asset_name_label')"
								></div>
							</el-form-item>

							<el-form-item
								label="Wealth Management Account Provider"
								prop="asset_name_label"
								:rules="
									getFieldRule(
										'Wealth Management Account Provider',
										'Wealth Management Account Provider',
										'alphanumericwithspecialchars'
									)
								"
								v-if="
									propertyForm.asset_type ==
										'Wealth Management Account'
								"
							>
								<el-input
									@input="
										(value) => (propertyForm.asset_name_label = value)
									"
									:value="propertyForm.asset_name_label | capitalize"
									type="text"
									placeholder="Name of the Wealth Management Account Provider"
								></el-input>
								<div
									v-if="form.errors.has('asset_name_label')"
									class="el-form-item__error"
									v-text="form.errors.first('asset_name_label')"
								></div>
							</el-form-item>

							<el-form-item
								label="Name of the Exchange or Wallet"
								prop="asset_name_label"
								:rules="
									getFieldRule(
										'Name of the Exchange or Wallet',
										'Name of the Exchange or Wallet',
										'alphanumericwithspecialchars'
									)
								"
								v-if="propertyForm.asset_type == 'Crypto Currency'"
							>
								<el-input
									@input="
										(value) => (propertyForm.asset_name_label = value)
									"
									:value="propertyForm.asset_name_label | capitalize"
									type="text"
									placeholder="Coinbase/Kraken/Ledger Nano/Trade mark/Copyright/patent/Design"
								></el-input>
								<div
									v-if="form.errors.has('asset_name_label')"
									class="el-form-item__error"
									v-text="form.errors.first('asset_name_label')"
								></div>
							</el-form-item>

							<el-form-item
								label="Bank Name"
								prop="asset_name_label"
								:rules="getFieldRule('Bank Name', 'Bank Name', 'alphanumericwithspecialchars')"
								v-if="
									propertyForm.asset_type == 'Bank Account' ||
										propertyForm.asset_type == 'Bank Locker' ||
										propertyForm.asset_type == 'Fixed Deposit'
								"
							>
								<el-input
									@input="
										(value) => (propertyForm.asset_name_label = value)
									"
									:value="propertyForm.asset_name_label | capitalize"
									type="text"
									placeholder="Bank Name"
								></el-input>
								<div
									v-if="form.errors.has('asset_name_label')"
									class="el-form-item__error"
									v-text="form.errors.first('asset_name_label')"
								></div>
							</el-form-item>
							<el-form-item
								label="Insurance Company"
								prop="asset_name_label"
								:rules="getFieldRule('Insurance Company', 'Insurance Company', 'alphanumericwithspecialchars')"
								v-else-if="propertyForm.asset_type == 'Life Insurance'"
							>
								<el-input
									type="text"
									@input="
										(value) => (propertyForm.asset_name_label = value)
									"
									:value="propertyForm.asset_name_label | capitalize"
									placeholder="Insurance Company"
								></el-input>
								<div
									v-if="form.errors.has('asset_name_label')"
									class="el-form-item__error"
									v-text="form.errors.first('asset_name_label')"
								></div>
							</el-form-item>
							<el-form-item
								label="Pension Provider"
								prop="asset_name_label"
								:rules="getFieldRule('Pension Provider', 'Pension Provider', 'alphanumericwithspecialchars')"
								v-else-if="propertyForm.asset_type == 'Pension'"
							>
								<el-input
									type="text"
									@input="
										(value) => (propertyForm.asset_name_label = value)
									"
									:value="propertyForm.asset_name_label | capitalize"
									placeholder="Pension Provider"
								></el-input>
								<div
									v-if="form.errors.has('asset_name_label')"
									class="el-form-item__error"
									v-text="form.errors.first('asset_name_label')"
								></div>
							</el-form-item>
							<el-form-item
								label="Company/Broker holding your share"
								prop="asset_name_label"
								:rules="
									getFieldRule('Company/Broker holding your share', 'Company/Broker holding your share', 'alphanumericwithspecialchars')
								"
								v-else-if="propertyForm.asset_type == 'SharesStock'"
							>
								<el-input
									type="text"
									@input="
										(value) => (propertyForm.asset_name_label = value)
									"
									:value="propertyForm.asset_name_label | capitalize"
									placeholder="Company/Broker holding your share"
								></el-input>
								<div
									v-if="form.errors.has('asset_name_label')"
									class="el-form-item__error"
									v-text="form.errors.first('asset_name_label')"
								></div>
							</el-form-item>
							<el-form-item
								label="Description of the Property"
								prop="asset_name_label"
								:rules="getFieldRule('Description', 'Description', 'alphanumericwithspecialchars')"
								v-else-if="propertyForm.asset_type == 'Land'"
							>
								<el-input
									type="text"
									@input="
										(value) => (propertyForm.asset_name_label = value)
									"
									:value="propertyForm.asset_name_label | capitalize"
									placeholder="2 acre of land"
								></el-input>
								<div
									v-if="form.errors.has('asset_name_label')"
									class="el-form-item__error"
									v-text="form.errors.first('asset_name_label')"
								></div>
							</el-form-item>
							<el-form-item
								label="Description of the Property"
								prop="asset_name_label"
								:rules="getFieldRule('Description', 'Description', 'alphanumericwithspecialchars')"
								v-else-if="
									propertyForm.asset_type == 'House' ||
										propertyForm.asset_type == 'Commercial Property'
								"
							>
								<el-input
									type="text"
									@input="
										(value) => (propertyForm.asset_name_label = value)
									"
									:value="propertyForm.asset_name_label | capitalize"
									placeholder="2BHK Flat or 2 acre of land"
								></el-input>
								<div
									v-if="form.errors.has('asset_name_label')"
									class="el-form-item__error"
									v-text="form.errors.first('asset_name_label')"
								></div>
							</el-form-item>
							<el-form-item
								label="Detail"
								prop="asset_name_label"
								:rules="getFieldRule('Detail', 'Detail', 'alphanumericwithspecialchars')"
								v-else-if="
									propertyForm.asset_type == 'Other Movable' ||
										propertyForm.asset_type == 'Other Immovable'
								"
							>
								<el-input
									type="text"
									@input="
										(value) => (propertyForm.asset_name_label = value)
									"
									:value="propertyForm.asset_name_label | capitalize"
									placeholder="Detail"
								></el-input>
								<div
									v-if="form.errors.has('asset_name_label')"
									class="el-form-item__error"
									v-text="form.errors.first('asset_name_label')"
								></div>
							</el-form-item>
						</div>
						<div
							class="form-group col-sm-6 col-md-6 col-lg-6"
							v-if="
								propertyForm.asset_type == 'Physical Shares' ||
									propertyForm.asset_type == 'Demat Account' ||
									propertyForm.asset_type == 'Bond/Debentures' ||
									propertyForm.asset_type == 'Vehicle' ||
									propertyForm.asset_type == 'Jewellery' ||
									propertyForm.asset_type == 'Weight' ||
									propertyForm.asset_type == 'Crypto Currency' ||
									propertyForm.asset_type ==
										'Wealth Management Account' ||
									propertyForm.asset_type == 'Digital Assets' ||
									propertyForm.asset_type == 'Intellectual property' ||
									propertyForm.asset_type == 'Mutual Fund' ||
									propertyForm.asset_type == 'Bank Account' ||
									propertyForm.asset_type == 'Bank Locker' ||
									propertyForm.asset_type == 'Life Insurance' ||
									propertyForm.asset_type == 'PPF' ||
									propertyForm.asset_type == 'Pension' ||
									propertyForm.asset_type == 'Fixed Deposit' ||
									propertyForm.asset_type == 'SharesStock' ||
									propertyForm.asset_type == 'Gratuity'
							"
						>
							<el-form-item
								label="Wealth Management Account Number"
								prop="WMA_ac_no_label"
								:rules="
									getFieldRule(
										'Wealth Management Account Number',
										'Wealth Management Account Number',
										'alphanumericwithspecialchars'
									)
								"
								v-if="
									propertyForm.asset_type ==
										'Wealth Management Account'
								"
							>
								<el-input
									@input="
										(value) => (propertyForm.WMA_ac_no_label = value)
									"
									:value="propertyForm.WMA_ac_no_label | capitalize"
									type="text"
									placeholder="Wealth Management Account Number"
								></el-input>
								<div
									v-if="form.errors.has('WMA_ac_no_label')"
									class="el-form-item__error"
									v-text="form.errors.first('WMA_ac_no_label')"
								></div>
							</el-form-item>

							<el-form-item
								label="Bond Certificate Number"
								prop="bond_ac_no_label"
								:rules="
									getFieldRule(
										'Bond Certificate Number',
										'Bond Certificate Number',
										'alphanumericwithspecialchars'
									)
								"
								v-if="propertyForm.asset_type == 'Bond/Debentures'"
							>
								<el-input
									@input="
										(value) => (propertyForm.bond_ac_no_label = value)
									"
									:value="propertyForm.bond_ac_no_label | capitalize"
									type="text"
									placeholder="Bond Account/Certificate number"
								></el-input>
								<div
									v-if="form.errors.has('bond_ac_no_label')"
									class="el-form-item__error"
									v-text="form.errors.first('bond_ac_no_label')"
								></div>
							</el-form-item>
							<el-form-item
								label="Share Certificate Number"
								prop="share_ac_no_label"
								:rules="
									getFieldRule(
										'Share Certificate Number',
										'Share Certificate Number',
										'alphanumericwithspecialchars'
									)
								"
								v-if="propertyForm.asset_type == 'Physical Shares'"
							>
								<el-input
									@input="
										(value) =>
											(propertyForm.share_ac_no_label = value)
									"
									:value="propertyForm.share_ac_no_label | capitalize"
									type="text"
									placeholder="Share Certificate Number"
								></el-input>
								<div
									v-if="form.errors.has('share_ac_no_label')"
									class="el-form-item__error"
									v-text="form.errors.first('share_ac_no_label')"
								></div>
							</el-form-item>
							<el-form-item
								label="Vehicle Description"
								prop="vehicle_name_label"
								:rules="
									getFieldRule(
										'Vehicle Description',
										'Vehicle Description',
										'alphanumericwithspecialchars'
									)
								"
								v-if="propertyForm.asset_type == 'Vehicle'"
							>
								<el-input
									@input="
										(value) =>
											(propertyForm.vehicle_name_label = value)
									"
									:value="propertyForm.vehicle_name_label | capitalize"
									type="text"
									placeholder="Honda Civic/Bajaj Pulsar"
								></el-input>
								<div
									v-if="form.errors.has('vehicle_name_label')"
									class="el-form-item__error"
									v-text="form.errors.first('vehicle_name_label')"
								></div>
							</el-form-item>

							<el-form-item
								label="Account Name/Number"
								prop="crypto_currency_ac_no_label"
								:rules="
									getFieldRule(
										'Account Name/Number',
										'Account Name/Number',
										'alphanumericwithspecialchars'
									)
								"
								v-if="propertyForm.asset_type == 'Crypto Currency'"
							>
								<el-input
									@input="
										(value) =>
											(propertyForm.crypto_currency_ac_no_label = value)
									"
									:value="
										propertyForm.crypto_currency_ac_no_label
											| capitalize
									"
									type="text"
									placeholder="Account Number"
								></el-input>
								<div
									v-if="form.errors.has('crypto_currency_ac_no_label')"
									class="el-form-item__error"
									v-text="
										form.errors.first('crypto_currency_ac_no_label')
									"
								></div>
							</el-form-item>
							<el-form-item
								label=" Mutual Fund Account Number"
								prop="mutual_fund_ac_no_label"
								:rules="
									getFieldRule(
										'Account Number',
										'Mutual Fund Account Number',
										'alphanumericwithspecialchars'
									)
								"
								v-if="propertyForm.asset_type == 'Mutual Fund'"
							>
								<el-input
									@input="
										(value) =>
											(propertyForm.mutual_fund_ac_no_label = value)
									"
									:value="
										propertyForm.mutual_fund_ac_no_label | capitalize
									"
									type="text"
									placeholder="Mutual Fund Account Number"
								></el-input>
								<div
									v-if="form.errors.has('mutual_fund_ac_no_label')"
									class="el-form-item__error"
									v-text="form.errors.first('mutual_fund_ac_no_label')"
								></div>
							</el-form-item>
							<el-form-item
								label="Registration Number"
								prop="Intellectual_property_label"
								:rules="
									getFieldRule(
										'Account Number',
										'Intellectual property Account Number',
										'alphanumericwithspecialchars'
									)
								"
								v-if="
									propertyForm.asset_type == 'Intellectual property'
								"
							>
								<el-input
									@input="
										(value) =>
											(propertyForm.Intellectual_property_label = value)
									"
									:value="
										propertyForm.Intellectual_property_label
											| capitalize
									"
									type="text"
									placeholder=" Patent/Trademark Reg Number"
								></el-input>
								<div
									v-if="form.errors.has('Intellectual_property_label')"
									class="el-form-item__error"
									v-text="
										form.errors.first('Intellectual_property_label')
									"
								></div>
							</el-form-item>

							<el-form-item
								label="Description"
								prop="DigitalAssets_property_label"
								:rules="
									getFieldRule(
										'Account Number',
										'Digital Assets Account Number',
										'alphanumericwithspecialchars'
									)
								"
								v-if="propertyForm.asset_type == 'Digital Assets'"
							>
								<el-input
									@input="
										(value) =>
											(propertyForm.DigitalAssets_property_label = value)
									"
									:value="
										propertyForm.DigitalAssets_property_label
											| capitalize
									"
									type="text"
									placeholder=" "
								></el-input>
								<div
									v-if="
										form.errors.has('DigitalAssets_property_label')
									"
									class="el-form-item__error"
									v-text="
										form.errors.first('DigitalAssets_property_label')
									"
								></div>
							</el-form-item>
							<el-form-item
								label="Description"
								prop="Jewellery_property_label"
								:rules="
									getFieldRule(
										'Jewellery details',
										'Jewellery details',
										'alphanumericwithspecialchars'
									)
								"
								v-if="propertyForm.asset_type == 'Jewellery'"
							>
								<el-input
									@input="
										(value) =>
											(propertyForm.Jewellery_property_label = value)
									"
									:value="
										propertyForm.Jewellery_property_label | capitalize
									"
									type="text"
									placeholder="Pearl necklace"
								></el-input>
								<div
									v-if="form.errors.has('Jewellery_property_label')"
									class="el-form-item__error"
									v-text="
										form.errors.first('Jewellery_property_label')
									"
								></div>
							</el-form-item>

							<el-form-item
								label="Account Number"
								prop="asset_ac_no_label"
								:rules="getFieldRule('Account Number')"
								v-if="
									propertyForm.asset_type == 'Bank Account' ||
										propertyForm.asset_type == 'Bank Locker' ||
										propertyForm.asset_type == 'Fixed Deposit' ||
										propertyForm.asset_type == 'SharesStock'
								"
							>
								<el-input
									type="text"
									v-model="propertyForm.asset_ac_no_label"
									placeholder="Account Number"
								></el-input>
								<div
									v-if="form.errors.has('asset_ac_no_label')"
									class="el-form-item__error"
									v-text="form.errors.first('asset_ac_no_label')"
								></div>
							</el-form-item>
							<el-form-item
								label="Policy Number"
								prop="asset_ac_no_label"
								:rules="getFieldRule('Policy Number')"
								v-if="propertyForm.asset_type == 'Life Insurance'"
							>
								<el-input
									type="text"
									v-model="propertyForm.asset_ac_no_label"
									placeholder="Policy Number"
								></el-input>
								<div
									v-if="form.errors.has('asset_ac_no_label')"
									class="el-form-item__error"
									v-text="form.errors.first('asset_ac_no_label')"
								></div>
							</el-form-item>
							<el-form-item
								label="Pension Account Number"
								prop="asset_ac_no_label"
								:rules="getFieldRule('Pension Account Number')"
								v-if="propertyForm.asset_type == 'Pension'"
							>
								<el-input
									type="text"
									v-model="propertyForm.asset_ac_no_label"
									placeholder="Pension Account Number"
								></el-input>
								<div
									v-if="form.errors.has('asset_ac_no_label')"
									class="el-form-item__error"
									v-text="form.errors.first('asset_ac_no_label')"
								></div>
							</el-form-item>
							<el-form-item
								label="PPF Account Number"
								prop="ppf_ac_no_label"
								:rules="
									getFieldRule(
										'PPF Account Number',
										'PPF Account Numbers',
										'alphanumericwithspecialchars'
									)
								"
								v-if="propertyForm.asset_type == 'PPF'"
							>
								<el-input
									@input="
										(value) => (propertyForm.ppf_ac_no_label = value)
									"
									:value="propertyForm.ppf_ac_no_label | capitalize"
									type="text"
									placeholder="PPF Account Number"
								></el-input>
								<div
									v-if="form.errors.has('ppf_ac_no_label')"
									class="el-form-item__error"
									v-text="form.errors.first('ppf_ac_no_label')"
								></div>
							</el-form-item>
							<el-form-item
								label="Account Number"
								prop="gratuity_ac_no_label"
								:rules="
									getFieldRule(
										'Gratuity Account Number',
										'Gratuity Account Number',
										'alphanumericwithspecialchars'
									)
								"
								v-if="propertyForm.asset_type == 'Gratuity'"
							>
								<el-input
									@input="
										(value) =>
											(propertyForm.gratuity_ac_no_label = value)
									"
									:value="
										propertyForm.gratuity_ac_no_label | capitalize
									"
									type="text"
									placeholder="Gratuity Account Number"
								></el-input>
								<div
									v-if="form.errors.has('gratuity_ac_no_label')"
									class="el-form-item__error"
									v-text="form.errors.first('gratuity_ac_no_label')"
								></div>
							</el-form-item>

							<el-form-item
								label="Demat Account number"
								prop="demat_ac_no_label"
								:rules="
									getFieldRule(
										'Demat Account Number',
										'Demat Account Number',
										'alphanumericwithspecialchars'
									)
								"
								v-if="propertyForm.asset_type == 'Demat Account'"
							>
								<el-input
									@input="
										(value) =>
											(propertyForm.demat_ac_no_label = value)
									"
									:value="propertyForm.demat_ac_no_label | capitalize"
									type="text"
									placeholder="Demat Account Number"
								></el-input>
								<div
									v-if="form.errors.has('demat_ac_no_label')"
									class="el-form-item__error"
									v-text="form.errors.first('demat_ac_no_label')"
								></div>
							</el-form-item>
						</div>

						<div
							class="form-group col-sm-6 col-md-6 col-lg-6"
							v-if="
								propertyForm.asset_type == 'House' ||
									propertyForm.asset_type == 'Vehicle' ||
									propertyForm.asset_type == 'Bank Account' ||
									propertyForm.asset_type == 'Bank Locker' ||
									propertyForm.asset_type == 'Jewellery' ||
									propertyForm.asset_type == 'Land' ||
									propertyForm.asset_type == 'Fixed Deposit' ||
									propertyForm.asset_type == 'Commercial Property'
							"
						>
							<el-form-item
								label="Branch"
								prop="asset_detail_label"
								:rules="getFieldRule('Branch name')"
								v-if="
									propertyForm.asset_type == 'Bank Account' ||
										propertyForm.asset_type == 'Bank Locker' ||
										propertyForm.asset_type == 'Fixed Deposit'
								"
							>
								<el-input
									type="text"
									@input="
										(value) =>
											(propertyForm.asset_detail_label = value)
									"
									:value="propertyForm.asset_detail_label | capitalize"
									placeholder="Branch name"
								></el-input>
								<div
									v-if="form.errors.has('asset_detail_label')"
									class="el-form-item__error"
									v-text="form.errors.first('asset_detail_label')"
								></div>
							</el-form-item>
							<el-form-item
								label="Vehicle Registration Number"
								prop="asset_detail_label"
								:rules="
									getFieldRule(
										'Vehicle Registration Number',
										'Vehicle Registration Number',
										'alphanumericwithspecialchars'
									)
								"
								v-if="propertyForm.asset_type == 'Vehicle'"
							>
								<el-input
									@input="
										(value) =>
											(propertyForm.asset_detail_label = value)
									"
									:value="propertyForm.asset_detail_label | capitalize"
									type="text"
									placeholder="Registration Number like DL 8956"
								></el-input>
								<div
									v-if="form.errors.has('asset_detail_label')"
									class="el-form-item__error"
									v-text="form.errors.first('asset_detail_label')"
								></div>
							</el-form-item>
							<el-form-item
								label="Weight"
								prop="asset_detail_label"
								:rules="
									getFieldRule(
										'Weight of the Jewellery',
										'Weight of the Jewellery',
										'alphanumericwithspecialchars'
									)
								"
								v-if="propertyForm.asset_type == 'Jewellery'"
							>
								<el-input
									@input="
										(value) =>
											(propertyForm.asset_detail_label = value)
									"
									:value="propertyForm.asset_detail_label | capitalize"
									type="text"
									placeholder="Weight"
								></el-input>
								<div
									v-if="form.errors.has('asset_detail_label')"
									class="el-form-item__error"
									v-text="form.errors.first('asset_detail_label')"
								></div>
							</el-form-item>
							<el-form-item
								label="Address of the Property"
								prop="asset_detail_label"
								:rules="getFieldRule('Address')"
								v-if="
									propertyForm.asset_type == 'House' ||
										propertyForm.asset_type == 'Land' ||
										propertyForm.asset_type == 'Commercial Property'
								"
							>
								<el-input
									type="text"
									@input="
										(value) =>
											(propertyForm.asset_detail_label = value)
									"
									:value="propertyForm.asset_detail_label | capitalize"
									placeholder="Address of the Property"
								></el-input>
								<div
									v-if="form.errors.has('asset_detail_label')"
									class="el-form-item__error"
									v-text="form.errors.first('asset_detail_label')"
								></div>
							</el-form-item>
						</div>
					</div>

					<h2 class="will-create-subtitle">
						Beneficiary
					    <el-button type="text" class="add-copy" @click="backStep()" style="color:blue !important"
							>(Add new beneficiary)</el-button
						>
						<span class="beneficiary-total">
							<span class="text-danger"
								>{{ primaryBeneficiariesTotal }}%</span
							>
							/ <small>100%</small>
						</span>
					</h2>
					<div class="form-row beneficiary-add mb-3">
						<div
							class="col-sm-6 col-md-6 col-lg-12 col-xl-6"
							v-for="(info, index) in data.beneficiary_info
								.beneficiaries"
							v-if="
								propertyForm.secondary_beneficiaries[info.id] ==
									undefined ||
									(propertyForm.secondary_beneficiaries[info.id] !=
										undefined &&
										propertyForm.secondary_beneficiaries[info.id] ==
											0)
							"
						>
							<div class="row">
								<div
									class="form-group col-7 col-sm-6 col-md-6 col-lg-8 col-xl-6"
								>
									<label class="wg form-check-label"
										>{{ info.first_name }} {{ info.last_name }}
										<input
											type="checkbox"
											:checked="
												propertyForm.primary_beneficiaries[
													info.id
												] != undefined &&
												propertyForm.primary_beneficiaries[
													info.id
												] > 0
													? true
													: false
											"
											@change="
												changeBeneficiary($event, info, 'primary')
											"
										/>
										<span class="checkmark"></span>
									</label>
								</div>
								<div
									class="form-group col-5 col-md-6 col-lg-4 col-xl-6"
								>
									<input
										type="number"
										v-model="
											propertyForm.primary_beneficiaries[info.id]
										"
										v-on:keyup="allocatePercentageValue('Primary')"
										v-on:change="allocatePercentageValue('Primary')"
										class="form-control form-control-sm"
										placeholder="Allocation%"
									/>
								</div>
							</div>
						</div>
					</div>
					<div
						class="allocationerror mt-2"
						v-if="primaryBeneficiariesTotal != 100"
					>
						<p>Please Allocate Exactly 100%</p>
					</div>
					<hr class="mt0" />

					<div class="radio-details-tab w-100 mb-3 d-inline-block">
						<span class="will-radio-title pr-1"
							>Do you want to Add Secondary Beneficiary</span
						>
						<div
							class="type-tooltip will-tooltip"
							style="position: relative; display: inline-block"
						>
							<div class="tooltips">
								<span
									class="fa fa-question fa-tooltip question_tooltip"
									style="position: relative; left: 0px; top: -10px"
								></span>
								<div
									class="tooltip-box"
									style="width: 350px; left: 7px; bottom: 45px"
								>
									The person named under <b>“Secondary”</b> will have
									the powers only if your selected primary person(s)
									predecease you.
								</div>
							</div>
						</div>
						<div
							class="yn-radio"
							style="position: relative; display: inline-block; left: 20px"
						>
							<el-radio
								v-model="propertyForm.secondary_beneficiary"
								label="Yes"
								>Yes</el-radio
							>
							<el-radio
								v-model="propertyForm.secondary_beneficiary"
								label="No"
								>No</el-radio
							>
						</div>
					</div>

					<h2
						class="will-create-subtitle"
						v-if="propertyForm.secondary_beneficiary == 'Yes'"
					>
						Secondary Beneficiary
					    <el-button type="text" class="add-copy" @click="backStep()" style="color:blue !important"
							>(Add new beneficiary)</el-button
						>
						<span class="beneficiary-total">
							<span class="text-danger"
								>{{ secondryBeneficiariesTotal }}%</span
							>
							/ <small>100%</small>
						</span>
					</h2>
					<div
						class="form-row beneficiary-add mb-3"
						v-if="propertyForm.secondary_beneficiary == 'Yes'"
					>
						<div
							class="col-sm-6 col-md-6 col-lg-12 col-xl-6"
							v-for="(info, index) in data.beneficiary_info
								.beneficiaries"
							v-if="
								propertyForm.primary_beneficiaries[info.id] ==
									undefined ||
									(propertyForm.primary_beneficiaries[info.id] !=
										undefined &&
										propertyForm.primary_beneficiaries[info.id] == 0)
							"
						>
							<div class="row">
								<div
									class="form-group col-7 col-sm-6 col-md-6 col-lg-8 col-xl-6"
								>
									<label class="wg form-check-label"
										>{{ info.first_name }} {{ info.last_name }}
										<input
											type="checkbox"
											:checked="
												propertyForm.secondary_beneficiaries[
													info.id
												] != undefined &&
												propertyForm.secondary_beneficiaries[
													info.id
												] > 0
													? true
													: false
											"
											@change="
												changeBeneficiary($event, info, 'secondary')
											"
										/>
										<span class="checkmark"></span>
									</label>
								</div>
								<div
									class="form-group col-5 col-md-6 col-lg-4 col-xl-6"
								>
									<input
										type="number"
										v-model="
											propertyForm.secondary_beneficiaries[info.id]
										"
										v-on:keyup="allocatePercentageValue('Secondary')"
										v-on:change="allocatePercentageValue('Secondary')"
										class="form-control form-control-sm"
										placeholder="Allocation%"
									/>
								</div>
							</div>
						</div>
					</div>
					<div
						class="allocationerror mt-2"
						v-if="
							propertyForm.secondary_beneficiary == 'Yes' &&
								secondryBeneficiariesTotal != 100
						"
					>
						<p>Please Allocate Exactly 100%</p>
					</div>
				</div>

				<div
					class="will-btn-group row"
					v-if="property_info.add_estate == 'Yes'"
				>
					<div class="col-3 col-sm-6 col-md-6 col-lg-6 text-left">
						<el-button
							v-if="recordIndex != null"
							class="delete-btn mini-btn btn btn-sm"
							@click="deleteItem(recordIndex)"
						>
							delete
						</el-button>
					</div>
					<div class="col-9 col-sm-6 col-md-6 col-lg-6 text-right">
						<el-button
							v-if="recordIndex != null"
							class="cancel-btn mini-btn btn mr15 btn-sm"
							@click="cancel()"
							>cancel
						</el-button>
						<el-button
							v-if="recordIndex == null"
							title="Add Details"
							class="add-btn mini-btn btn btn-sm"
							:loading="loading"
							:disabled="loading"
							@click="addRecord()"
							>Add Estate
						</el-button>
						<el-button
							v-else
							class="add-btn mini-btn btn btn-sm"
							:loading="loading"
							:disabled="loading"
							@click="addRecord()"
							>Update Estate
						</el-button>
					</div>
				</div>
				<div v-if="message != ''" class="personalinfo-tab tab-card">
					<h2 class="will-create-title error-message text-center">
						{{ message }}
					</h2>
				</div>
			</div>

			<div class="col-sm-12 col-md-12 col-lg-4 position-relative">
				<div class="will-form-image">
					<div class="will-toon">
						<img src="/themes/flatly/images/will5.svg" class="" />
					</div>
				</div>
				<div class="document-img">
					<img src="/themes/flatly/images/document.svg" />
				</div>
				<div
					class="details-add mov-immov-card"
					v-if="property_info.records.length > 0"
				>
					<div class="row">
						<div class="col-md-6 col-lg-12 col-xl-12 col-12">
							<h3 class="details-add-title">Movable</h3>
							<div
								class="tab-card estate-details-list details-list"
								v-if="
									property_info.records.length > 0 &&
										info.asset_type != 'Land' &&
										info.asset_type != 'House' &&
										info.asset_type != 'Other Immovable' &&
										info.asset_type != 'Commercial Property'
								"
								v-for="(info, index) in property_info.records"
							>
								<h2
									class="asset-type"
									v-if="
										info.asset_type != undefined &&
											info.asset_type == 'SharesStock'
									"
								>
									Stocks & Shares
								</h2>
								<h2 class="asset-type" v-else>{{ info.asset_type }}</h2>
								<span class="asset-location">
									<span v-if="info.asset_name_label">{{
										info.asset_name_label
									}}</span>
									<small v-if="info.asset_detail_label"
										>, {{ info.asset_detail_label }}</small
									>
									<small v-if="info.vehicle_name_label"
										>, {{ info.vehicle_name_label }}</small
									>
									<small v-if="info.asset_ac_no_label"
										>, {{ info.asset_ac_no_label }}</small
									>
									<small v-if="info.Intellectual_property_label"
										>, {{ info.Intellectual_property_label }}</small
									>
									<small v-if="info.DigitalAssets_property_label"
										>, {{ info.DigitalAssets_property_label }}</small
									>
									<small v-if="info.Jewellery_property_label"
										>, {{ info.Jewellery_property_label }}</small
									>
									<small v-if="info.mutual_fund_ac_no_label"
										>, {{ info.mutual_fund_ac_no_label }}</small
									>
									<small v-if="info.gratuity_ac_no_label"
										>, {{ info.gratuity_ac_no_label }}</small
									>
									<small v-if="info.bond_ac_no_label"
										>, {{ info.bond_ac_no_label }}</small
									>
									<small v-if="info.share_ac_no_label"
										>, {{ info.share_ac_no_label }}</small
									>
									<small v-if="info.ppf_ac_no_label"
										>, {{ info.ppf_ac_no_label }}</small
									>
									<small v-if="info.crypto_currency_ac_no_label"
										>, {{ info.crypto_currency_ac_no_label }}</small
									>
									<small v-if="info.WMA_ac_no_label"
										>, {{ info.WMA_ac_no_label }}</small
									>
									<small v-if="info.demat_ac_no_label"
										>, {{ info.demat_ac_no_label }}</small
									>
								</span>
								<span class="asset-part">
									<p
										class="beni-type"
										v-if="checkBeniciariesAssociated(info, 'Primary')"
									>
										Primary
									</p>
									<span
										class="asset-part"
										v-if="checkBeniciariesAssociated(info, 'Primary')"
									>
										<span
											v-if="
												info.primary_beneficiaries[item.id] !=
													undefined &&
													info.primary_beneficiaries[item.id] > 0
											"
											v-for="(item, index) in data.beneficiary_info
												.beneficiaries"
										>
											{{ item.first_name }} {{ item.last_name }}
											{{ info.primary_beneficiaries[item.id] }}%
										</span>
									</span>

									<hr
										v-if="
											checkBeniciariesAssociated(info, 'Secondary')
										"
									/>

									<p
										class="beni-type"
										v-if="
											checkBeniciariesAssociated(info, 'Secondary')
										"
									>
										Secondary
									</p>
									<span
										class="asset-part"
										v-if="
											checkBeniciariesAssociated(info, 'Secondary')
										"
									>
										<!--  <span v-if="info.beneficiaries[item.id]!=undefined && info.beneficiaries[item.id] > 0 && item.type == 'Secondary'"
                                          v-for="(item,index) in data.beneficiary_info.beneficiaries">
                                        {{item.first_name}} {{item.last_name}} {{ info.beneficiaries[item.id] }}%
                                    </span>-->
										<span
											v-if="
												info.secondary_beneficiaries[item.id] !=
													undefined &&
													info.secondary_beneficiaries[item.id] > 0
											"
											v-for="(item, index) in data.beneficiary_info
												.beneficiaries"
										>
											{{ item.first_name }} {{ item.last_name }}
											{{ info.secondary_beneficiaries[item.id] }}%
										</span>
									</span>
									<!--<span v-if="info.primary_beneficiaries[item.id]!=undefined && info.primary_beneficiaries[item.id] > 0"
                                              v-for="(item,index) in data.beneficiary_info.beneficiaries">
                                            {{item.first_name}} {{item.last_name}} {{ info.primary_beneficiaries[item.id] }}%
                                        </span>
                                        <span v-if="info.secondary_beneficiaries[item.id]!=undefined && info.secondary_beneficiaries[item.id] > 0"
                                              v-for="(item,index) in data.beneficiary_info.beneficiaries">
                                            {{item.first_name}} {{item.last_name}} {{ info.secondary_beneficiaries[item.id] }}%
                                        </span>-->
								</span>
								<el-button
									type="text"
									title="Update Details"
									@click="editRecord(index)"
									><a class="fa fa-pencil"></a>
								</el-button>
							</div>
						</div>

						<div
							class="col-md-6 col-lg-12 col-xl-12 col-12"
							v-if="property_info.records.length > 0"
						>
							<h3 class="details-add-title">Immovable</h3>
							<div
								class="tab-card estate-details-list details-list"
								v-if="
									property_info.records.length > 0 &&
										(info.asset_type == 'Land' ||
											info.asset_type == 'House' ||
											info.asset_type == 'Other Immovable' ||
											info.asset_type == 'Commercial Property')
								"
								v-for="(info, index) in property_info.records"
							>
								<h2 class="asset-type">{{ info.asset_type }}</h2>
								<span class="asset-location">
									<span v-if="info.asset_name_label != undefined">{{
										info.asset_name_label
									}}</span>
									<small v-if="info.asset_detail_label != undefined"
										>, {{ info.asset_detail_label }}</small
									>
									<small v-if="info.asset_ac_no_label"
										>, {{ info.asset_ac_no_label }}</small
									></span
								>
								<span class="asset-part">
									<p
										class="beni-type"
										v-if="checkBeniciariesAssociated(info, 'Primary')"
									>
										Primary
									</p>
									<span
										class="asset-part"
										v-if="checkBeniciariesAssociated(info, 'Primary')"
									>
										<span
											v-if="
												info.primary_beneficiaries[item.id] !=
													undefined &&
													info.primary_beneficiaries[item.id] > 0
											"
											v-for="(item, index) in data.beneficiary_info
												.beneficiaries"
										>
											{{ item.first_name }} {{ item.last_name }}
											{{ info.primary_beneficiaries[item.id] }}%
										</span>
									</span>

									<hr
										v-if="
											checkBeniciariesAssociated(info, 'Secondary')
										"
									/>

									<p
										class="beni-type"
										v-if="
											checkBeniciariesAssociated(info, 'Secondary')
										"
									>
										Secondary
									</p>
									<span
										class="asset-part"
										v-if="
											checkBeniciariesAssociated(info, 'Secondary')
										"
									>
										<!--  <span v-if="info.beneficiaries[item.id]!=undefined && info.beneficiaries[item.id] > 0 && item.type == 'Secondary'"
                                          v-for="(item,index) in data.beneficiary_info.beneficiaries">
                                        {{item.first_name}} {{item.last_name}} {{ info.beneficiaries[item.id] }}%
                                    </span>-->
										<span
											v-if="
												info.secondary_beneficiaries[item.id] !=
													undefined &&
													info.secondary_beneficiaries[item.id] > 0
											"
											v-for="(item, index) in data.beneficiary_info
												.beneficiaries"
										>
											{{ item.first_name }} {{ item.last_name }}
											{{ info.secondary_beneficiaries[item.id] }}%
										</span>
									</span>
									<!--<span v-if="info.primary_beneficiaries[item.id]!=undefined && info.primary_beneficiaries[item.id] > 0"
                                              v-for="(item,index) in data.beneficiary_info.beneficiaries">
                                            {{item.first_name}} {{item.last_name}} {{ info.primary_beneficiaries[item.id] }}%
                                        </span>
                                        <span v-if="info.secondary_beneficiaries[item.id]!=undefined && info.secondary_beneficiaries[item.id] > 0"
                                              v-for="(item,index) in data.beneficiary_info.beneficiaries">
                                            {{item.first_name}} {{item.last_name}} {{ info.secondary_beneficiaries[item.id] }}%
                                        </span>-->
								</span>
								<el-button type="text" @click="editRecord(index)"
									><a class="fa fa-pencil"></a>
								</el-button>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="row">
			<div class="col-sm-12 col-md-12 col-lg-12">
				<div class="will-rext-prev-btn">
					<el-button
						class="btn btn-prev active btn-nex-prev"
						@click="backStep()"
						><i class="fa fa-angle-left"></i
					></el-button>
					<el-button class="btn btn-next btn-nex-prev" @click="submit()"
						><i class="fa fa-angle-right"></i>
					</el-button>
				</div>
			</div>
		</div>
	</el-form>
</template>

<script>
	import Form from 'form-backend-validation';
	import { MessageBox } from 'element-ui';

	export default {
		name: 'step5',
		props: ['data', 'id'],
		data() {
			return {
				activeTab: 5,
				formName: 'propertyForm',
				loading: false,
				recordIndex: null,
				showForm: false,
				form: new Form(),
				property_info: {
					add_estate: 'Yes',
					records: []
				},
				primaryBeneficiariesTotal: 0,
				secondryBeneficiariesTotal: 0,
				message: '',
				propertyForm: {
					id: Date().valueOf(),
					beneficiaries: {},
					primary_beneficiaries: {},
					secondary_beneficiaries: {},
					secondary_beneficiary: 'No',
					asset_type: 'Bank Account',
					asset_name_label: '',
					demat_ac_no_label: '',
					asset_ac_no_label: '',
					asset_detail_label: '',
					asset_Weight_label: '',
					WMA_ac_no_label: '',
					crypto_currency_ac_no_label: '',
					ppf_ac_no_label: '',
					mutual_fund_ac_no_label: '',
					bond_ac_no_label: '',
					vehicle_name_label: '',
					gratuity_ac_no_label: '',
					Intellectual_property_label: '',
					DigitalAssets_property_label: '',
					Jewellery_property_label: '',
					share_ac_no_label: ''
				}
			};
		},
		methods: {
			clearErrors() {
				//.find((f) => f.prop == "ppf_ac_no_label").resetField();
				//this.propertyForm.resetFields();
				//this.$refs.propertyForm.getFieldRule();
				let atype = this.propertyForm.asset_type;
				this.$refs.propertyForm.resetFields();
				console.log("atype :",atype);
				this.propertyForm.asset_type = atype;
				//this.$refs.propertyForm.asset_ac_no_label = "";
				//this.$refs.propertyForm. = "";
			},
			checkBeniciariesAssociated(record, type) {
				/* for (var i in this.data.beneficiary_info.beneficiaries) {
                     for (var j in this.gift_info.records) {
                         if (this.data.beneficiary_info.beneficiaries[i].type == type && this.gift_info.records[j].beneficiaries[this.data.beneficiary_info.beneficiaries[i].id] != undefined && this.gift_info.records[j].beneficiaries[this.data.beneficiary_info.beneficiaries[i].id] > 0) {
                             return true;
                             break;
                         }
                     }
                 }*/
				if (type == 'Primary') {
					for (var i in this.data.beneficiary_info.beneficiaries) {
						if (
							record.primary_beneficiaries[
								this.data.beneficiary_info.beneficiaries[i].id
							] != undefined &&
							record.primary_beneficiaries[
								this.data.beneficiary_info.beneficiaries[i].id
							] > 0
						) {
							return true;
							break;
						}
					}
				} else {
					for (var i in this.data.beneficiary_info.beneficiaries) {
						if (
							record.secondary_beneficiaries[
								this.data.beneficiary_info.beneficiaries[i].id
							] != undefined &&
							record.secondary_beneficiaries[
								this.data.beneficiary_info.beneficiaries[i].id
							] > 0
						) {
							return true;
							break;
						}
					}
				}

				return false;
			},
			deleteItem(index) {
				MessageBox.confirm(
					`Do you really want to delete?`,
					'Confirmation',
					{
						confirmButtonText: 'OK',
						cancelButtonText: 'Cancel',
						type: 'warning'
					}
				)
					.then(() => {
						this.deleteRecord(index);
					})
					.catch(() => {});
			},
			cancel() {
				this.propertyForm.beneficiaries = {};
				this.propertyForm.secondary_beneficiaries = {};
				this.propertyForm.primary_beneficiaries = {};
				this.$refs.propertyForm.resetFields();
				this.recordIndex = null;
			},
			editRecord(index) {
				this.recordIndex = index;
				this.property_info.add_estate = 'Yes';

				var record = {};
				for (const cnfg in this.property_info.records[index]) {
					console.log(cnfg);
					record = _.merge(record, {
						[cnfg]: this.property_info.records[index][cnfg]
					});
				}
				// if(record.property_info.secondary_beneficiary == 'Yes') {
				//
				// }

				this.propertyForm = record;
				this.allocatePercentageValue('Primary');
				this.allocatePercentageValue('Secondary');
			},
			deleteRecord(deleteIndex) {
				this.property_info.records.splice(deleteIndex, 1);
				this.recordIndex = null;
				this.cancel();
				if (this.property_info.records.length == 0)
					this.property_info.add_estate = 'No';
				this.submit('Yes');
			},
			resetForm() {
				this.$refs[this.formName].resetFields();
			},
			addRecord() {
				if (this.loading == true) return false;
				this.loading = true;
				this.message = '';
				console.log(1);
				if (this.propertyForm.secondary_beneficiary !== 'No') {
					console.log(2);
					this.$refs[this.formName].validate((valid) => {
						console.log(3);
						if (
							valid &&
							this.primaryBeneficiariesTotal == 100 &&
							this.secondryBeneficiariesTotal == 100
						) {
							console.log(4);
							this.form = new Form({
								activeTab: this.activeTab,
								property_record: this.propertyForm
							});
							if (this.id != 0) {
								console.log(5);
								console.log('id', this.id);
								this.form
									.post(
										route('api.will.wills.savedata', { id: this.id })
									)
									.then((response) => {
										console.log('id', this.id);
										this.loading = false;
										if (response.errors == false) {
											this.addInfo();
										} else {
											if (response.dashboardRoute != undefined) {
												window.location = response.dashboardRoute;
											}
										}
									})
									.catch((error) => {
										this.loading = false;
									});
							} else {
								console.log("step 5 add", this.form);
								this.form
									.post('/api/will/formData/add')
									.then((response) => {
										this.loading = false;
										if (response.errors == false) {
											this.message = '';
											this.addInfo();
										} else {
											if (response.dashboardRoute != undefined) {
												window.location = response.dashboardRoute;
											}
										}
									})
									.catch((error) => {
										this.save = false;
										this.loading = false;
									});
							}
						} else {
							console.log(6);
							this.loading = false;
						}
					});
				} else {
					console.log(7);
					this.$refs[this.formName].validate((valid) => {
						console.log(8);
						if (valid && this.primaryBeneficiariesTotal == 100) {
							console.log(9);
							this.form = new Form({
								activeTab: this.activeTab,
								property_record: this.propertyForm
							});
							if (this.id != 0) {
								console.log(10);
								console.log('id', this.id);
								this.form
									.post(
										route('api.will.wills.savedata', { id: this.id })
									)
									.then((response) => {
										console.log('id', this.id);
										this.loading = false;
										if (response.errors == false) {
											this.addInfo();
										} else {
											if (response.dashboardRoute != undefined) {
												window.location = response.dashboardRoute;
											}
										}
									})
									.catch((error) => {
										this.loading = false;
									});
							} else {
								console.log(11);
								this.form
									.post('/api/will/formData/add')
									.then((response) => {
										this.loading = false;
										if (response.errors == false) {
											this.message = '';
											this.addInfo();
										} else {
											if (response.dashboardRoute != undefined) {
												window.location = response.dashboardRoute;
											}
										}
									})
									.catch((error) => {
										this.save = false;
										this.loading = false;
									});
							}
						} else {
							console.log(12);
							this.loading = false;
						}
					});
				}
				this.loading = false;
			},
			addInfo() {
				var record = {};
				for (const cnfg in this.propertyForm) {
					record = _.merge(record, { [cnfg]: this.propertyForm[cnfg] });
				}
				if (this.recordIndex == null) {
					record.id = 'SP' + new Date().valueOf();
					this.property_info.records.push(record);
				} else {
					this.property_info.records[this.recordIndex] = record;
					this.recordIndex = null;
				}
				this.propertyForm.beneficiaries = {};
				this.propertyForm.secondary_beneficiaries = {};
				this.propertyForm.primary_beneficiaries = {};
				this.$refs.propertyForm.resetFields();
				this.secondryBeneficiariesTotal = 0;
				this.primaryBeneficiariesTotal = 0;

				this.loading = false;
				this.submit('Yes');
			},
			submit(addInfo = null) {
				window.scrollTo({ top: 0, behavior: 'smooth' });
				this.message = '';
				if (
					this.property_info.add_estate == 'Yes' &&
					this.property_info.records.length == 0
				) {
					console.log('error');
					this.$refs[this.formName].validate((valid) => {
						if (valid) {
							this.property_info.add_estate = 'Yes';
							this.message =
								"Please click on 'Add Estate' button to save the details";
						} else {
							this.property_info.add_estate = 'Yes';
						}
					});
				} else {
					this.loading = true;
					this.form = new Form({
						activeTab: this.activeTab,
						property_info: this.property_info
					});
					if (this.id != 0) {
						console.log('id', this.id);
						this.form
							.post(route('api.will.wills.savedata', { id: this.id }))

							.then((response) => {
								console.log('id', this.id);
								this.loading = false;
								if (response.errors == false) {
									if (addInfo != 'Yes') {
										this.$emit('changeActiveTab', this.activeTab + 1);
										this.$emit('updateFormData', response.data);
									}
								}
							})
							.catch((error) => {
								this.loading = false;
							});
					} else {
						this.form
							.post('/api/will/formData/add')
							.then((response) => {
								this.loading = false;
								if (response.errors == false) {
									this.message = '';
									if (addInfo != 'Yes') {
										this.$emit('changeActiveTab', this.activeTab + 1);
										this.$emit('updateFormData', response.data);
									}
								} else {
									if (response.dashboardRoute != undefined) {
										window.location = response.dashboardRoute;
									}
								}
							})
							.catch((error) => {
								this.save = false;
								this.loading = false;
							});
					}
				}
			},
			generateBeneficiaryTotalVal(type, beneficaryId = 0) {
				var totalCount = 0;

				if (type == 'Primary') {
					for (var i in this.data.beneficiary_info.beneficiaries) {
						var id = this.data.beneficiary_info.beneficiaries[i].id;
						if (
							this.propertyForm.primary_beneficiaries[id] != undefined
						) {
							totalCount += parseInt(
								this.propertyForm.primary_beneficiaries[id]
							);
						}
					}
					if (totalCount > 0) this.primaryBeneficiariesTotal = totalCount;
					else this.primaryBeneficiariesTotal = 0;
					if (beneficaryId != 0) {
						this.propertyForm.primary_beneficiaries.splice(beneficaryId);
						console.log(this.propertyForm.primary_beneficiaries);
					}
				} else {
					for (var i in this.data.beneficiary_info.beneficiaries) {
						var id = this.data.beneficiary_info.beneficiaries[i].id;
						if (
							this.propertyForm.secondary_beneficiaries[id] != undefined
						) {
							totalCount += parseInt(
								this.propertyForm.secondary_beneficiaries[id]
							);
						}
					}
					if (totalCount > 0) this.secondryBeneficiariesTotal = totalCount;
					else this.secondryBeneficiariesTotal = 0;
					if (beneficaryId != 0) {
						this.propertyForm.secondary_beneficiaries.splice(
							beneficaryId,
							1
						);
					}
				}
			},
			changeBeneficiary(event, item, type) {
				if (type == 'primary') {
					if (event.target.checked == true) {
						console.log(event);
						if (
							this.propertyForm.primary_beneficiaries[item.id] ==
							undefined
						) {
							// this.propertyForm.primary_beneficiaries.push({[item.id] : 0})
							this.propertyForm.primary_beneficiaries[item.id] = 0;
						}
					} else {
						this.propertyForm.primary_beneficiaries[item.id] = 0;
						this.generateBeneficiaryTotalVal('Primary', item.id);
					}
				} else {
					if (event.target.checked == true) {
						console.log(event);
						if (
							this.propertyForm.secondary_beneficiaries[item.id] ==
							undefined
						) {
							// this.propertyForm.secondary_beneficiaries.push({[item.id] : 0})
							this.propertyForm.secondary_beneficiaries[item.id] = 0;
						}
					} else {
						this.propertyForm.secondary_beneficiaries[item.id] = 0;
						this.generateBeneficiaryTotalVal('Secondary', item.id);
					}
				}
			},
			allocatePercentageValue(type) {
				this.generateBeneficiaryTotalVal(type);
			},
			backStep() {
				this.$emit('changeActiveTab', this.activeTab - 1);
			},
			getFieldRule(Title, FieldName = null, FieldType = null) {
				if (FieldType == 'number') {
					return [
						{
							required: true,
							message: `${Title} field is required`
						},
						{
							max: 100,
							message: 'Length should be 3 to 5'
						}
					];
				} else if (FieldType == 'alphanumberic') {
					return [
						{
							validator: (rule, value, callback) => {
								if (!value) {
									// this.property_info.add_estate = "No";
									return callback(
										new Error(`${FieldName} is required`)
									);
								} else if (!value.match(/^[0-9a-zA-Z]+$/)) {
									// this.property_info.add_estate = "No";
									return callback(
										new Error(
											`${FieldName} should contain only alphanumeric`
										)
									);
								} else {
									//this.$refs.propertyForm.validateField('asset_name_label');
									callback();
								}
							},
							trigger: 'blur'
						}
					];
				} else if (FieldType == 'alphanumbericwithspace') {
					return [
						{
							validator: (rule, value, callback) => {
								if (!value) {
									return callback(
										new Error(`${FieldName} is required`)
									);
								} else if (!value.match(/^[0-9a-zA-Z ]+$/)) {
									return callback(
										new Error(
											`${FieldName} should contain only alphanumeric and it can contain space`
										)
									);
								} else {
									//this.$refs.propertyForm.validateField('asset_ac_no_label');
									callback();
								}
							},
							trigger: 'blur'
						}
					];
				} else if (FieldType == 'alphanumberic') {
					return [
						{
							validator: (rule, value, callback) => {
								if (!value) {
									// this.property_info.add_estate = "No";
									return callback(
										new Error(`${FieldName} is required`)
									);
								} else if (!value.match(/^[0-9a-zA-Z]+$/)) {
									// this.property_info.add_estate = "No";
									return callback(
										new Error(
											`${FieldName} should contain only alphanumeric`
										)
									);
								} else {
									//this.$refs.propertyForm.validateField('asset_name_label');
									callback();
								}
							},
							trigger: 'blur'
						}
					];
				} else if (FieldType == 'alphanumbericwithspace') {
					return [
						{
							validator: (rule, value, callback) => {
								if (!value) {
									return callback(
										new Error(`${FieldName} is required`)
									);
								} else if (!value.match(/^[0-9a-zA-Z ]+$/)) {
									return callback(
										new Error(
											`${FieldName} should contain only alphanumeric and it can contain space`
										)
									);
								} else {
									//this.$refs.propertyForm.validateField('asset_ac_no_label');
									callback();
								}
							},
							trigger: 'blur'
						}
					];
				} else if (FieldType == 'alphanumericwithspecialchars') {
					return [
						{
							validator: (rule, value, callback) => {
								console.log('value123', value);

								if (!value) {
									return callback(
										new Error(`${FieldName} is required`)
									);
								} else if (
									!value.match(/^[0-9a-zA-Z#@_$!~%^&*()+\- ]+$/)
								) {
									return callback(
										new Error(
											`${FieldName} should contain only alphanumeric and Special Characters like ( Accepted Special Characters : #@_$!~%^&*()-+ )`
										)
									);
								} else {
									//this.$refs.propertyForm.validateField('asset_ac_no_label');
									callback();
								}
							},
							trigger: 'blur'
						}
					];
				} else {
					return {
						required: true,
						message: `${Title} field is required`
					};
				}
			}
		},
		filters: {
			capitalize: function(value) {
				if (!value) return '';
				// value = value.toString().toLowerCase()
				return value.charAt(0).toUpperCase() + value.slice(1);
			}
		},
		mounted() {
			this.loading = true;

			if (
				this.data != undefined &&
				this.data.property_info != undefined &&
				this.data.property_info.records !== undefined
			) {
				this.property_info = this.data.property_info;
			}
			this.loading = false;
		},
		watch: {
			'property_info.add_estate': function() {
				if (this.property_info.add_estate == 'No') {
					this.property_info.add_estate = 'Yes';
					// this.property_info.records = [];
				}
			},
			'propertyForm.secondary_beneficiary': function() {
				if (this.propertyForm.secondary_beneficiary == 'No') {
					this.propertyForm.secondary_beneficiaries = {};
				}
			}
		}
	};
</script>

<style>
	.allocationerror {
		color: red;
		font-size: 10px;
	}
	.tooltipsestate {
		position: absolute;
		width: 100%;
		bottom: 17% !important;
		right: -42% !important;
	}
	.fa-tooltipestate {
		position: absolute;
		top: 0;
		left: 53px !important;
		width: 1rem !important;
		padding: 0 !important;
		text-align: center !important;
	}
</style>
