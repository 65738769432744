<template>
    <el-form v-loading="loading" ref="recordForm" :model="recordForm" label-width="120px" label-position="top">
        <div class="row">
            <div class="col-sm-12 col-md-12 col-lg-8">
               
                <div class="radio-details-tab  tab-card">
                    <span class="will-radio-title pr-3">Do you want to add the details of your liabilities?</span>
                    <p>If you have any loans or liabilities which you would like to inform your beneficiaries/family please use this.</p>
                    <div class="yn-radio">
                        <div class="yn-radio">
                            <!-- liability-yes -->
                            <el-radio v-model="liability_info.add_liability" label="Yes">Yes</el-radio>  
                            <!-- liability-No -->
                            <el-radio v-model="liability_info.add_liability" label="No">No</el-radio>
                        </div>
                    </div>
                </div>
                <!-- Liability After yes -->
                <div class=" tab-card" v-if="liability_info.add_liability == 'Yes'">  
                     <h2 class="will-create-title">Please give details of the liabilty/loan</h2>
                     <div class="form-row">
                         <div class="form-group col-sm-6 col-md-6 col-lg-6">
                                <el-form-item
                                    label="Loan Type" 
                                    prop="asset_type" 
                                    :rules="getFieldRule('Loan Type')" 
                                >
                                    <el-select
                                    v-model="recordForm.asset_type"
                                    >
                                            <el-option
                                                value="Personal Loan"
                                                label="Personal Loan"
                                            ></el-option>
                                            <el-option
                                                value="Home Loan"
                                                label="Home Loan"
                                            ></el-option>
                                    </el-select>
                                </el-form-item>
                         </div>
                         <div class="form-group col-sm-6 col-md-6 col-lg-6">
                            <el-form-item label="Bank Name" prop="bank_name" :rules="getFieldRule('Bank Name')"
                            v-if="recordForm.asset_type == 'Home Loan' || recordForm.asset_type == 'Personal Loan'">
                                <el-input @input="value => recordForm.bank_name = value"
                                                  :value="recordForm.bank_name | capitalize" type="text"
                                          placeholder="Bank Name"></el-input>
                                <div v-if="form.errors.has('bank_name')"  class="el-form-item__error"
                                     v-text="form.errors.first('bank_name')"></div>
                            </el-form-item>  
                         </div>        
                         <div class="form-group col-sm-6 col-md-6 col-lg-6"
                         v-if="recordForm.asset_type == 'Home Loan'||recordForm.asset_type == 'Personal Loan' " >

                             <el-form-item label="Account Number" prop="account_number"
                                          :rules="getFieldRule('Account Number')"
                                           v-if="recordForm.asset_type == 'Home Loan' || recordForm.asset_type == 'Personal Loan'">
                                <el-input type="text" 
                                          placeholder="Account Number" @input="value => recordForm.account_number = value"
                                                  :value="recordForm.account_number" ></el-input>
                                <div v-if="form.errors.has('account_number')" class="el-form-item__error"
                                     v-text="form.errors.first('account_number')"></div>
                            </el-form-item>      
                         </div>
                         <div class="form-group col-sm-6 col-md-6 col-lg-6"  v-if="recordForm.asset_type == 'Personal Loan' || recordForm.asset_type == 'Home Loan' ">
                            <el-form-item label="Amount" prop="amount"
                            :rules="getFieldRule('Amount')" 
                            v-if="recordForm.asset_type == 'Personal Loan' || recordForm.asset_type == 'Home Loan'">
                                <el-input @input="value => recordForm.amount = value"
                                    :value="recordForm.amount"
                                          placeholder="Enter Amount"></el-input>
                                <div v-if="form.errors.has('asset_detail_label')" class="el-form-item__error"
                                     v-text="form.errors.first('amount')"></div>
                            </el-form-item>  
                         </div>
                     </div>
                </div>
                <div class=" tab-card" v-if="liability_info.add_liability == 'Yes'">
                    <h3 class="will-create-title">If you wish to link any of your assets to pay for your loan/liability please mention below:</h3>
                     <p style="padding-left: 0.3rem;">If the liability is still outstanding at the time of your demise, then your asset below will be used to pay off the liability.<br>If the asset mentioned is not enough to pay off the said liability, then your executor/beneficiary will have to pay the remaining liability out of your other assets. </p>                   

                    <!-- <el-form-item prop="summary_help">
                       <el-input type="textarea" @input="value => recordForm.summary_help = value"
                                    :value="recordForm.summary_help" class="form-control"></el-input>
                       <div v-if="form.errors.has('asset_detail_label')" class="el-form-item__error"
                                     v-text="form.errors.first('summary_help')"></div>
                    </el-form-item>
                     <br>
                        <span>&nbsp; (or)</span> -->
                    

                        <div class="form-row">
                            <div class="col-12">
                            <h2 class="tragegy-asset-title" v-if="((totalRemaining > 0 || editAssets != false) && (movableCount > 0))">Assets <a>(Movable)</a></h2>
                                <div class="row tragegy-assets" v-if="totalRemaining > 0 || editAssets != false">
                                    <div class="col-sm-6 col-md-6 col-lg-6 tragegy-assets-list"
                                        v-if="(remainingAssets[info.id]!=undefined && remainingAssets[info.id] > 0 || remainingAssets[info.id]==undefined || editAssets != false) && (info.asset_type != 'Land' && info.asset_type != 'Commercial Property' && info.asset_type != 'Other Immovable' && info.asset_type != 'House')"
                                        v-for="(info,index) in data.property_info.records">
                                        
                                        <h3>{{info.asset_type}}</h3>
                                        <p v-if='info.asset_name_label!=null'>{{ info.asset_name_label }}</p>
                                        <p v-else>{{ info.asset_detail_label }}</p>
                                        <div class="form-row m-0">
                                            <div class="form-group col-3 pl-0">
                                                <label class="wg form-check-label mt-2">All <input
                                                        type="checkbox"
                                                        :checked="recordForm.properties[info.id]!=undefined  ? true:false"
                                                        @change="changeBeneficiary($event,info)"> <span
                                                        class="checkmark"></span>
                                                </label>
                                            </div>
                                            <div class="form-group col-3">
                                                <input type="text" v-model="recordForm.properties[info.id]"
                                                    v-on:keyup="allocatePercentageValue(info, $event)"
                                                    class="form-control form-control-sm"
                                                    :placeholder="remainingAssets[info.id]!=undefined ? remainingAssets[info.id]+'%':'100%'">
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <h2 class="tragegy-asset-title" v-if="((totalRemaining > 0 || editAssets != false) && (immovableCount > 0))">Assets <a>(Immovable)</a>
                                    </h2>
                                <div class="row tragegy-assets" v-if="totalRemaining > 0 || editAssets != false">
                                    <div class="col-sm-6 col-md-6 col-lg-6 tragegy-assets-list"
                                        v-if="(remainingAssets[info.id]!=undefined && remainingAssets[info.id] > 0 || remainingAssets[info.id]==undefined || editAssets != false) && (info.asset_type == 'Land' || info.asset_type == 'House' || info.asset_type == 'Commercial Property' || info.asset_type == 'Other Immovable' )"
                                        v-for="(info,index) in data.property_info.records"> 
                                        <h3>{{info.asset_type}}</h3>
                                        <p v-if='info.asset_name_label!=null'>{{ info.asset_name_label }}</p>
                                        <p v-else>{{ info.asset_detail_label }}</p>

                                        <div class="form-row m-0">
                                            <div class="form-group col-3 pl-0">
                                                <label class="wg form-check-label mt-2">All <input
                                                        type="checkbox"
                                                        :checked="recordForm.properties[info.id]!=undefined  ? true:false"
                                                        @change="changeBeneficiary($event,info)"> <span
                                                        class="checkmark"></span>
                                                </label>
                                            </div>
                                            <div class="form-group col-3">
                                                <input type="text" v-model="recordForm.properties[info.id]"
                                                    v-on:keyup="allocatePercentageValue(info, $event)"
                                                    class="form-control form-control-sm"
                                                    :placeholder="remainingAssets[info.id]!=undefined ? remainingAssets[info.id]+'%':'100%'">
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div
                                    class="allocationerror mt-2"
                                    v-if="percentOverhundred != ''"
                                >
                                    <p>{{percentOverhundred}}</p>
                                </div>
                            </div>
                        </div>
                </div>

                <div class="will-btn-group row" v-if="liability_info.add_liability == 'Yes'">
                    <div class="col-3 col-sm-6 col-md-6 col-lg-6 text-left">
                        <el-button class="delete-btn mini-btn btn btn-sm"  v-if="submit_btn != 'Add Liability'"
                                   @click="deleteItem(recordIndex)">delete
                        </el-button>
                    </div>
                    <div class="col-9 col-sm-6 col-md-6 col-lg-6 text-right">
                        <el-button v-if="submit_btn != 'Add Liability'" class="cancel-btn  mini-btn btn mr15 btn-sm"
                                   @click="cancel()">cancel
                        </el-button>
                        <el-button :title="submit_btn" class="add-btn  mini-btn btn btn-sm"
                                   :loading="loading"
                                   :disabled="usedTotalMessage !== '' || loading == true || percentOverhundred != '' ? true : false"
                                   @click="addRecord()">{{submit_btn}}
                        </el-button>
                        <!-- <el-button v-else title="Update Details" class="add-btn  mini-btn btn btn-sm" :loading="loading"
                                   :disabled="usedTotalMessage !== '' || loading == true ? true : false"
                                   @click="addRecord()">Update Liability
                        </el-button> -->
                    </div>

                </div>
                <div v-if="message != ''" class="personalinfo-tab tab-card">
                    <h2 class="will-create-title error-message text-center">{{ message }}</h2>
                </div>
            </div>
            <div class="col-sm-12 col-md-12 col-lg-4 position-relative">
                <div class="will-form-image">
                    <div class="will-toon">
                        <img src="/themes/flatly/images/will8.svg" class="">
                    </div>

                </div>
                <div class="document-img">
                    <img src="/themes/flatly/images/document.svg">
                </div>
                <div class="details-add" v-if="liability_info.records.length > 0">
                <div class="row">
						<div class="col-md-6 col-lg-12 col-xl-12 col-12">
							<div
								class="tab-card estate-details-list details-list"
								v-for="(info, index) in liability_info.records"
							>
								<h2 class="asset-type">{{ info.asset_type }}</h2>
                                <span>{{ info.asset_type }} from {{ info.bank_name }} Acc No {{ info.account_number }} for {{ info.amount }}</span>		
                                <p class="mb-0" v-if="info.properties[item.id] !=
                                            undefined &&
                                            info.properties[item.id] > 0
                                    "
                                    v-for="(item, index) in data.property_info
                                        .records"
                                >
                                    {{ item .asset_type }} - {{item.asset_name_label}} - {{info.properties[item.id]}}%
                                </p>							
                                <el-button
									type="text"
									title="Update Details"
									@click="editRecord(index)"
									><a class="fa fa-pencil"></a
								></el-button>
                            </div>
						</div>
					</div>
                </div>
            </div>
        </div>
        
        <div class="row">
            <div class="col-sm-12 col-md-12 col-lg-12">
                <div class="will-rext-prev-btn">
                    <el-button class="btn btn-prev active btn-nex-prev" @click="backStep()"><i
                            class="fa fa-angle-left"></i></el-button>
                    <el-button class="btn btn-next btn-nex-prev" @click="submit('recordForm')"><i
                            class="fa fa-angle-right"></i></el-button>
                </div>
            </div>
        </div>
    </el-form>
</template>

<script>
    import Form from 'form-backend-validation';
    import Common from '../../mixins/commonMethod';
    import {MessageBox} from "element-ui";

    export default {
        name: "step7",
        props: ['data', 'states', 'asset_types', 'id'],
        mixins: [Common], 
        data() {
            return {
                activeTab: 7,
                total: 0,
                message: '',
                propert_message: '',
                usedTotalMessage: '',
                loading: false,
                editAssets: false,
                form: new Form(),
                recordIndex: null,
                percentOverhundred : '',
                movableCount: 0,
                immovableCount: 0,
                submit_btn : "Add Liability",
                liability_info: {
                    add_liability: 'No',
                    records: []
                },
                formName: 'recordForm',
                recordForm: {
                    id: "SP" + new Date().valueOf(),
                    properties: {},
                    bank_name:'',
                    account_number:'',
                    amount:'',
                    summary_help: ''                   
                },
                assetsTotalAreUsed: false,
                usedTotalAssets: {},
                remainingAssets: {},
                editableProperty: {},
                totalRemaining: 100,
                isExit: 0,                
            }
        },
        filters: {
            capitalize: function (value) {
                if (!value) return ''
                // value = value.toString().toLowerCase()
                return value.charAt(0).toUpperCase() + value.slice(1)
            }
        },
        methods: {
            deleteItem(index) {
                MessageBox.confirm(`Do you really want to delete?`,
                    "Confirmation",
                    {
                        confirmButtonText: "OK",
                        cancelButtonText: "Cancel",
                        type: "warning"
                    }).then(() => {
                    this.deleteRecord(index);
                }).catch(() => {
                });
            },            
            generateRemainingAssets(check = null) {
                this.assetsTotalAreUsed = false;
                this.usedTotalAssets = {};
                this.remainingAssets = {};
                this.usedTotalMessage = '';
                this.total = 0;
                var usedAssets = [];
                console.log("this.liability_info.records", this.liability_info.records);
                for (var i in this.liability_info.records) {
                    for (var j in this.liability_info.records[i].properties) {
                        if (this.usedTotalAssets[j] != undefined) {
                            this.usedTotalAssets[j] += parseInt(this.liability_info.records[i].properties[j]);
                        } else {
                            this.usedTotalAssets[j] = parseInt(this.liability_info.records[i].properties[j]);
                        }
                        if (check == 'Yes') {
                            if (this.recordIndex === null) {
                                this.total = this.usedTotalAssets[j];
                            } else if (this.editableProperty[j] != undefined) {

                                this.total = this.usedTotalAssets[j] - this.editableProperty[j];
                            } else {
                                console.log('property', j);
                                console.log('editableProperty', this.editableProperty[j]);
                                console.log('usedtotal', this.usedTotalAssets[j]);
                                this.total = this.usedTotalAssets[j];
                            }

                            console.log('before', this.total);
                            let temp = this.total;
                            this.total += parseInt(this.recordForm.properties[j]);
                            console.log('after', this.total);

                            if(this.total > 100){
                                this.percentOverhundred = `Please allocate ${100 - temp} or less`;
                            }

                            if (this.total > 100) {
                                this.usedTotalMessage = "One of Your Property allocation's calculation is more than 100%, You cannot allocate more than 100%.";
                            }
                        }
                    }
                }

                for (var k in this.data.property_info.records) {
                    if (this.usedTotalAssets[this.data.property_info.records[k].id] != undefined) {
                        this.remainingAssets[this.data.property_info.records[k].id] = 100 - this.usedTotalAssets[this.data.property_info.records[k].id];
                    } else {
                        this.remainingAssets[this.data.property_info.records[k].id] = 100;
                    }


                    if (this.remainingAssets[this.data.property_info.records[k].id] == 0) {

                        this.assetsTotalAreUsed = true;
                        if (this.totalRemaining > 0) {
                            this.totalRemaining--;
                        }
                        usedAssets.push(this.data.property_info.records[k].id);
                    } else {
                        this.assetsTotalAreUsed = false;
                        this.totalRemaining++;
                        usedAssets.splice(1);
                    }
                    if (this.remainingAssets[this.data.property_info.records[k].id] < 0) {
                        usedAssets.push(this.data.property_info.records[k].id);
                    }
                    if (usedAssets.length == this.data.property_info.records.length) {

                        this.totalRemaining = 0;
                    } else {

                        this.totalRemaining++
                        this.assetsTotalAreUsed = false;

                    }

                }
            },
            changeBeneficiary(event, item) {
                if (event.target.checked == true) {
                    if (this.remainingAssets[item.id] !== undefined) {
                        this.recordForm.properties[item.id] = this.remainingAssets[item.id];
                    } else {
                        this.recordForm.properties[item.id] = 100;
                    }
                } else {
                    this.recordForm.properties[item.id] = 0;
                }
            },
            allocatePercentageValue(item, event) {
                this.usedTotalMessage = '';
                this.percentOverhundred = '';
                console.log("item percentage", event.target.value, item);
                if(event.target.value > 100){
                    this.percentOverhundred = 'Please allocate 100% or less';
                }
                this.generateRemainingAssets('Yes');
            },
            cancel() {
                console.log("cancelllll");
                this.generateRemainingAssets();
                this.recordForm.properties = {};
                this.recordIndex = null;
                this.resetForm();
                this.submit_btn = "Add Liability";
                this.recordForm = {
                    id: "SP" + new Date().valueOf(),
                    properties: {},
                    bank_name:'',
                    account_number:'',
                    amount:'',
                    summary_help: '',
                    asset_type: ''     
                }
                this.id = 0;
            },
            backStep() {
                this.$emit('changeActiveTab', this.activeTab - 1);
            },
            getFieldRule(Title, FieldName = null, FieldType = null) {
                return {
                    required: true,
                    message: `${Title} field is required`,
                };
            },
            editRecord(index) {
                console.log("edit index", index);
                this.submit_btn = "Update Liability";
                this.recordIndex = index;
                this.liability_info.add_liability = 'Yes';
                var record = {};
                for (const cnfg in this.liability_info.records[index]) {
                    record = _.merge(record, {[cnfg]: this.liability_info.records[index][cnfg]})
                }
                this.editableProperty = this.liability_info.records[index].properties;
                this.editAssets = true;
                this.recordForm = record;
                this.id = record.id;
                console.log("iddddd", this.id, record);
            },
            deleteRecord(deleteIndex) {
                // this.editableProperty = this.liability_info.records[index].properties;
                this.liability_info.records.splice(deleteIndex, 1);
                this.cancel();
                if (this.liability_info.records.length == 0)
                    this.liability_info.add_liability = 'No';
                this.form = new Form({activeTab: this.activeTab, liability_info: this.liability_info});
                this.form.post('/api/will/formData/add')
                    .then((response) => {
                        this.loading = false;
                        if (response.errors == false) {
                            this.recordForm = {
                                id: "SP" + new Date().valueOf(),
                                properties: {},
                                bank_name:'',
                                account_number:'',
                                amount:'',
                                summary_help: ''                   
                            }
                            //this.addInfo();
                            this.submit_btn = "Add Liability";
                            this.id = 0;
                            this.resetForm();
                            this.liability_info = response.data.liability_info;
                        } else {
                            if (response.dashboardRoute != undefined) {
                                window.location = (response.dashboardRoute);
                            }
                        }
                    })
                    .catch((error) => {
                        this.loading = false;
                    });
                
            },
            resetForm() {
                this.$refs[this.formName].resetFields();
            },
            addRecord() {
                if (this.loading == true)
                    return false;
                this.loading = true;
                this.editAssets = false;
                this.message = '';
                this.propert_message = '';


                // this.usedTotalMessage  = '';
                this.$refs[this.formName].validate((valid) => {
                    if (valid && this.usedTotalMessage == '') {
                        console.log("111");
                        let recordsList = [];
                        if(this.liability_info.records && this.liability_info.records.length > 0){
                            console.log("222", this.id);
                            if(this.id !== 0){
                                console.log("333");
                                const recordIndex = this.liability_info.records.findIndex((record) => record.id === this.id)
                                recordsList = this.liability_info.records;
                                recordsList[recordIndex] = this.recordForm;
                            }else{
                                console.log("444");
                                recordsList = this.liability_info.records.concat(this.recordForm);
                            }
                        }else{
                            console.log("555");
                            recordsList = [this.recordForm];
                        }
                        this.liability_info.records = recordsList;
                        this.form = new Form({activeTab: this.activeTab, liability_info: this.liability_info});
                            this.form.post('/api/will/formData/add')
                                .then((response) => {
                                    this.loading = false;
                                    if (response.errors == false) {
                                        console.log("666");
                                        this.liability_info = response.data.liability_info;
                                       
                                        this.recordForm = {
                                            id: "SP" + new Date().valueOf(),
                                            properties: {},
                                            bank_name:'',
                                            account_number:'',
                                            amount:'',
                                            summary_help: '',
                                            asset_type: ''     
                                        }
                                        this.generateRemainingAssets();
                                        // this.addInfo();
                                        this.resetForm();
                                        this.id = 0;
                                        this.submit_btn = "Add Liability";
                                    } else {
                                        console.log("777");
                                        if (response.dashboardRoute != undefined) {
                                            window.location = (response.dashboardRoute);
                                        }
                                    }
                                })
                                .catch((error) => {
                                    console.log("888");
                                    this.loading = false;
                                });
                    } else {
                        console.log("999");
                        this.loading = false;
                    }
                });
                // this.loading = false;

            },
            addInfo() {
                var record = 0;
                for (var j in this.recordForm.properties) {

                    if (this.recordForm.properties[j] > 0) {
                        var record = 1;
                        break;
                    }
                }
                if (record > 0) {

                    var submit = true;
                    for (var j in this.recordForm.properties) {
                        if (this.remainingAssets[j] != undefined && this.recordForm.properties[j] > this.remainingAssets[j]) {
                            this.assetsTotalAreUsed = false;
                            submit = false;
                        }
                        if (this.remainingAssets[j] != undefined && this.recordForm.properties[j] > this.remainingAssets[j]) {
                            this.remainingAssets[j] += parseInt(this.editableProperty[j]);
                            if (this.remainingAssets[j] != undefined && this.recordForm.properties[j] > this.remainingAssets[j]) {
                                this.assetsTotalAreUsed = false;
                                submit = false;
                            } else {
                                submit = true;
                            }
                        }
                    }

                    if (submit == true) {

                        var record = {};
                        for (const cnfg in this.recordForm) {
                            record = _.merge(record, {[cnfg]: this.recordForm[cnfg]})
                        }
                        if (this.recordIndex == null) {
                            record.id = "SP" + new Date().valueOf();
                            this.liability_info.records.push(record);
                        } else {
                            this.liability_info.records[this.recordIndex] = record;
                            this.recordIndex = null;
                        }
                        this.generateRemainingAssets();
                        this.recordForm.properties = {};
                        this.recordIndex = null;
                        this.resetForm();

                     }
                } else {
                    this.propert_message = 'please allocate atleast 1 property'
                }
            },
            submit(addInfo = null) {
                window.scrollTo({top: 0, behavior: 'smooth'});
                if (this.liability_info.add_liability == 'Yes' && this.liability_info.records.length == 0) {
                    this.$emit('changeActiveTab', this.activeTab + 1);
                } else {
                    if (this.liability_info.add_liability != 'Yes' && this.usedTotalMessage == '') {
                        this.loading = true;
                        this.form = new Form({activeTab: this.activeTab, liability_info: this.liability_info});
                        if (this.id != 0) {
                            this.$emit('changeActiveTab', this.activeTab + 1);
                        } else {
                            this.form.post('/api/will/formData/add')
                                .then((response) => {
                                    this.loading = false;
                                    if (response.errors == false) {
                                        if (addInfo != 'Yes') {
                                            this.$emit('changeActiveTab', this.activeTab + 1);
                                            this.$emit('updateFormData', response.data);
                                        }
                                        this.generateRemainingAssets();
                                    }
                                })
                                .catch((error) => {
                                    this.loading = false;
                                });
                        }
                    } else {
                        this.$emit('changeActiveTab', this.activeTab + 1);
                    }
                }
            }
        },
        mounted() {
            this.loading = true;
            if (this.data != undefined && this.data.liability_info != undefined) {
                this.liability_info = this.data.liability_info;
                this.liability_info.add_liability = 'Yes';
                this.generateRemainingAssets();
            }
            if(this.data != undefined && this.data.property_info != undefined){
                console.log("starts....");
                console.log("data starts", this.data.property_info.records);
                this.data.property_info.records.forEach(element => {
                    if(element.asset_type == 'Land' || element.asset_type == 'House' || element.asset_type == 'Commercial Property' || element.asset_type == 'Other Immovable'){
                        this.immovableCount++;
                    }else{
                        this.movableCount++;
                    }
                });
            }
            this.loading = false;
        },
        watch: {
            'liability_info.add_liability': function () {
                if (this.liability_info.add_liability == "No") {
                    this.liability_info.records = [];
                }
            },
            'recordForm.title': function () {
                if (this.recordForm.title != "Other") {
                    if (this.recordForm.other_title != '') {
                        this.recordForm.other_title = ''
                    }
                }
            }
        }
    };

</script>
<style>
</style>
