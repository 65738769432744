export default {
    methods:{
        makeAddress(address1,address2,state,city,pincode){
            if((typeof address2 !== undefined && typeof pincode !== undefined) && (address2 != null && pincode != null) && address2 != '' && pincode != '') {
                var address = address1 + ', ' + address2 + ', ' + state + ', ' + city + ', ' + pincode;
            }else if(address2 != undefined && address2 != null && address2 != ''){
                var address = address1 + ', ' + address2 + ', ' + state + ', ' + city;
            }else if(pincode != undefined && pincode != null && pincode != ''){
                var address = address1 + ', ' + state + ', ' + city + ', ' + pincode;
            }else{
                var address = address1 + ', ' + state + ', ' + city;
            }
            return address;
        },
        makeName(title,firsrt_name,middle_name,last_name){
            if(middle_name != undefined){
                var fullName = title + '. ' +firsrt_name  + ' ' + middle_name + ' ' + last_name;
            }else{
                var fullName = title + '. ' +firsrt_name  + ' ' + last_name;
            }
            return fullName;
        },
    }
}
