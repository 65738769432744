<template>
    <div class="row">
        <div class="col-xl-12 col-md-12 col-sm-12 col-12">
            <el-form v-loading="loading" ref="beneficiaryForm" :model="beneficiaryForm" label-width="120px"
                     label-position="top">
                <!-- Beneficiary Details -->
                <div class="row">
                    <div class="col-sm-12 col-md-12 col-lg-8">
                        <div class="wizard-des">
                            <p>
                                Please add further details of your beneficiaries
                            </p>
                        </div>
                        <div class="radio-details-tab  tab-card">
                            <span class="will-radio-title">Would you like to add any beneficiary other than your spouse/children?</span><br>

                            <div class="yn-radio mt-2">
                                <el-radio v-model="beneficiary_info.add_beneficiary" label="Yes">Yes</el-radio>
                                <el-radio v-model="beneficiary_info.add_beneficiary" label="No">No</el-radio>
                            </div>
                        </div>
                        <div v-if="beneficiary_info.add_beneficiary == 'Yes'" class="children-details-tab tab-card">
                            <h2 class="will-create-title">Beneficiary Details</h2>
                            <div class="form-row">
                                <div class="form-group col-3 col-sm-2 col-md-2 col-lg-2">
                                    <el-form-item label="Title" prop="title" :rules="getTitleRule('Title')">
                                        <el-select v-model="beneficiaryForm.title">
                                            <el-option label="Mr" value="Mr"></el-option>
                                            <el-option label="Mrs" value="Mrs"></el-option>
                                            <el-option label="Ms" value="Ms"></el-option>
                                            <el-option label="Miss" value="Miss"></el-option>
                                            <el-option label="Master" value="Master"></el-option>
                                            <!--<el-option label="Dr" value="Dr"></el-option>-->
<!--                                            <el-option label="Other" value="Other"></el-option>-->
                                        </el-select>
                                        <div v-if="form.errors.has('title')" class="el-form-item__error"
                                             v-text="form.errors.first('title')"></div>
                                    </el-form-item>
                                </div>
                                <div class="form-group col-9 col-sm-4 col-md-4 col-lg-4">
                                    <el-form-item label="First name" prop="first_name"
                                                  :rules="getFieldRule('First name')">
                                        <el-input maxlength="20" @input="value => beneficiaryForm.first_name = value"
                                                  :value="beneficiaryForm.first_name | capitalize" type="text"
                                                  placeholder="First Name"></el-input>
                                        <div v-if="form.errors.has('first_name')" class="el-form-item__error"
                                             v-text="form.errors.first('first_name')"></div>
                                    </el-form-item>
                                </div>
                                <div class="form-group col-sm-6 col-md-6 col-lg-6">
                                    <el-form-item label="Last name" prop="last_name" :rules="getFieldRule('Last name')">
                                        <el-input maxlength="20" @input="value => beneficiaryForm.last_name = value"
                                                  :value="beneficiaryForm.last_name | capitalize" type="text"
                                                  placeholder="Last name"></el-input>
                                        <div v-if="form.errors.has('last_name')" class="el-form-item__error"
                                             v-text="form.errors.first('last_name')"></div>
                                    </el-form-item>
                                </div>
                                <!--<div class="form-group col-3 col-sm-2 col-md-2 col-lg-2"  v-if="beneficiaryForm.title == 'Other'">
                                    <el-form-item label="Other title" prop="other_title" :rules="getFieldRule('Title')">
                                        <el-input v-model="beneficiaryForm.other_title" type="text"
                                                  placeholder="other title"></el-input>
                                    </el-form-item>
                                </div>
                                <div class="form-group col-sm-6 col-md-6 col-lg-6"  v-if="beneficiaryForm.title == 'Other'">
                                </div>
                                <div class="form-group col-9 col-sm-4 col-md-4 col-lg-4"  v-if="beneficiaryForm.title == 'Other'">
                                </div>-->
                                <div class="form-group col-sm-6 col-md-6 col-lg-6">
                                    <el-form-item label="Middle name">
                                        <el-input maxlength="20" @input="value => beneficiaryForm.middle_name = value"
                                                  :value="beneficiaryForm.middle_name | capitalize" type="text"
                                                  placeholder="Middle name"></el-input>
                                        <div v-if="form.errors.has('middle_name')" class="el-form-item__error"
                                             v-text="form.errors.first('middle_name')"></div>

                                    </el-form-item>
                                </div>
                                <div class="form-group col-sm-6 col-md-6 col-lg-6">
                                    <el-form-item label="Gender" prop="gender" :rules="getFieldRule('Gender')">
                                        <el-radio v-model="beneficiaryForm.gender" label="Male">Male</el-radio>
                                        <el-radio v-model="beneficiaryForm.gender" label="Female">Female</el-radio>
                                    </el-form-item>
                                </div>
                                <div class="form-group col-sm-6 col-md-6 col-lg-6">
                                    <el-form-item label="Relationship" prop="relationship"
                                                  :rules="getFieldRule('relationship')">
                                        <el-input maxlength="20" @input="value => beneficiaryForm.relationship = value"
                                                  :value="beneficiaryForm.relationship | capitalize" type="text"
                                                  placeholder="Relationship"></el-input>
                                        <div maxlength="20" v-if="form.errors.has('relationship')"
                                             class="el-form-item__error"
                                             v-text="form.errors.first('relationship')"></div>
                                    </el-form-item>

                                </div>
                               <!-- <div class="col-sm-6 col-md-6 col-lg-6 type-tooltip">
                                   <div class="tooltips">
                                        <span class="fa fa-question fa-tooltip"></span>
                                        <div class="tooltip-box">
                                            The person named under <b>“Secondary”</b> will have
                                            the powers only if your selected primary person(s)
                                            predecease you.
                                        </div>
                                    </div>
                                    <el-form-item label="Type" prop="type" :rules="getFieldRule('Type')">
                                        <el-radio v-model="beneficiaryForm.type" label="Primary">Primary</el-radio>
                                        <el-radio v-model="beneficiaryForm.type" label="Secondary">Secondary</el-radio>
                                    </el-form-item>
                                </div> -->
                            </div>
                            <h2 class="will-create-subtitle">Address<el-button type="text" class="add-copy">(To copy your address please click <span @click="copybeneficiaryAddress()" class="click_here">here</span>)</el-button></h2>
                            <div class="form-row">
                                <div class="form-group col-sm-6 col-md-6 col-lg-6">
                                    <el-form-item label="Address 1" prop="address_1" :rules="getFieldRule('Address 1')">
                                        <el-input maxlength="50" @input="value => beneficiaryForm.address_1 = value"
                                                  :value="beneficiaryForm.address_1 | capitalize"
                                                  placeholder="Address 1"></el-input>
                                        <div v-if="form.errors.has('address_1')" class="el-form-item__error"
                                             v-text="form.errors.first('address_1')"></div>
                                    </el-form-item>
                                </div>
                                <div class="form-group col-sm-6 col-md-6 col-lg-6">
                                    <el-form-item label="Address 2" prop="address_2">
                                        <el-input maxlength="50"@input="value => beneficiaryForm.address_2 = value"
                                                  :value="beneficiaryForm.address_2 | capitalize"
                                                  placeholder="Address 2"></el-input>
                                    </el-form-item>
                                </div>
                                <div class="form-group col-sm-3 col-md-3 col-lg-3">
                                    <el-form-item label="City" prop="city" :rules="getFieldRule('City')">
                                        <el-input maxlength="20" @input="value => beneficiaryForm.city = value"
                                                  :value="beneficiaryForm.city | capitalize"
                                                  placeholder="City"></el-input>
                                        <div v-if="form.errors.has('city')" class="el-form-item__error"
                                             v-text="form.errors.first('title')"></div>
                                    </el-form-item>
                                </div>
                                <div class="form-group col-sm-3 col-md-3 col-lg-3">
                                    <el-form-item label="State" prop="state" :rules="getFieldRule('State')">
                                        <!--                                        <el-select filterable  allow-create v-model="beneficiaryForm.state" placeholder="State">-->
                                        <!--                                            <el-option-->
                                        <!--                                                    v-for="(record,index) in states"-->
                                        <!--                                                    v-bind:key="index"-->
                                        <!--                                                    :label="record.state"-->
                                        <!--                                                    v-bind:value="record.state">-->
                                        <!--                                            </el-option>-->
                                        <!--                                        </el-select>-->
                                        <el-input maxlength="20" @input="value => beneficiaryForm.state = value"
                                                  :value="beneficiaryForm.state | capitalize"
                                                  placeholder="State"></el-input>

                                        <div v-if="form.errors.has('state')" class="el-form-item__error"
                                             v-text="form.errors.first('title')"></div>
                                    </el-form-item>
                                </div>

                                <div class="form-group col-sm-3 col-md- col-lg-3">
                                    <el-form-item label="Pin Code" prop="pin_code">
                                        <el-input maxlength="10" v-model="beneficiaryForm.pin_code"
                                                  placeholder="Pin code"></el-input>
                                       <!-- <div v-if="form.errors.has('pin_code')" class="el-form-item__error"
                                             v-text="form.errors.first('pin_code')"></div>-->
                                    </el-form-item>
                                </div>
                                <div class="form-group col-sm-3 col-md-3 col-lg-3">
                                    <el-form-item label="Country" prop="country" :rules="getFieldRule('Country')">
                                        <!--                                        <el-select v-model="beneficiaryForm.country">-->
                                        <!--                                            <el-option label="India" value="India"></el-option>-->
                                        <!--                                        </el-select>-->
                                        <el-input maxlength="20" @input="value => beneficiaryForm.country = value"
                                                  :value="beneficiaryForm.country | capitalize"
                                                  placeholder="Country"></el-input>

                                        <div v-if="form.errors.has('country')" class="el-form-item__error"
                                             v-text="form.errors.first('country')"></div>
                                    </el-form-item>
                                </div>

                            </div>
                        </div>

                        <div v-if="beneficiary_info.add_beneficiary == 'Yes'" class="will-btn-group row">
                            <div class="col-3 col-sm-6 col-md-6 col-lg-6 text-left">
                                <el-button class="delete-btn mini-btn btn btn-sm" v-if="beneficiaryRecordIndex != null"
                                           @click="deleteItem('beneficary',beneficiaryRecordIndex)">delete
                                </el-button>
                            </div>
                            <div class="col-9 col-sm-6 col-md-6 col-lg-6 text-right">
                                <el-button v-if="beneficiaryRecordIndex != null"
                                           class="cancel-btn  mini-btn btn mr15 btn-sm" @click="cancel('beneficary')">
                                    cancel
                                </el-button>
                                <el-button v-if="beneficiaryRecordIndex == null" title="Add Details" class="add-btn  mini-btn btn btn-sm"
                                           :loading="loading" :disabled="loading" @click="addRecord('beneficary')">Add Beneficiary
                                </el-button>
                                <el-button v-else  title="Update Details" class="add-btn  mini-btn btn btn-sm" :loading="loading" :disabled="loading" @click="addRecord('beneficary')">
                                    Update Beneficiary
                                </el-button>
                            </div>
                        </div>
                        <div v-if="message != ''" class="personalinfo-tab tab-card">
                            <h2 class="will-create-title error-message text-center">{{ message }}</h2>
                        </div>
                    </div>
                    <div class="col-sm-12 col-md-12 col-lg-4 position-relative">
                        <div class="will-form-image">
                            <div class="will-toon">
                                <img src="/themes/flatly/images/will4.svg" class="">
                            </div>
                        </div>
                        <div class="document-img">
                            <img src="/themes/flatly/images/document.svg">
                        </div>
                        <div v-if="beneficiary_info.add_beneficiary  == 'Yes' && beneficiary_info.beneficiaries.length > allocatedBeneficiaries"
                             class="details-add">
                            <h3 class="details-add-title">Beneficiaries</h3>
                            <div v-for="(info,index) in beneficiary_info.beneficiaries"
                                 class="tab-card guardian-details-list details-list" v-if="info.type !== 'primary'">
                                <h2 class="guardian-name"> {{
                                    makeName(info.title,info.first_name,info.middle_name,info.last_name) }}</h2>
                                <span class="guardian-relation">{{ info.relationship }}</span>
<!--                                <span class="guardian-type">{{info.type}}</span>-->
                                <!--                                            <span class="guardian-type">{{info.}}</span>-->
                                <p class="guardian-address">{{
                                    makeAddress(info.address_1,info.address_2,info.state,info.city,info.pin_code) }}</p>
                                <el-button type="text" title="Update Details" @click="editRecord('beneficary',index)"><a
                                        class="fa fa-pencil"></a></el-button>
                            </div>
                        </div>

                    </div>
                </div>
            </el-form>
            <el-form v-loading="loading" ref="specialCluaseForm" :model="specialCluaseForm" label-width="120px"
                     label-position="top">

                <!-- Special Clause -->
                <div class="row">
                    <div class="col-sm-12 col-md-12 col-lg-8">
                        <div class="radio-details-tab  tab-card">
                            <span class="will-radio-title">Do you want to omit any of your heirs from your Will?</span>
                            <p><b>WARNING</b>: The individual(s) entered below will be fully omitted from your Will.</p>

                            <div class="yn-radio mt-2">
                                <el-radio v-model="beneficiary_info.appoint_special" label="Yes">Yes</el-radio>
                                <el-radio v-model="beneficiary_info.appoint_special" label="No">No</el-radio>
                            </div>
                        </div>
                        <div v-if="beneficiary_info.appoint_special == 'Yes'" class="children-details-tab tab-card">
                            <h2 class="will-create-title">Special Clause</h2>
                            <div class="form-row">
                                <div class="form-group col-3 col-sm-2 col-md-2 col-lg-2">
                                    <el-form-item label="Title" prop="title" :rules="getTitleRule('Title')">
                                        <el-select v-model="specialCluaseForm.title">
                                            <el-option label="Mr" value="Mr"></el-option>
                                            <el-option label="Mrs" value="Mrs"></el-option>
                                            <el-option label="Ms" value="Ms"></el-option>
                                            <el-option label="Miss" value="Miss"></el-option>
                                            <el-option label="Master" value="Master"></el-option>
                                            <!--<el-option label="Dr" value="Dr"></el-option>-->
                                            <!--<el-option label="Other" value="Other"></el-option>-->
                                        </el-select>
                                        <div v-if="form.errors.has('title')" class="el-form-item__error"
                                             v-text="form.errors.first('title')"></div>
                                    </el-form-item>
                                </div>
                                <div class="form-group col-9 col-sm-4 col-md-4 col-lg-4">
                                    <el-form-item label="First name" prop="first_name"
                                                  :rules="getFieldRule('First name')">
                                        <el-input maxlength="20" @input="value => specialCluaseForm.first_name = value"
                                                  :value="specialCluaseForm.first_name | capitalize" type="text"
                                                  placeholder="First Name"></el-input>
                                        <div v-if="form.errors.has('first_name')" class="el-form-item__error"
                                             v-text="form.errors.first('first_name')"></div>
                                    </el-form-item>
                                </div>
                                <div class="form-group col-sm-6 col-md-6 col-lg-6">
                                    <el-form-item label="Last name" prop="last_name" :rules="getFieldRule('Last name')">
                                        <el-input maxlength="20" @input="value => specialCluaseForm.last_name = value"
                                                  :value="specialCluaseForm.last_name | capitalize" type="text"
                                                  placeholder="Last name"></el-input>
                                        <div v-if="form.errors.has('last_name')" class="el-form-item__error"
                                             v-text="form.errors.first('last_name')"></div>
                                    </el-form-item>
                                </div>
                                <!--<div class="form-group col-3 col-sm-2 col-md-2 col-lg-2"  v-if="specialCluaseForm.title == 'Other'">
                                    <el-form-item label="Other title" prop="other_title" :rules="getFieldRule('Title')">
                                        <el-input v-model="specialCluaseForm.other_title" type="text"
                                                  placeholder="other title"></el-input>
                                    </el-form-item>
                                </div>
                                <div class="form-group col-sm-6 col-md-6 col-lg-6"  v-if="specialCluaseForm.title == 'Other'">
                                </div>
                                <div class="form-group col-9 col-sm-4 col-md-4 col-lg-4"  v-if="specialCluaseForm.title == 'Other'">
                                </div>-->
                                <div class="form-group col-sm-6 col-md-6 col-lg-6">
                                    <el-form-item label="Middle name">
                                        <el-input maxlength="20" @input="value => specialCluaseForm.middle_name = value"
                                                  :value="specialCluaseForm.middle_name | capitalize" type="text"
                                                  placeholder="Middle name"></el-input>
                                        <div v-if="form.errors.has('middle_name')" class="el-form-item__error"
                                             v-text="form.errors.first('middle_name')"></div>

                                    </el-form-item>
                                </div>
                                <div class="form-group col-sm-6 col-md-6 col-lg-6">
                                    <el-form-item label="Relationship" prop="relationship"
                                                  :rules="getFieldRule('relationship')">
                                        <el-input maxlength="20" @input="value => specialCluaseForm.relationship = value"
                                                  :value="specialCluaseForm.relationship | capitalize" type="text"
                                                  placeholder="Relationship" ></el-input>
                                        <div v-if="form.errors.has('relationship')" class="el-form-item__error"
                                             v-text="form.errors.first('relationship')"></div>
                                    </el-form-item>

                                </div>
                            </div>

                            <h2 class="will-create-subtitle">Address<el-button type="text" class="add-copy">(To copy your address please click <span @click="copyspecialCluaseAddress()" class="click_here">here</span>)</el-button></h2>
                            <div class="form-row">
                                <div class="form-group col-sm-6 col-md-6 col-lg-6">
                                    <el-form-item label="Address 1" prop="address_1" :rules="getFieldRule('Address 1')">
                                        <el-input maxlength="50"  @input="value => specialCluaseForm.address_1 = value"
                                                  :value="specialCluaseForm.address_1 | capitalize"
                                                  placeholder="Address 1"></el-input>
                                        <div v-if="form.errors.has('address_1')" class="el-form-item__error"
                                             v-text="form.errors.first('address_1')"></div>
                                    </el-form-item>
                                </div>
                                <div class="form-group col-sm-6 col-md-6 col-lg-6">
                                    <el-form-item label="Address 2" prop="address_2">
                                        <el-input maxlength="50" @input="value => specialCluaseForm.address_2 = value"
                                                  :value="specialCluaseForm.address_2 | capitalize"
                                                  placeholder="Address 2"></el-input>
                                    </el-form-item>
                                </div>
                                <div class="form-group col-sm-3 col-md-3 col-lg-3">
                                    <el-form-item label="City" prop="city" :rules="getFieldRule('City')">
                                        <el-input maxlength="20"  @input="value => specialCluaseForm.city = value"
                                                  :value="specialCluaseForm.city | capitalize"
                                                  placeholder="City"></el-input>
                                        <div v-if="form.errors.has('city')" class="el-form-item__error"
                                             v-text="form.errors.first('city')"></div>
                                    </el-form-item>
                                </div>
                                <div class="form-group col-sm-3 col-md-3 col-lg-3">
                                    <el-form-item label="State" prop="state" :rules="getFieldRule('State')">
                                        <!--<el-select filterable allow-create v-model="specialCluaseForm.state" placeholder="State">
                                            <el-option
                                                    v-for="(record,index) in states"
                                                    v-bind:key="index"
                                                    :label="record.state"
                                                    v-bind:value="record.state">
                                            </el-option>
                                        </el-select>-->
                                        <el-input maxlength="20"  @input="value => specialCluaseForm.state = value"
                                                  :value="specialCluaseForm.state | capitalize"
                                                  placeholder="State"></el-input>

                                        <div v-if="form.errors.has('state')" class="el-form-item__error"
                                             v-text="form.errors.first('state')"></div>
                                    </el-form-item>
                                </div>

                                <div class="form-group col-sm-3 col-md- col-lg-3">
                                    <el-form-item label="Pin Code" prop="pin_code">
                                        <el-input maxlength="10" v-model="specialCluaseForm.pin_code"
                                                  placeholder="Pin code"></el-input>
                                      <!--  <div v-if="form.errors.has('pin_code')" class="el-form-item__error"
                                             v-text="form.errors.first('pin_code')"></div>-->
                                    </el-form-item>
                                </div>
                                <div class="form-group col-sm-3 col-md-3 col-lg-3">
                                    <el-form-item label="Country" prop="country" :rules="getFieldRule('Country')">
                                        <!--<el-select v-model="specialCluaseForm.country">
                                            <el-option label="India" value="India"></el-option>
                                        </el-select>-->
                                        <el-input maxlength="20"  @input="value => specialCluaseForm.country = value"
                                                  :value="specialCluaseForm.country | capitalize"
                                                  placeholder="Country"></el-input>

                                        <div v-if="form.errors.has('country')" class="el-form-item__error"
                                             v-text="form.errors.first('country')"></div>
                                    </el-form-item>
                                </div>

                            </div>
                        </div>

                        <div v-if="beneficiary_info.appoint_special == 'Yes'" class="will-btn-group row">

                            <div class="col-3 col-sm-6 col-md-6 col-lg-6 text-left">
                                <el-button class="delete-btn mini-btn btn btn-sm"
                                           v-if="specialClauseRecordIndex != null"
                                           @click="deleteItem('specialCluase',specialClauseRecordIndex)">delete
                                </el-button>
                            </div>
                            <div class="col-9 col-sm-6 col-md-6 col-lg-6 text-right">
                                <el-button v-if="specialClauseRecordIndex != null"
                                           class="cancel-btn  mini-btn btn mr15 btn-sm"
                                           @click="cancel('specialCluase')">cancel
                                </el-button>
                                <el-button title="Add Details" v-if="specialClauseRecordIndex == null" class="add-btn  mini-btn btn btn-sm"
                                           @click="addRecord('specialCluase')">Add Special
                                    Clause
                                </el-button>
                                <el-button v-else class="add-btn  mini-btn btn btn-sm"
                                           @click="addRecord('specialCluase')">Update Special Clause
                                </el-button>
                            </div>

                        </div>
                        <div v-if="specialmessage != ''" class="personalinfo-tab tab-card">
                            <h2 class="will-create-title error-message text-center">{{ specialmessage }}</h2>
                        </div>
                    </div>
                    <div class="col-sm-12 col-md-12 col-lg-4 position-relative">
                        <div class="document-img">
                            <img src="/themes/flatly/images/document.svg">
                        </div>
                        <div v-if="beneficiary_info.appoint_special == 'Yes' && beneficiary_info.specialclauses.length > 0 && beneficiary_info.specialclauses != undefined"
                             class="details-add">
                            <h3 class="details-add-title">Excluded from Will</h3>
                            <div v-for="(info,index) in beneficiary_info.specialclauses"
                                 class="tab-card guardian-details-list details-list">
                                <h2 class="guardian-name"> {{
                                    makeName(info.title,info.first_name,info.middle_name,info.last_name) }}</h2>
                                <span class="guardian-relation">{{ info.relationship }}</span>
                                <p class="guardian-address">{{
                                    makeAddress(info.address_1,info.address_2,info.state,info.city,info.pin_code) }}</p>
                                <el-button type="text" title="Update Details" @click="editRecord('specialCluase',index)"><a
                                        class="fa fa-pencil"></a></el-button>
                            </div>
                        </div>

                    </div>
                </div>

                <div class="row">
                    <div class="col-sm-12 col-md-12 col-lg-12">
                        <div class="will-rext-prev-btn">
                            <el-button class="btn btn-prev active btn-nex-prev" @click="backStep()"><i
                                    class="fa fa-angle-left"></i></el-button>
                            <el-button class="btn btn-next btn-nex-prev" @click="submit()"><i
                                    class="fa fa-angle-right"></i></el-button>
                        </div>
                    </div>
                </div>
            </el-form>
        </div>
    </div>
</template>

<script>
    import Form from 'form-backend-validation';
    import Common from '../../mixins/commonMethod';
    import {MessageBox} from "element-ui";

    var uuid = require('uuid');

    export default {
        name: "step4",
        props: ['data', 'states', 'id'],
        mixins: [Common],
        data() {
            return {
                activeTab: 4,
                loading: false,
                form: new Form(),
                beneficiaryForm: {
                    title: '',
                    type: '',
                    first_name: '',
                    other_title: '',
                    last_name: '',
                    middle_name: '',
                    relationship: '',
                    address_1: '',
                    address_2: '',
                    gender: 'Male',
                    city: '',
                    state: '',
                    pin_code: '',
                    country: '',

                },
                specialCluaseForm: {
                    title: '',
                    other_title: '',
                    first_name: '',
                    last_name: '',
                    middle_name: '',
                    relationship: '',
                    address_1: '',
                    address_2: '',
                    city: '',
                    state: '',
                    pin_code: '',
                    country: '',
                },
                deleteCluaseIndex: 0,
                allocatedBeneficiaries: 0,
                specialmessage: '',
                message: '',
                specialClauseRecordIndex: null,
                deleteBeneficiaryIndex: 0,
                beneficiaryRecordIndex: null,
                beneficiary_info: {
                    appoint_special: 'No',
                    add_beneficiary: 'No',
                    beneficiaries: [],
                    specialclauses: []
                }
            }
        },
        filters: {
            capitalize: function (value) {
                if (!value) return ''
                // value = value.toString().toLowerCase()
                return value.charAt(0).toUpperCase() + value.slice(1)
            }
        },
        methods: {
            copybeneficiaryAddress(){
                this.beneficiaryForm.address_1 = this.data.personal_info.personal_info.address_1;
                this.beneficiaryForm.address_2 = this.data.personal_info.personal_info.address_2;
                this.beneficiaryForm.city = this.data.personal_info.personal_info.city;
                this.beneficiaryForm.state = this.data.personal_info.personal_info.state;
                this.beneficiaryForm.country = this.data.personal_info.personal_info.country;
                this.beneficiaryForm.pin_code = this.data.personal_info.personal_info.pin_code;
            },

            copyspecialCluaseAddress(){
                this.specialCluaseForm.address_1 = this.data.personal_info.personal_info.address_1;
                this.specialCluaseForm.address_2 = this.data.personal_info.personal_info.address_2;
                this.specialCluaseForm.city = this.data.personal_info.personal_info.city;
                this.specialCluaseForm.state = this.data.personal_info.personal_info.state;
                this.specialCluaseForm.country = this.data.personal_info.personal_info.country;
                this.specialCluaseForm.pin_code = this.data.personal_info.personal_info.pin_code;
            },

            deleteItem(type,index) {
                MessageBox.confirm(`Do you really want to delete?`,
                    "Confirmation",
                    {
                        confirmButtonText: "OK",
                        cancelButtonText: "Cancel",
                        type: "warning"
                    }).then(() => {
                    this.deleteRecord(type,index);
                }).catch(() => {
                });
            },
            cancel(type) {
                if (type == 'beneficary') {
                    this.beneficiaryRecordIndex = null;
                } else {
                    this.specialClauseRecordIndex = null;
                }
                this.resetForm(type);
            },
            backStep() {
                this.$emit('changeActiveTab', this.activeTab - 1);
            },
            getFieldRule(Title, FieldName = null, FieldType = null) {
                return {
                    required: true,
                    message: `${Title} field is required`,
                };
            },
            editRecord(type, index) {
                if (type == 'beneficary') {
                    this.beneficiaryRecordIndex = index;
                    this.beneficiary_info.add_beneficiary = 'Yes';

                    var record = {};
                    for (const cnfg in this.beneficiary_info.beneficiaries[index]) {
                        record = _.merge(record, {[cnfg]: this.beneficiary_info.beneficiaries[index][cnfg]})
                    }

                    this.beneficiaryForm = record;
                } else {
                    this.specialClauseRecordIndex = index;
                    this.beneficiary_info.appoint_special = 'Yes';

                    var record = {};
                    for (const cnfg in this.beneficiary_info.specialclauses[index]) {
                        record = _.merge(record, {[cnfg]: this.beneficiary_info.specialclauses[index][cnfg]})
                    }

                    this.specialCluaseForm = record;
                }
            },
            deleteRecord(type, deleteIndex) {
                if (type == 'beneficary') {
                    this.beneficiary_info.beneficiaries.splice(deleteIndex, 1);
                    this.beneficiaryRecordIndex = null;
                } else {
                    this.beneficiary_info.specialclauses.splice(deleteIndex, 1);
                    this.specialClauseRecordIndex = null;
                }
                this.resetForm(type);
                if(this.beneficiary_info.beneficiaries.length == 0)
                    this.beneficiary_info.add_beneficiary = 'No';
                if(this.beneficiary_info.specialclauses.length == 0)
                    this.beneficiary_info.appoint_special == 'No';
                this.submit(type,'Yes');
            },
            getTitleRule(Title, FieldName = null, FieldType = null) {
                return {
                    required: true,
                    message: `field required`,
                };
            },
            resetForm(type) {

                if (type == 'beneficary') {
                    this.$refs.beneficiaryForm.resetFields();
                    this.beneficiaryForm.middle_name = '';
                } else {
                    this.$refs.specialCluaseForm.resetFields();
                    this.specialCluaseForm.middle_name = '';
                }


            },
            addRecord(type) {
                if(this.loading == true)
                    return false;
                this.loading = true;
                this.message = '';
                this.specialmessage = '';
                if (type == 'beneficary') {
                    this.form = new Form({activeTab: this.activeTab, beneficiary_record: this.beneficiaryForm});
                    this.$refs.beneficiaryForm.validate((valid) => {
                        console.log('id', this.id); 
                        // alert("this.id"+this.id);
                        if (valid) {
                            if (this.id != 0) {
                                this.form.post(route('api.will.wills.savedata', {id: this.id}))

                                    .then((response) => {
                                        console.log('id', this.id);
                                        this.loading = false;
                                        if (response.errors == false) {
                                            this.addInfo(type);
                                        }
                                        else{
                                            if(response.dashboardRoute != undefined){
                                                window.location = (response.dashboardRoute);
                                            }
                                        }
                                    })
                                    .catch((error) => {
                                        this.loading = false;
                                    });
                            } else {
                                this.form.post('/api/will/formData/add')
                                    .then((response) => {
                                        console.log("after adding", response);
                                        this.loading = false;
                                        if (response.errors == false) {
                                            this.message = '';
                                            this.addInfo(type);
                                        } else {
                                            if (response.dashboardRoute != undefined) {
                                                window.location = (response.dashboardRoute);
                                            }
                                        }
                                    })
                                    .catch((error) => {
                                        this.loading = false;
                                    });
                            }
                        }
                        else{
                            this.loading = false;
                        }
                    });

                } else {
                    this.form = new Form({activeTab: this.activeTab, specialclauses_record: this.specialCluaseForm});
                    this.$refs.specialCluaseForm.validate((valid) => {
                        if (valid) {
                            if (this.id != 0) {
                                console.log('id', this.id);
                                this.form.post(route('api.will.wills.savedata', {id: this.id}))

                                    .then((response) => {
                                        console.log('id', this.id);
                                        this.loading = false;
                                        if (response.errors == false) {
                                            this.addInfo(type);
                                        }
                                    })
                                    .catch((error) => {
                                        this.loading = false;
                                    });
                            } else {
                                this.form.post('/api/will/formData/add')
                                    .then((response) => {
                                        this.loading = false;
                                        if (response.errors == false) {
                                            this.message = '';
                                            this.addInfo(type);
                                        } else {
                                            if (response.dashboardRoute != undefined) {
                                                window.location = (response.dashboardRoute);
                                            }
                                        }
                                    })
                                    .catch((error) => {
                                        this.loading = false;
                                    });
                            }
                        }
                        else{
                            this.loading = false;
                        }
                    });
                }
                // this.loading = false;
            },
            addInfo(type) {
                if (type == 'beneficary') {

                    var record = {};
                    console.log("beneficiary form", this.beneficiaryForm);
                    for (const cnfg in this.beneficiaryForm) {
                        record = _.merge(record, {[cnfg]: this.beneficiaryForm[cnfg]})
                    }
                    console.log("beneficiary record index", this.beneficiaryRecordIndex);
                    console.log("beneficiary record", record);
                    if (this.beneficiaryRecordIndex == null) {
                        record.id = "PB" + new Date().valueOf();
                        this.beneficiary_info.beneficiaries.push(record);
                        console.log("this.beneficaryinfo,beneficiaries", this.beneficiary_info.beneficiaries);
                    } else {
                        this.beneficiary_info.beneficiaries[this.beneficiaryRecordIndex] = record;
                        this.beneficiaryRecordIndex = null;
                    }
                    this.resetForm(type);
                } else {

                    var record = {};
                    for (const cnfg in this.specialCluaseForm) {
                        record = _.merge(record, {[cnfg]: this.specialCluaseForm[cnfg]})
                    }

                    if (this.specialClauseRecordIndex == null) {
                        record.id = "SB" + new Date().valueOf();
                        this.beneficiary_info.specialclauses.push(record);
                    } else {
                        this.beneficiary_info.specialclauses[this.specialClauseRecordIndex] = record;
                        this.specialClauseRecordIndex = null;
                    }
                    this.resetForm(type);
                }

            this.submit(type, 'Yes');
        },
        submit(type, addInfo = null) {
            window.scrollTo({top: 0, behavior: 'smooth'});
            this.message = '';
            this.specialmessage = '';
            if ((this.beneficiary_info.add_beneficiary == 'Yes' && this.beneficiary_info.beneficiaries.length == this.allocatedBeneficiaries) || (this.beneficiary_info.appoint_special == 'Yes' && this.beneficiary_info.specialclauses.length == 0)) {
                console.log("submit 1");
                console.log('error');
                this.$refs.beneficiaryForm.validate((valid) => {
                    if (valid) {
                        console.log("submit 2");
                        this.beneficiary_info.add_beneficiary = 'Yes';
                        this.message = "Please click on 'Add Beneficiary' button to save the details";
                    } else {
                        console.log("submit 3");
                        this.beneficiary_info.add_beneficiary = 'Yes';
                    }
                });
                
                if(this.beneficiary_info.appoint_special == 'Yes' && this.beneficiary_info.specialclauses.length == 0) {
                    this.$refs.specialCluaseForm.validate((valid) => {
                        console.log("submit 4");
                        if (valid) {
                            console.log("submit 5");
                            this.beneficiary_info.appoint_special = 'Yes';
                            this.specialmessage = 'First need to click on add specialcluase button';
                        } else {
                            console.log("submit 6");
                            this.beneficiary_info.appoint_special = 'Yes';
                        }
                    });
                }
            } else {
                console.log("submit 7");
                this.loading = true;
                console.log("beneficiary_info:", this.beneficiary_info);
                this.form = new Form({activeTab: this.activeTab, beneficiary_info: this.beneficiary_info});
                if (this.id != 0) {
                    console.log("submit 8");
                    console.log('id', this.id);
                    this.form.post(route('api.will.wills.savedata', {id: this.id}))
                        .then((response) => {
                            console.log('id', this.id);
                            this.loading = false;
                            if (response.errors == false) {
                                console.log("submit 9");
                                if (addInfo != 'Yes') {
                                    console.log("submit 10");
                                    this.$emit('changeActiveTab', this.activeTab + 1);
                                    this.$emit('updateFormData', response.data);
                                }
                            }
                        })
                        .catch((error) => {
                            console.log("submit 11");
                            this.loading = false;
                        });
                } else {
                    this.form.post('/api/will/formData/add')
                        .then((response) => {
                            console.log("submit 12");
                            this.loading = false;
                            if (response.errors == false) {
                                console.log("submit 13");
                                this.message = '';
                                if (addInfo != 'Yes') {
                                    console.log("submit 14");
                                    this.$emit('changeActiveTab', this.activeTab + 1);
                                    this.$emit('updateFormData', response.data);
                                }
                            } else {
                                console.log("submit 15");
                                if (response.dashboardRoute != undefined) {
                                    console.log("submit 16");
                                    window.location = (response.dashboardRoute);
                                }
                            }
                        })
                        .catch((error) => {
                            console.log("submit 17");
                            this.loading = false;
                        });
                }
            }
        }
    }
    ,
    mounted()
    {
        this.loading = true;
        if (this.data != undefined && this.data.beneficiary_info != undefined) {
            // this.data.guardian_info = [];
            this.beneficiary_info = this.data.beneficiary_info;

            if (this.beneficiary_info.beneficiaries.length > 0 && this.beneficiary_info.add_beneficiary === 'Yes') {
                this.beneficiary_info.add_beneficiary = 'Yes';
            }
            if (this.beneficiary_info.beneficiaries.length > 0) {
                for(var col in this.beneficiary_info.beneficiaries){
                    if(this.beneficiary_info.beneficiaries[col].type === 'primary'){
                        this.allocatedBeneficiaries ++;
                    }
                }
            }
            if (this.beneficiary_info.specialclauses.length > 0) {
                this.beneficiary_info.appoint_special = 'Yes';
            }
        }
        this.loading = false;

    }
    ,
    watch: {
        'beneficiary_info.add_beneficiary': function () {
            var deleteIds = [];
            if (this.beneficiary_info.add_beneficiary == "No") {
                for(var col in this.beneficiary_info.beneficiaries){
                    if(this.beneficiary_info.beneficiaries[col].type !== 'primary'){
                        deleteIds.push(this.beneficiary_info.beneficiaries[col].id );
                    }
                }
                var count = 0;
                for(var id in deleteIds){
                    for(var col in this.beneficiary_info.beneficiaries){
                        if(this.beneficiary_info.beneficiaries[col].id === id) {
                            this.beneficiary_info.beneficiaries.splice(col, 1);
                        }
                    }
                }
            }
        },
        'beneficiary_info.appoint_special': function () {
            if (this.beneficiary_info.appoint_special == "No") {
                this.beneficiary_info.specialclauses = [];
            }
        },
        'specialCluaseForm.title': function () {
            if (this.specialCluaseForm.title != "Other") {
                if (this.specialCluaseForm.other_title != '') {
                    this.specialCluaseForm.other_title = ''
                }
            }
        },
        'beneficiaryForm.title': function () {
            if (this.beneficiaryForm.title != "Other") {
                if (this.beneficiaryForm.other_title != '') {
                    this.beneficiaryForm.other_title = ''
                }
            }
        }
    }
    }
</script>

<style scoped>

</style>
