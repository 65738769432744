<template>
    <el-form v-loading="loading" :ref="formName" :model="recordForm" label-width="120px" label-position="top">
        <div class="row">
            <div class="col-sm-12 col-md-12 col-lg-8">
                <div class="wizard-des">
                    <p>
                        Please provide the details of your children.
                    </p>
                </div>
                <div class="radio-details-tab tab-card">
                    <span class="will-radio-title pr-3">Do you have children?  </span>
                    <div class="yn-radio">
                        <el-radio v-model="children_info.have_children" label="Yes">Yes</el-radio>
                        <el-radio v-model="children_info.have_children" label="No">No</el-radio>
                    </div>
                </div>
                <div v-if="children_info.have_children == 'Yes'" class="children-details-tab tab-card">
                    <h2 class="will-create-title">children details</h2>
                    <div class="form-row">
                        <div class="form-group col-3 col-sm-2 col-md-2 col-lg-2">
                            <el-form-item label="Title" prop="title" :rules="getTitleRule('Title')">
                                <el-select v-model="recordForm.title">
                                    <el-option label="Mr" value="Mr"></el-option>
                                    <el-option label="Mrs" value="Mrs"></el-option>
                                    <el-option label="Ms" value="Ms"></el-option>
                                    <el-option label="Miss" value="Miss"></el-option>
                                    <el-option label="Master" value="Master"></el-option>
                                    <!--<el-option label="Dr" value="Dr"></el-option>-->
                                    <!--<el-option label="Other" value="Other"></el-option>-->
                                </el-select>
                                <div v-if="form.errors.has('title')" class="el-form-item__error"
                                     v-text="form.errors.first('title')"></div>
                            </el-form-item>
                        </div>
                        <div class="form-group col-9 col-sm-4 col-md-4 col-lg-4">
                            <el-form-item label="First name" prop="first_name" :rules="getFieldRule('First name')">
                                <el-input maxlength="20" @input="value => recordForm.first_name = value"
                                          :value="recordForm.first_name | capitalize" type="text"
                                          placeholder="First Name"></el-input>
                                <div v-if="form.errors.has('first_name')" class="el-form-item__error"
                                     v-text="form.errors.first('first_name')"></div>
                            </el-form-item>
                        </div>
                        <div class="form-group col-sm-6 col-md-6 col-lg-6">
                            <el-form-item label="Last name" prop="last_name" :rules="getFieldRule('Last name')">
                                <el-input maxlength="20" @input="value => recordForm.last_name = value"
                                          :value="recordForm.last_name | capitalize" type="text"
                                          placeholder="Last name"></el-input>
                                <div v-if="form.errors.has('last_name')" class="el-form-item__error"
                                     v-text="form.errors.first('last_name')"></div>
                            </el-form-item>
                        </div>

                        <!--                        <div class="form-group col-3 col-sm-2 col-md-2 col-lg-2"  v-if="recordForm.title == 'Other'">-->
                        <!--                            <el-form-item label="Other title" prop="other_title" :rules="getFieldRule('Title')">-->
                        <!--                                <el-input v-model="recordForm.other_title" type="text"-->
                        <!--                                          placeholder="other title"></el-input>-->
                        <!--                            </el-form-item>-->
                        <!--                        </div>-->
                        <!--                        <div class="form-group col-sm-6 col-md-6 col-lg-6"  v-if="recordForm.title == 'Other'">-->
                        <!--                        </div>-->
                        <!--                        <div class="form-group col-9 col-sm-4 col-md-4 col-lg-4"  v-if="recordForm.title == 'Other'">-->
                        <!--                        </div>-->
                        <div class="form-group col-sm-6 col-md-6 col-lg-6">
                            <el-form-item label="Middle name">
                                <el-input maxlength="20" @input="value => recordForm.middle_name = value"
                                          :value="recordForm.middle_name | capitalize" type="text"
                                          placeholder="Middle name"></el-input>
                                <div v-if="form.errors.has('middle_name')" class="el-form-item__error"
                                     v-text="form.errors.first('middle_name')"></div>
                            </el-form-item>
                        </div>
                        <div class="form-group col-sm-6 col-md-6 col-lg-6">
                            <el-form-item label="Gender" prop="gender" :rules="getFieldRule('Gender')">
                                <el-radio v-model="recordForm.gender" label="Male">Male</el-radio>
                                <el-radio v-model="recordForm.gender" label="Female">Female</el-radio>
                            </el-form-item>
                        </div>
                        <div class="form-group col-sm-6 col-md-6 col-lg-6">
                            <el-form-item label="Date of Birth" prop="date_of_birth"
                                          :rules="getFieldRule('Date of Birth')">
                                <el-date-picker
                                        v-model="recordForm.date_of_birth"
                                        type="date" :clearable="false"
                                        format="yyyy/MM/dd"
                                        :picker-options="datePickerOptions"
                                        value-format="yyyy-MM-dd">
                                </el-date-picker>
                                <div v-if="form.errors.has('date_of_birth')" class="el-form-item__error"
                                     v-text="form.errors.first('date_of_birth')"></div>
                            </el-form-item>
                        </div>
                    </div>

                    <h2 class="will-create-subtitle">Address<el-button type="text" class="add-copy">(To copy your address please click <span @click="copy()" class="click_here">here</span>)</el-button></h2>
                    <div class="form-row">
                        <div class="form-group col-sm-6 col-md-6 col-lg-6">
                            <el-form-item label="Address 1" prop="address_1" :rules="getFieldRule('Address 1')">
                                <el-input maxlength="50" @input="value => recordForm.address_1 = value"
                                          :value="recordForm.address_1 | capitalize"
                                          placeholder="Address 1"></el-input>
                                <div v-if="form.errors.has('address_1')" class="el-form-item__error"
                                     v-text="form.errors.first('address_1')"></div>
                            </el-form-item>
                        </div>
                        <div class="form-group col-sm-6 col-md-6 col-lg-6">
                            <el-form-item label="Address 2" prop="address_2">
                                <el-input maxlength="50" @input="value => recordForm.address_2 = value"
                                          :value="recordForm.address_2 | capitalize" placeholder="Address 2"></el-input>
                            </el-form-item>
                        </div>
                        <div class="form-group col-sm-3 col-md-3 col-lg-3">
                            <el-form-item label="City" prop="city" :rules="getFieldRule('City')">
                                <el-input maxlength="20"  @input="value => recordForm.city = value"
                                          :value="recordForm.city | capitalize" placeholder="City"> </el-input>
                                <div v-if="form.errors.has('city')" class="el-form-item__error"
                                     v-text="form.errors.first('city')"></div>
                            </el-form-item>
                        </div>
                        <div class="form-group col-sm-3 col-md-3 col-lg-3">
                            <el-form-item label="State" prop="state" :rules="getFieldRule('State')">
                                <!--                                <el-select filterable allow-create v-model="recordForm.state" placeholder="State">-->
                                <!--                                    <el-option-->
                                <!--                                            v-for="(record,index) in states"-->
                                <!--                                            v-bind:key="index"-->
                                <!--                                            :label="record.state"-->
                                <!--                                            v-bind:value="record.state">-->
                                <!--                                    </el-option>-->
                                <!--                                </el-select>-->
                                <el-input maxlength="20" @input="value => recordForm.state = value"
                                          :value="recordForm.state | capitalize" placeholder="State"></el-input>

                                <div v-if="form.errors.has('state')" class="el-form-item__error"
                                     v-text="form.errors.first('state')"></div>
                            </el-form-item>
                        </div>

                        <div class="form-group col-sm-3 col-md- col-lg-3">
                            <el-form-item label="Pin Code" prop="pin_code">

                            <el-input maxlength="10" @input="value=> recordForm.pin_code = value"
                            :value="recordForm.pin_code | capitalize" placeholder="Pin code"></el-input>
                                <!-- <el-input maxlength="10" v-model="recordForm.pin_code"
                                          placeholder="Pin code"></el-input> -->
                              <!--  <div v-if="form.errors.has('pin_code')" class="el-form-item__error"
                                      v-text="form.errors.first('pin_code')"></div> -->

                            </el-form-item>
                        </div>
                        <div class="form-group col-sm-3 col-md-3 col-lg-3">
                            <el-form-item label="Country" prop="country" :rules="getFieldRule('Country')">
                                <!--                                <el-select allow-create v-model="recordForm.country">-->
                                <!--                                    <el-option label="India" value="India"></el-option>-->
                                <!--                                </el-select>-->
                                <el-input maxlength="20" @input="value => recordForm.country = value"
                                          :value="recordForm.country | capitalize" placeholder="Country"></el-input>

                                <div v-if="form.errors.has('country')" class="el-form-item__error"
                                     v-text="form.errors.first('country')"></div>
                            </el-form-item>
                        </div>

                    </div>
                </div>
                <div v-if="children_info.have_children == 'Yes'" class="will-btn-group row">

                    <div class="col-3 col-sm-6 col-md-6 col-lg-6 text-left">
                        <el-button class="delete-btn mini-btn btn btn-sm" v-if="recordIndex != null"
                                   @click="deleteItem(recordIndex)">delete
                        </el-button>
                    </div>
                    <div class="col-9 col-sm-6 col-md-6 col-lg-6 text-right">
                        <el-button v-if="recordIndex != null" class="cancel-btn  mini-btn btn mr15 btn-sm"
                                   @click="cancel()">cancel
                        </el-button>
                        <el-button v-if="recordIndex == null" title="Add Details"class="add-btn  mini-btn btn btn-sm" :loading="loading" :disabled="loading" @click="addRecord()">
                            Add child
                        </el-button>
                        <el-button class="add-btn  mini-btn btn btn-sm" :loading="loading" :disabled="loading" v-else @click="addRecord()">Update child
                        </el-button>
                    </div>
                </div>
                <div v-if="message != ''" class="personalinfo-tab tab-card">
                    <h2 class="will-create-title error-message text-center">{{ message }}</h2>
                </div>
            </div>
            <div class="col-sm-12 col-md-12 col-lg-4 position-relative">
                <div class="will-form-image">
                    <div class="will-toon ">
                        <img src="/themes/flatly/images/will2.svg">
                    </div>
                </div>
                <div class="document-img">
                    <img src="/themes/flatly/images/document.svg">
                </div>
                <div class="details-add" v-if="children_info.records.length > 0">
                    <h3 class="details-add-title">Children</h3>
                    <div v-for="(info,index) in children_info.records" class="tab-card child-details-list details-list">
                        <h2 class="child-name">{{ makeName(info.title,info.first_name,info.middle_name,info.last_name)
                            }}</h2>
                        <span class="child-gender">{{ info.gender }}</span>
                        <span class="child-dob">{{ info.date_of_birth }}</span>
                        <p class="child-address">{{
                            makeAddress(info.address_1,info.address_2,info.state,info.city,info.pin_code) }}</p>
                        <el-button type="text" title="Update Details" @click="editRecord(index)"><a class="fa fa-pencil"></a></el-button>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-sm-12 col-md-12 col-lg-12">
                <div class="will-rext-prev-btn">
                    <el-button class="btn btn-prev active btn-nex-prev" @click="backStep()"><i
                            class="fa fa-angle-left"></i></el-button>
                    <el-button class="btn btn-next btn-nex-prev" @click="submit('recordForm')"><i
                            class="fa fa-angle-right"></i></el-button>
                </div>
            </div>
        </div>
    </el-form>

</template>

<script>
    import Form from 'form-backend-validation';
    import Common from '../../mixins/commonMethod';
    import {MessageBox} from 'element-ui'
    import {Message} from 'element-ui'

    var uuid = require('uuid');

    export default {
        name: "step2",
        props: ['data', 'states', 'id'],
        mixins: [Common],
        data() {
            return {
                start_at: new Date(),
                datePickerOptions: {
                    disabledDate(date) {
                        return (date > new Date())
                    }
                },
                start: '',
                activeTab: 2,
                loading: false,
                form: new Form(),
                message: '',
                recordIndex: null,
                children_info: {
                    have_children: 'No',
                    records: []
                },
                beneficiary_info: {
                    appoint_special: 'No',
                    add_beneficiary: 'No',
                    beneficiaries: [],
                    specialclauses: []
                },
                formName: 'recordForm',
                recordForm: {
                    id: '',
                    other_title: '',
                    title: '',
                    first_name: '',
                    last_name: '',
                    middle_name: '',
                    gender: 'Male',
                    date_of_birth: '',
                    address_1: '',
                    address_2: '',
                    city: '',
                    state: '',
                    pin_code: '',
                    country: '',
                }
            }
        },
        filters: {
            capitalize: function (value) {
                if (!value) return ''
                // value = value.toString().toLowerCase()
                return value.charAt(0).toUpperCase() + value.slice(1)
            }
        },
        methods: {
            copy(){
                this.recordForm.address_1 = this.data.personal_info.personal_info.address_1;
                this.recordForm.address_2 = this.data.personal_info.personal_info.address_2;
                this.recordForm.city = this.data.personal_info.personal_info.city;
                this.recordForm.state = this.data.personal_info.personal_info.state;
                this.recordForm.country = this.data.personal_info.personal_info.country;
                this.recordForm.pin_code = this.data.personal_info.personal_info.pin_code;
            },

            deleteItem(index) {
                MessageBox.confirm(`Do you really want to delete?`,
                    "Confirmation",
                    {
                        confirmButtonText: "OK",
                        cancelButtonText: "Cancel",
                        type: "warning"
                    }).then(() => {
                    this.deleteRecord(index);
                }).catch(() => {
                });
            },
            cancel() {
                this.recordIndex = null;
                this.resetForm();
            },
            getTitleRule(Title, FieldName = null, FieldType = null) {
                return {
                    required: true,
                    message: `field required`,
                };
            },
            backStep() {
                this.$emit('changeActiveTab', this.activeTab - 1);
            },
            getFieldRule(Title, FieldName = null, FieldType = null) {
                return {
                    required: true,
                    message: `${Title} field is required`,
                };
            },
            editRecord(index) {
                this.recordIndex = index;
                this.children_info.have_children = 'Yes';

                var record = {};
                for (const cnfg in this.children_info.records[index]) {
                    record = _.merge(record, { [cnfg]: this.children_info.records[index][cnfg] })
                }

                this.recordForm = record;
            },
            deleteRecord(deleteIndex) {
                var childRecord = this.children_info.records[deleteIndex];
                console.log('childRecord.id',childRecord.id);
                this.children_info.records.splice(deleteIndex, 1);
                for(const col in this.beneficiary_info.beneficiaries){
                    // console.log('beneficiary_info.beneficiaries[col].id',beneficiary_info.beneficiaries[col].id);
                    console.log('beneficiary',this.beneficiary_info.beneficiaries[col].id);
                    if(childRecord.id  == this.beneficiary_info.beneficiaries[col].id){
                        console.log('match beneficiary_info.beneficiaries[col].id',this.beneficiary_info.beneficiaries[col].id);
                        this.beneficiary_info.beneficiaries.splice(col, 1);
                    }
                }
                // this.beneficiary_info.beneficiaries.splice(deleteIndex, 1);
                this.recordIndex = null;
                this.resetForm();
                if (this.children_info.records.length == 0)
                    this.children_info.have_children = 'No';
                this.submit('Yes');
            },
            resetForm() {
                this.$refs[this.formName].resetFields();
                this.recordForm.middle_name = '';
            },
            addRecord() {
                if(this.loading == true)
                    return false;
                this.loading = true;
                this.message = '';
                this.$refs[this.formName].validate((valid) => {
                    if (valid) {
                        this.loading = true;
                        this.form = new Form({ activeTab: this.activeTab, children_record: this.recordForm });
                        if (this.id != 0) {
                            console.log('id', this.id);
                            this.form.post(route('api.will.wills.savedata', { id: this.id }))
                                .then((response) => {
                                    console.log('id', this.id);
                                    this.loading = false;
                                    if (response.errors == false) {
                                        this.addInfo();
                                    }

                                })
                                .catch((error) => {
                                    this.loading = false;
                                });
                        } else {
                            this.form.post('/api/will/formData/add')
                                .then((response) => {
                                    this.loading = false;
                                    if (response.errors == false) {
                                        this.addInfo();
                                    } else {
                                        if (response.dashboardRoute != undefined) {
                                            window.location = (response.dashboardRoute);
                                        }
                                    }
                                })
                                .catch((error) => {
                                    this.loading = false;
                                });
                        }

                    }
                    else{
                        this.loading = false;
                    }
                });
                // this.loading = false;
            },
            addInfo() {

                var record = {};
                for (const cnfg in this.recordForm) {
                    record = _.merge(record, { [cnfg]: this.recordForm[cnfg] })
                }

                if (this.recordIndex == null) {
                    record.id = 'CI' + new Date().valueOf();
                    console.log('record', record);
                    var brecord = record;
                    brecord.type = 'primary';
                    if(brecord.gender === 'Male'){
                        brecord.relationship = 'Son';
                    }else{
                        brecord.relationship = 'Daughter';
                    }
                    // brecord.relationship = 'Children';
                    this.children_info.records.push(record);
                    this.beneficiary_info.beneficiaries.push(brecord);
                } else {

                    this.children_info.records[this.recordIndex] = record;
                    console.log('edit-record',record);
                    for(var col in this.beneficiary_info.beneficiaries){
                        // console.log('this.beneficiary_info.beneficiaries[col].first_name ',this.beneficiary_info.beneficiaries[col].first_name );
                        if(this.beneficiary_info.beneficiaries[col].id === record.id){
                            if(record.gender === 'Male'){
                                record.relationship = 'Son';
                            }else{
                                record.relationship = 'Daughter';
                            }
                            this.beneficiary_info.beneficiaries[col] = record;
                        }
                    }
                    this.recordIndex = null;
                }
                this.resetForm();
                this.submit('Yes');
            },
            submit(addInfo = null) {
                window.scrollTo({top: 0, behavior: 'smooth'});
                this.message = '';
                if (this.children_info.have_children == 'Yes' && this.children_info.records.length == 0) {
                    console.log('error');
                    this.$refs[this.formName].validate((valid) => {
                        if (valid) {
                            this.children_info.have_children = 'Yes';
                            this.message = "Please click on 'Add Child' button to save the details";
                        } else {
                            this.children_info.have_children = 'Yes';
                        }
                    });
                } else {
                    this.loading = true;
                    console.log('this.beneficiary_info',this.beneficiary_info);
                    this.form = new Form({activeTab: this.activeTab, children_info: this.children_info, beneficiary_info: this.beneficiary_info});
                    if (this.id != 0) {
                        console.log('id', this.id);
                        this.form.post(route('api.will.wills.savedata', { id: this.id }))

                            .then((response) => {
                                console.log('id', this.id);
                                this.loading = false;
                                if (response.errors == false) {
                                    if (addInfo != 'Yes') {
                                        this.$emit('changeActiveTab', this.activeTab + 1);
                                        this.$emit('updateFormData', response.data);
                                    }else{
                                        if(response.dashboardRoute != undefined){
                                            window.location = (response.dashboardRoute);
                                        }
                                    }
                                }

                            })
                            .catch((error) => {
                                this.loading = false;
                            });
                    } else {
                        this.form.post('/api/will/formData/add')
                            .then((response) => {
                                this.loading = false;
                                if (response.errors == false) {
                                    if (addInfo != 'Yes') {
                                        this.$emit('changeActiveTab', this.activeTab + 1);
                                        this.$emit('updateFormData', response.data);
                                    }
                                } else {
                                    if (response.dashboardRoute != undefined) {
                                        window.location = (response.dashboardRoute);
                                    }
                                }
                            })
                            .catch((error) => {
                                this.loading = false;
                            });
                    }
                }
            }
        },
        mounted() {
            this.loading = true;
            if (this.data != undefined && this.data.children_info != undefined) {
                this.children_info.records = this.data.children_info.records;

                if (this.children_info.records.length > 0) {
                    this.children_info.have_children = 'Yes';
                } else {
                    this.children_info.have_children = 'No';
                }
                this.start = this.data.personal_info.personal_info.date_of_birth;
            }
            if (this.data != undefined && this.data.beneficiary_info != undefined) {
                // this.data.guardian_info = [];
                this.beneficiary_info = this.data.beneficiary_info;
            }
            this.loading = false;
        },
        watch: {
            'children_info.have_children': function () {
                if (this.children_info.have_children == "No") {
                    this.children_info.records = [];
                }
            },
            'recordForm.title': function () {
                if (this.recordForm.title != "Other") {
                    if (this.recordForm.other_title != '') {
                        this.recordForm.other_title = ''
                    }
                }
            }
        }
    }
</script>

<style scoped>

</style>
